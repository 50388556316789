/**
 * Convert the upload data from AWS into an image object
 * matching the format used by the UI.
 *
 * The AWS data takes the shape:
 * const uploadFromAWS = {
 *   image: {
 *     file: {
 *       name: "ello-optimized-2c07cd65.jpg",
 *       size: 1983518,
 *     },
 *     file_name: "ello-optimized-2c07cd65.jpg",
 *     type: "image/jpeg",
 *     url: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD",
 *   },
 *   location: undefined,
 * };
 */
// TODO  Rename awsFileUploadToImage
export function awsUploadToImage(upload, title) {
  return {
    file_name: upload.image.name,
    title: title,
    description: upload.category,
    url: upload.location,
    width: upload.dimensions.width,
    height: upload.dimensions.height,
    upload_tmp: {
      storage: 'cache_upload',
      metadata: {
        size: upload.image.size,
        file_name: upload.image.name,
        mime_type: upload.image.mimeType,
      }
    },
  };
}

/**
 * Convert the upload data from an image URL upload into
 * an object matching the format used by the UI
 */
export function awsURLUploadToImage(upload, title) {
  return {
    // Use the url as the file name since that's the original
    // location of the file as far as we're concerned. This
    // also gives us the ability to check if it is different
    // than the existing image in the case of a user's profile picture.
    file_name: upload.image.url,
    title: title,
    description: upload.category,
    url: upload.location,
    width: upload.dimensions.width,
    height: upload.dimensions.height,
    upload_tmp: {
      storage: 'cache_upload',
      metadata: {
        size: upload.image.size,
        file_name: upload.image.url,
        mime_type: upload.image.mimeType,
      }
    }
  }
}
