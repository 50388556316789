import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import {
  combineClasses,
} from '~/util';

import styles from './Checkbox.module.scss';


export function Checkbox({
  label,
  className,
  ...rest
}) {
  return (
    <label className={combineClasses(styles.Checkbox, className)}>
      <input className={styles.checkbox} type="checkbox" {...rest} />
      {label}
    </label>
  );
}

export function FormikCheckbox({
  label,
  className,
  ...rest
}) {
  return (
    <label className={combineClasses(styles.Checkbox, className)}>
      <Field className={styles.checkbox} type="checkbox" {...rest} />
      {label}
    </label>
  );
}

FormikCheckbox.propTypes = {
  /**
   * The label text to display next to this checkbox.
   */
  label: PropTypes.string.isRequired,
  /**
   * The name of the form variable associated with this checkbox.
   */
  name: PropTypes.string.isRequired,
};
