import { Auth, Hub } from 'aws-amplify';

import { Service } from '../../service';
import { env } from '../../env';

// Configuring Google Auth with Amazon Cognito:
// https://aws.amazon.com/premiumsupport/knowledge-center/cognito-google-social-identity-provider/
//
// Google console privs for managing OAuth APIs:
// https://serverfault.com/questions/907682/permissions-for-creating-oauth-credentials-in-google-cloud
// or
// https://stackoverflow.com/questions/58956802/permissions-to-create-oauth-client-id-in-google-cloud
export class GoogleAuthAPI extends Service {
  constructor(
    client = Auth,
    hub = Hub,
    debug
  ) {
    super(client, debug);
    this.hub = hub;

    this.info(`created with client <${client.constructor.name}>`);
  }

  getUser() {
    this.debug('getUser');
    return this.client.currentAuthenticatedUser()
      .then(response => {
        this.debug('getUser response', response);
        const session = response.signInUserSession;
        const idToken = session.idToken;
        const hasuraClaims = JSON.parse(idToken.payload['https://hasura.io/jwt/claims']);
        const roles = hasuraClaims['x-hasura-allowed-roles'];
        const role = hasuraClaims['x-hasura-default-role'];

        const out = {
          attributes: response.attributes,
          token: idToken.jwtToken,
          roles,
          role,
        };
        this.info('getUser SUCCESS', out);
        return out;
      })
      .catch(error => {
        if (error === 'not authenticated') {
          this.info('getUser NOT AUTHENTICATED');
          return null;
        } else {
          this.error('getUser ERROR', error);
        }
      });
  }

  login() {
    this.debug('login');
    return this.client.federatedSignIn({provider: 'Google'})
      .then(() => {
        this.info('login SUCCESS');

        // When using real authentication, the page will refresh
        // after login success so we can just let the page loader run
        // until the page refresh. However, in a mock environment,
        // the page should not refresh (in order to maintain the state)
        // so we request the user data to simulate the page refresh.
        if (env.mock) {
          return this.getUser().then(response => {
            this.emit('signIn', response);
            return true;
          });
        } else {
          return true;
        }
      })
      .catch(error => {
        this.error('login ERROR', error);
        throw error;
      });
  }

  logout() {
    this.debug('logout');
    return this.client.signOut()
      .then(response => {
        this.info('logout SUCCESS', response);
        this.emit('signOut');
        return response;
      })
      .catch(error => {
        this.error('logout ERROR', error);
        throw error;
      });
  }
}

