import React from 'react';
import PropTypes from 'prop-types';

import {
  combineClasses,
} from '~/util';

import './Loader.scss';

import { ReactComponent as LoaderIcon } from './icon/loader.svg';
import './icon/loader.css';

export default function Loader({
  className,
  fast,
  ...rest
}) {
  return (
    <div
      className={combineClasses("loader-component", className)}
      {...rest}
    >
      <LoaderIcon
        data-test="loaderComponent"
        className={combineClasses('loader', fast ? 'fast' : null)}
      />
    </div>
  );
}

Loader.propTypes = {
  /**
   * Run the loader animation at double speed.
   */
  fast: PropTypes.bool,
};
