import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const AuthSchema = Yup.object().shape({
  username: Yup.string().email('must be a valid email').required('is required'),
  password: Yup.string().required('is required'),
});


// OAuth Scopes
// The data about the user returned by the OAuth request.
export const AuthScopesPropTypeDef = {
  email: PropTypes.string.isRequired,
  given_name: PropTypes.string.isRequired,
  family_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
};
export const AuthScopesPropType = PropTypes.shape(AuthScopesPropTypeDef);

// OAuth Response
// Data returned from a successful OAuth sign in.
export const AuthResponsePropTypeDef = {
  /**
   * User scopes returned by the OAuth request.
   */
  attributes: AuthScopesPropType.isRequired,
  /**
   * The JWT token that can be used for future auth requests.
   */
  token: PropTypes.string.isRequired,
  /**
   * The list of roles this user has.
   */
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The default role of the user.
   */
  role: PropTypes.string.isRequired,
}
export const AuthResponsePropType = PropTypes.shape(AuthResponsePropTypeDef);
