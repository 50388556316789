import React from 'react';

import {
  dataToCSV,
} from '~/util';
import { Service } from '../service';

export class DownloadService extends Service {
  /**
   * Create a link element that can be used to download
   * a file.
   * @param {string} fileName - The name of the file.
   * @param {string} data - The contents of the file.
   * @return {HTMLAnchorElement}
   */
  createDownloadLink(fileName, data) {
    var encodedUri = encodeURI(data);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    return link;
  }

  /**
   * Download the provided data as a file.
   * @param {string} fileName - The name of the file.
   * @param {string} data - The contents of the file.
   */
  downloadFile(fileName, data) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const url = URL.createObjectURL(blob);
      const link = this.createDownloadLink(fileName, url);

      // Some browsers require the link to be rendered
      // before the download can happen.
      document.body.appendChild(link);

      // Click the link to start the download.
      link.click();

      // Remove the link
      document.body.removeChild(link);
    }
  }

  /**
   * Convert the provided data into a CSV string
   * using the provided config and download that CSV file.
   * @param {string} fileName - The name of the file to be
   * downloaded.
   * @param {*[]} data - An array of data items to convert to
   * CSV rows. Each array element will be converted into
   * a row of data using the provided config to determine
   * how to convert elements to values.
   * @param {CSVConfig[]} config - The config object used
   * to convert each array element into a row of CSV data.
   * @param {string} [separator] - The character to use
   * as a value delimiter.
   */
  downloadCSV(fileName, data, config, separator) {
    const csv = dataToCSV(data, config, separator);
    this.info('Downloading', fileName);
    this.debug('DATA', data);
    this.debug('CSV', csv);
    this.downloadFile(fileName, csv);
  }
}

export const DownloadServiceContext = React.createContext();
export const DownloadServiceProvider = DownloadServiceContext.Provider;

export function useDownloadService() {
  return React.useContext(DownloadServiceContext);
}
