import React from 'react';
import PropTypes from 'prop-types';

// Don't import the full components package!
import { NotFound } from '~/components/not-found';
import {
  WithCollections,
} from './WithCollections.jsx';

export function WithArtwork({
  collectionId,
  artworkId,
  children,
  className,
  ...rest
}) {
  return (
    <WithCollections id={collectionId}>
      { collection => {
        const artwork = collection.artworks.find(a => a.id === artworkId);

        if (!artwork) {
          const message =
            `We were unable to find artwork ${artworkId}. Please verify you have a valid artwork link.`;

          return (
            <NotFound
              title="Artwork Not Found"
              subtitle={message}
            />
          );
        } else {
          return children(artwork);
        }
      }}
    </WithCollections>
  );
}

WithArtwork.propTypes = {
  collectionId: PropTypes.number.isRequired,
  artworkId: PropTypes.number.isRequired,
}
