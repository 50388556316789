import React from 'react';
import PropTypes from 'prop-types';

import {
  FormikLabeledInput,
  FormikCurrencyInput,
  FormikNumberInput,
  FieldRow,
  FormikCheckbox,
  // FormikDebug,
  TextareaAutosize,
  useCallWhenFormikReady,
} from '../../../forms';
import {
  ArtworkPurchasingSchema,
} from '~/model';

import { AutoSaveForm } from '../../../forms/AutoSaveForm.jsx';

import styles from './ArtworkPurchasingForm.module.scss';

export function ArtworkPurchasingForm({
  name,
  values,
  onChange,
  onReady,
  strip,
  ...rest
}) {
  const whenReady = useCallWhenFormikReady();
  React.useEffect(() => whenReady(onReady), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AutoSaveForm
      strip={strip}
      name={name}
      initialValues={values}
      schema={ArtworkPurchasingSchema}
      onChange={onChange}
      {...rest}
    >
      <>
        <FieldRow className={styles.pricing}>
          <FormikLabeledInput name="price.retail" label="Retail Price">
            <FormikCurrencyInput currency="USD" allowNegative={false} required data-test="retailPrice" />
          </FormikLabeledInput>
          <FormikLabeledInput name="price.wholesale" label="Wholesale Cost">
            <FormikCurrencyInput currency="USD" allowNegative={false} required data-test="wholesalePrice" />
          </FormikLabeledInput>
        </FieldRow>
        <FormikLabeledInput name="price.quantity" label="Quantity">
          <FormikNumberInput data-testid="quantity" required defaultValue="1" />
        </FormikLabeledInput>
        <FormikLabeledInput
          name="notes.supplier.description"
          label="Supplier Notes"
          help="Use this field for purchasing information such as web links, gallery name, etc."
        >
          <TextareaAutosize data-testid="supplierNotes" />
        </FormikLabeledInput>
        <FormikCheckbox name="sold" label="Sold" data-testid="sold" />
      </>
    </AutoSaveForm>
  );
}

ArtworkPurchasingForm.propTypes = {
  /**
   * A name used to disambiguate change events from this form.
   */
  name: PropTypes.string,
  /**
   * The initial form values.
   */
  values: PropTypes.object,
  /**
   * A callback to handle changes to the state of the form.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * A callback that will be called once the form is initialized.
   */
  onReady: PropTypes.func,
};

