import React from 'react';
import PropTypes from 'prop-types';

import {
  SubTitle,
} from '../../titles';
import {
  TitleValuePair
} from '../../title-value-pair';
import {
  UserIcon,
} from '../../user-icon';
import {
  Image,
} from '../../images';
import {
  CardWithHeader
} from '../CardWithHeader.jsx';
import {
  formatDate,
  formatCurrency,
  combineClasses,
} from '~/util';
import {
  IdPropType,
  ArtworkSummaryPropType,
} from '~/model';

import { ReactComponent as CardIcon } from '~/assets/icons/project-card-fill.svg';

import styles from './CollectionCard.module.scss';

export function ArtworkThumbnails({
  artworks,
  max = 3,
  ...rest
}) {
  return (
    <div data-testid="ArtworkThumbnails"
      className={styles.ArtworkThumbnails}
      {...rest}
    >
      { artworks.slice(0, max).map((a, i) =>
        a.images.length > 0
          ? <Image data-testid="artworkImage"
              alt={a.title}
              className={styles.artworkImage}
              src={a.images[0].url}
              key={i}
            />
          : null
      )}
    </div>
  );
}

/*
 * @prop {function} [onSelect] Callback when the card is clicked or the
 *   enter key is typed.
 */
export function CollectionCard({
  id,
  title,
  created_at,
  budget,
  author,
  authorImage,
  artworks,
  maxArtworks,
  onSelect,
  className,
  ...rest
}) {
  return (
    <CardWithHeader
      data-testid="CollectionCard"
      {...rest}
      className={combineClasses(styles.CollectionCard, className)}
      title={title}
      icon={<CardIcon className={styles.CardIcon} />}
      onSelect={() => onSelect(id)}
    >
      <SubTitle className="author" data-test="author">
        Created by
        <span className={styles.authorName}>{ author }</span>
        <span className={styles.createdDate}>{ formatDate(created_at) }</span>
      </SubTitle>
      <div className="row budgets">
        <TitleValuePair
          data-test="budget"
          className="total-budget"
          title="Collection Budget"
          value={ formatCurrency(budget) }
        />
      </div>
      <div className={combineClasses(styles.iconRow, 'row')}>
        <UserIcon
          className={styles.UserIcon}
          name={author}
          image={authorImage}
          showTooltip
        />
        { artworks && artworks.length > 0 &&
          <ArtworkThumbnails artworks={artworks} max={maxArtworks} />
        }
      </div>
    </CardWithHeader>
  );
}

CollectionCard.propTypes = {
  /**
   * The id of the collection being displayed.
   */
  id: IdPropType.isRequired,
  /**
   * The name of the collection.
   */
  title: PropTypes.string.isRequired,
  /**
   * ISO string representing the creation date of the collection.
   */
  created_at: PropTypes.string.isRequired,
  /**
   * The total budget for this collection.
   */
  budget: PropTypes.number.isRequired,
  /**
   * The name of the user who initially created this collection.
   */
  author: PropTypes.string.isRequired,
  /**
   * The profile picture of the author.
   */
  authorImage: PropTypes.string,
  /**
   * The list of artworks for this collection.
   */
  artworks: PropTypes.arrayOf(ArtworkSummaryPropType),
  /**
   * A callback that will be called when the collection is clicked on.
   */
  onSelect: PropTypes.func.isRequired,
};

