import React from 'react';
import PropTypes from 'prop-types';

import {
  combineClasses,
} from '~/util';

import './HorizontalPanels.scss';

export function LeftPanel({
  children,
  className,
  ...rest
}) {
  return (
    <div data-test="leftPanel"
      className={combineClasses('left-panel', className)}
      {...rest}
    >
      { children }
    </div>
  );
}

export function RightPanel({
  children,
  className,
  ...rest
}) {
  return (
    <div data-test="rightPanel"
      className={combineClasses('right-panel', className)}
    >
      { children }
    </div>
  );
}

export default function HorizontalPanels({
  children,
  className,
  ...rest
}) {
  let left = null;
  let right = null;
  if (Array.isArray(children)) {
    children.forEach((child) => {
      if (child.type) {
        switch (child.type.name) {
          case LeftPanel.name:
            left = child;
            break;
          case RightPanel.name:
            right = child;
            break;
          // no default
        }
      }
    });
  }

  const wrap = (component) => (
    <div
      data-test="horizontalPanels"
      {...rest}
      className={combineClasses('horizontal-panels', className)}
    >
      { component }
    </div>
  );

  if (left || right) {
    return wrap(
      <React.Fragment>
        { left }
        { right }
      </React.Fragment>
    );
  } else {
    console.warn(
      'Could not find either a <LeftPanel> or <RightPanel> component ' +
      'in this <HorizontalPanels>. Will resort to rendering all children.'
    );
    return wrap(children);
  }
}

HorizontalPanels.propTypes = {
  className: PropTypes.string,
}
