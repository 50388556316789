import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classSet from 'react-classset';
import { Transition } from 'react-spring/renderprops';

import {
  RemoveButton,
  UploadButton,
} from '../../buttons';
import {
  SmallTitle,
} from '../../titles';
import {
  Image,
} from '../../images';
import {
  ImageTransport,
} from '~/model';
import { springs } from '~/util';

import { ReactComponent as ImagePlaceholder } from '~/assets/icons/image-placeholder.svg';

import './ImageList.scss';

function ImagePreview({image}) {
  return (
    <Image
        data-test="previewImage"
        className="preview-image"
        src={image.src}
        alt={image.name}
      />
  );
}

function Placeholder() {
  return (
    <div className="empty" data-test="placeholder">
      <ImagePlaceholder className="icon" />
      <SmallTitle className="message">Drag and Drop image here</SmallTitle>
    </div>
  );
}

/**
 * @typedef {object} ListItemProps
 * @property {boolean} [selected]
 * @property {object} [image]
 * @property {function} [onSelect]
 * @property {function} [onRemove]
 */
/**
 * @type {React.FC<ListItemProps>}
 */
const ListItem = React.forwardRef(({
  selected,
  image,
  onSelect,
  onRemove,
  ...rest
}, ref) => {
  const [hovered, setHovered] = React.useState(false);
  const classes = classSet({
    'image-entry': true,
    'selected': selected,
    'delete': hovered,
  });

  const handleRemove = e => {
    e.stopPropagation();
    onRemove(image);
  };

  return (
    <li
      ref={ref}
      data-test="imageEntry"
      className={classes}
      onClick={onSelect}
      {...rest}
    >
      <Image
        className="icon-image"
        src={image.src}
        alt={image.name}
      />
      <span className="image-name">{ image.name }</span>
      <RemoveButton
        data-test="removeButton"
        className="remove-image"
        onClick={handleRemove}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      />
    </li>
  );
});

ListItem.propTypes = {
  image: PropTypes.instanceOf(ImageTransport).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function List({
  images,
  selected,
  selectImage,
  removeImage,
  animated,
}) {
  const render = (item, i, styles) => (
    <ListItem
      key={i}
      style={styles}
      selected={selected === i}
      image={item}
      onSelect={() => selectImage(i)}
      onRemove={image => removeImage(i, image)}
    />
  );

  // Because Transition relies on transitionend (which only
  // works if the browser has focus), skip rendering the
  // Transition if animations are turned off.
  if (!animated) {
    return images.map((item, i) => render(item, i, {}));
  } else {
    return (
      <Transition
        items={images}
        keys={item => item.name}
        trail={150}
        config={springs.moderate}
        from={{  height: 'auto', opacity: 0, transform: 'translate3d(-20%,0,0)' }}
        enter={{ height: 'auto', opacity: 1, transform: 'translate3d(0,0,0)' }}
        leave={{ height: 0,      opacity: 0 }}
      >
        { (item, state, i) => props => render(item, i, props) }
      </Transition>
    );
  }
}

export default function ImageList({
  images,
  onBrowse,
  allowMultiple = true,
  onRemoveImage,
  animated = true,
}) {
  const [selected, setSelected] = useState(0);
  const [imageCount, setImageCount] = useState(images.length);
  const fileListRef = useRef();

  useEffect(() => {
    const last = images.length - 1;

    // If we added an image.
    if (images.length > 0 && imageCount < images.length && selected !== last) {
      // Select it
      setSelected(last);
      setImageCount(images.length);

      const list = fileListRef.current;
      list.scrollTop = list.scrollHeight;
    }
  }, [images, selected, imageCount]);

  const selectImage = (index) => {
    setSelected(index);
  };

  const removeImage = (index, image) => {
    if (selected >= index) {
      const nextSelected = Math.max(selected - 1, 0);
      setSelected(nextSelected);
    }

    onRemoveImage(index, image);
  }

  const image = images.length > 0
    ? <ImagePreview image={images[selected]} />
    : <Placeholder />;

  const uploadAreaClasses = classSet({
    'upload-area': true,
    'has-images': images.length > 0,
  });

  const handleBrowse = (e) => {
    const input = e.currentTarget;
    if (input.files) {
      onBrowse(input.files);
    } else {
      console.error('Unable to retrieve files to upload.', e);
    }
  };

  return (
    <div className="image-list" data-test="imageList">
      <div className={uploadAreaClasses}>
        <div className="image-preview">
          { image }
        </div>
        <ol className="file-list" ref={fileListRef}>
          <List
            images={images}
            selected={selected}
            selectImage={selectImage}
            removeImage={removeImage}
            animated={animated}
          />
        </ol>
      </div>
      <div className="browse-area">
        <div className="file-type-message">
          File types accepted: png, jpeg, gif
        </div>
        <UploadButton
          data-testid="browseButton"
          onChange={handleBrowse}
          accept="image/*"
          multiple={allowMultiple}
        />
      </div>
    </div>
  );
}

ImageList.propTypes = {
  images: PropTypes.arrayOf(PropTypes.instanceOf(ImageTransport)).isRequired,
  onBrowse: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool,
  onRemoveImage: PropTypes.func.isRequired,
  animated: PropTypes.bool,
};

