

/**
 * Escape any CSV special characters in a string.
 * return {string}
 */
export function escapeCSVValue(value) {
  if (value == null) return '';

  value = String(value);

  // Double quote chars need to be escaped with a double quote.
  let result = value.replace(/"/g, '""');

  // If the value contains any special characters, it needs to
  // be quoted.
  if (result.search(/("|,|\n)/g) >= 0)
    result = `"${result}"`;

  return result;
}

/**
 * @callback DataCallback
 * @param {*} data - The current piece of data to convert
 * into a CSV cell.
 * @return {string} The value of a CSV cell as a string.
 */
/**
 * @typedef CSVConfig - Represents a column of data
 * and how a piece of data is converted into a cell value.
 * @property {string} title - The name of this column.
 * @property {DataCallback} value - A function for converting
 * a piece of data into a cell value.
 */

export function configToHeaders(config, separator=',') {
  return config.map(c => escapeCSVValue(c.title)).join(separator);
}

/**
 * Convert the list of data objects into data rows
 * using the config provided.
 * @param {*[]} data - An array of data items to convert to
 * CSV rows. Each array element will be converted into
 * a row of data using the provided config to determine
 * how to convert elements to values.
 * @param {CSVConfig[]} config - The config object used
 * to convert each array element into a row of CSV data.
 * @return {string[][]} A mulitdimensional array of strings
 * where each row is an array of CSV cell values.
 */
export function prepareCSVData(data, config) {
  return data.map((d, i) =>
    config.map((c, j) =>
      escapeCSVValue( c.value ? c.value(d) : d )
    )
  );
}

/**
 * Convert the provided data into a CSV string
 * using the provided config.
 * @param {*[]} data - An array of data items to convert to
 * CSV rows. Each array element will be converted into
 * a row of data using the provided config to determine
 * how to convert elements to values.
 * @param {CSVConfig[]} config - The config object used
 * to convert each array element into a row of CSV data.
 * @param {string} [separator] - The character to use
 * as a value delimiter.
 * @return {string} The CSV file as a single string.
 */
export function dataToCSV(data, config, separator=',') {
  if (!Array.isArray(data)) data = [data];
  const header = configToHeaders(config, separator);

  const prepared = prepareCSVData(data, config);
  const csv = prepared.map(row => row.join(separator)).join('\n');

  return `${header}\n${csv}`;
}

