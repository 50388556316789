import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  Logo,
  GoogleSignInButton,
  PageLoader,
  toast,
} from '~/components';
import {
  useAuthAPI,
} from '~/services';

import styles from './GoogleLogin.module.scss';

export function GoogleLogin({
  errorMessage,
  onLogin,
}) {
  return (
    <div className={styles.GoogleLogin} data-testid="GoogleLogin">
      <div className={styles.pageContent}>
        <Logo className={styles.logo} alt="Dotfolio Logo" />
        <GoogleSignInButton onClick={onLogin} data-testid="GoogleSignInButton" />
      </div>
    </div>
  );
}

export default function Connected({
  onSuccess,
  onFailure,
  animated,
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState();
  const api = useAuthAPI();

  const handleLogin = () => {
    setSubmitting(true);
    api.login()
      .then(response => {
        // NOTE: Do not clear the submitting flag here
        // because the page is about to redirect and we don't
        // want to show the login page while we're waiting
        // for the login redirect to complete.
        if (onSuccess) onSuccess(response);
      })
      .catch(error => {
        ReactDOM.unstable_batchedUpdates(() => {
          setSubmitting(false);
          const message = `Hmmm...there was a problem logging you in.
            Please verify that you can log in to your Dotfolio email account.
            If you continue to experience issues, please contact your email administrator.`;
          setError(message);
          toast.error(message);
        });
        if (onFailure) onFailure(error);
      });
  };

  if (submitting) {
    return <PageLoader />;
  }
  else {
    return (
      <GoogleLogin
        onLogin={handleLogin}
        errorMessage={error}
        aniamted={animated}
      />
    );
  }
}

Connected.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};
