import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import composeRefs from '@seznam/compose-react-refs';

import {
  combineClasses,
  screenToPercent,
  useIsMounted,
} from '~/util';
import {
  PinPropType,
  RefPropType,
} from '~/model';
import { ART_PIN_TYPE } from './constants';
import DraggableMapPin, { MapPin } from './MapPin.jsx';
import { Image } from '../images';

import styles from './Map.module.scss';


export const Map = React.forwardRef(({
  imageURL,
  name,
  pins,
  scale,
  interactive,
  imageRef,
  onReady,
  onPinMove,
  onImageLoad,
  onPinHover,
  onPinLeave,
  animated,
  className,
  ...rest
}, ref) => {
  const isMounted = useIsMounted();
  const localImageRef = React.useRef();
  // Used to disable drag/drop functionality.
  const mockRef = React.useRef();
  const pinScale = 1/scale;
  const [pinBeingEdited, setPinBeingEdited] = React.useState(null);
  const [pinsLoadedCount, setPinsLoadedCount] = React.useState(0);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [onReadyCalled, setOnReadyCalled] = React.useState(false);

  // TODO Fade in the map on load
  const handleImageLoad = () => {
    if (isMounted()) {
      setImageLoaded(true);

      if (onImageLoad) onImageLoad();
    }
  };

  const onArtPinReady = () => {
    if (isMounted()) setPinsLoadedCount(current => current + 1);
  };

  const onArtPinOpen = (pin) => {
    setPinBeingEdited(pin);
  };

  const onArtPinClose = (pin) => {
    setPinBeingEdited(null);
  };

  const handlePinDrop = (pin, x, y) => {
    const dimensions = localImageRef.current.getBoundingClientRect();
    const coords = screenToPercent(x, y, dimensions);

    // Output the new pin data.
    onPinMove(pin, coords.x, coords.y);
  }

  // TODO Dragging pins doesn't work on touch devices.
  // TODO Pan does not work on touch devices on first touch
  // after opening/closing an artwork pin.
  // TODO Pin drop ghost image positioning is wrong while
  // dragging in Firefox
  // TODO Pin ghost image isn't drawn or positioned correctly
  // in Safari
  const [, dropRef] = useDrop({
    accept: [ART_PIN_TYPE],
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();

      const result = {
        type: ART_PIN_TYPE,
        item,
        clientX: clientOffset.x,
        clientY: clientOffset.y,
      };

      handlePinDrop(result.item, result.clientX, result.clientY);

      return result;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  React.useEffect(() => {
    if (
      imageLoaded &&
      pinsLoadedCount >= pins.length &&
      !onReadyCalled &&
      isMounted()
    ) {
      setOnReadyCalled(true);
      if (onReady) onReady();
    }
  }, [onReadyCalled, pinsLoadedCount, imageLoaded, pins.length, onReady, isMounted]);

  return (
    <div
      data-testid="Map"
      className={combineClasses(styles.Map, className)}
      ref={ref}
      {...rest}
    >
      <div className={styles.overlay}>
        { pins.map((p) => {
          const x = p.x * 100 + '%';
          const y = p.y * 100 + '%';
          const open = pinBeingEdited === p;
          const z = open ? 11 : 10;

          // console.log('----------------');
          // console.log('scale', pinScale);
          // console.log('p', p);
          // console.log('color', p.color);
          // console.log('x/y', x, y);
          // console.log('----------------');

          if (interactive) {
            return (
              <DraggableMapPin
                artwork={p}
                open={open}
                x={x}
                y={y}
                z={z}
                color={p.color}
                scale={pinScale}
                onReady={onArtPinReady}
                onHoverOver={() => onPinHover ? onPinHover(p) : null}
                onHoverOut={() => onPinLeave ? onPinLeave(p) : null}
                onOpen={onArtPinOpen}
                onClose={onArtPinClose}
                key={p.id}
              />
            );
          } else {
            return (
              <MapPin
                interactive={false}
                artwork={p}
                open={open}
                x={x}
                y={y}
                z={z}
                color={p.color}
                scale={pinScale}
                onReady={onArtPinReady}
                key={p.id}
              />
            );
          }
        })}
      </div>
      <div data-testid="dropZone"
        className={styles.background}
        ref={interactive ? dropRef : mockRef}
      >
        <Image data-testid="image"
          draggable="false"
          className={combineClasses(styles.image, 'map-background')}
          ref={imageRef ? composeRefs(imageRef, localImageRef) : localImageRef}
          src={imageURL}
          alt={name}
          onLoad={handleImageLoad}
          // Prevent the native image drag event in Firefox
          onDragStart={e => e.preventDefault()}
        />
      </div>
    </div>
  );
});

Map.propTypes = {
  imageURL: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pins: PropTypes.arrayOf(PinPropType).isRequired,
  scale: PropTypes.number,
  // TODO Separate the interactive components
  // into new components.
  interactive: PropTypes.bool,
  imageRef: RefPropType,
  /**
   * Once the map image and all pins have loaded.
   */
  onReady: PropTypes.func,
  onPinMove: PropTypes.func,
  /**
   * Once the map image has loaded but before the onReady
   * event has been emitted.
   */
  onImageLoad: PropTypes.func,
  onPinHover: PropTypes.func,
  onPinLeave: PropTypes.func,
  animated: PropTypes.bool,
};

Map.defaultProps = {
  scale: 1,
  animated: true,
  interactive: true,
}

// ZoomImage.propTypes = {
//   src: PropTypes.string.isRequired,
//   alt: PropTypes.string.isRequired,
//   onLoad: PropTypes.func,
//   onResize: PropTypes.func,
// };
