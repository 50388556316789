import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import {
  combineClasses,
  useId,
} from '~/util';

import styles from './HelpIcon.module.scss';

import {ReactComponent as Icon} from '~/assets/icons/circle-help.svg';

export function HelpIcon({
  warn,
  className,
  children,
  tooltipProps: {
    className: tooltipClassName,
    ...tooltipProps
  } = {},
  ...rest
}) {
  const id = useId();
  const tooltipId = `tooltip--HelpIcon-${id}`;

  return (
    <React.Fragment>
      <Icon
        data-testid="helpIcon"
        className={combineClasses(
          styles.HelpIcon,
          className,
          warn ? styles.warn : null
        )}
        {...rest}
        data-tip={children}
        data-for={tooltipId}
      />
      <ReactTooltip
        className={combineClasses(styles.tooltipWrapper, tooltipClassName)}
        place="top"
        effect="solid"
        id={tooltipId}
        {...tooltipProps}
      >
        { children }
      </ReactTooltip>
    </React.Fragment>
  );
}

HelpIcon.propTypes = {
  /**
   * The text to display inside the tooltip.
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether this icon should be more prominent by showing the warning coloring.
   */
  warn: PropTypes.bool,
  /**
   * Any props you'd like to pass on to the `ReactTooltip` instance. @see
   * https://wwayne.github.io/react-tooltip/
   */
  tooltipProps: PropTypes.object,
};

