
/**
 * Convert a screen location into a percentage over
 * a bounding object retrieved with `getBoundingClientRect`.
 */
export function screenToPercent(screenX, screenY, bounds) {
  return {
    x: (screenX - bounds.x) / bounds.width,
    y: (screenY - bounds.y) / bounds.height,
  };
};
