import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  CurrencyInput,
  fromHundredths,
  toHundredths,
} from '../number-input';

export default function FormikCurrencyInput({
  value,
  form,
  name,
  allowZero,
  ...rest
}) {
  const [dollars, setDollars] = React.useState(fromHundredths(value));
  const [formattedDollars, setFormattedDollars] = React.useState(() => {
    return value == null || value === '' ? '' : dollars;
  });

  React.useEffect(() => {
    let pennies = toHundredths(dollars);
    if (!allowZero && pennies === 0) pennies = '';
    if (pennies !== value) {
      form.setFieldValue(name, pennies);
    }
  }, [dollars]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (values) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setDollars(values.floatValue);
      setFormattedDollars(values.formattedValue);
    });
  };

  return (
    <CurrencyInput data-test="formikCurrencyInput"
      name={name}
      defaultValue={formattedDollars}
      {...rest}
      onChange={undefined}
      onValueChange={handleChange}
    />
  );
}

// For more props
// @see https://www.npmjs.com/package/react-number-format
FormikCurrencyInput.propTypes = {
  /**
   * The initial value of the input.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The currency code for the currency being displayed.
   * Defaults to 'USD'. See https://www.currency-iso.org/en/home/tables/table-a1.html
   */
  currency: PropTypes.string,
  /**
   * The locale to use when formatting the currency.
   * See https://tools.ietf.org/html/rfc5646
   */
  locale: PropTypes.string,
  // Form an Name are required but that is not enforced
  // here because they receive those from `FormikLabeledInput`
  // or Formik `Field`.
  // name: PropTypes.string,
  // form: PropTypes.shape({
  //   setFieldValue: PropTypes.func.isRequired
  // }),
};

