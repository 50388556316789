import PropTypes from 'prop-types';
import { ImageTransport } from './ImageTransport';

export const ARTWORK_TYPES = {
  original: 'original',
  commision: 'commision',
  reproduction: 'reproduction',
}

export const ARTWORK_MEDIUMS = [
  // Values need to exactly match those that currently exist in the database.
  {name: 'Ceramic'        , value: 'Ceramic'           } ,
  {name: 'Drawing'        , value: '2D - Drawing'      } ,
  {name: 'Fiber'          , value: '2D - Fiber'        } ,
  {name: 'Glass'          , value: '2D - Glass'        } ,
  {name: 'Metal'          , value: 'Metal'             } ,
  {name: 'Mixed Media'    , value: '2D - Mixed Media'  } ,
  {name: 'Mobile'         , value: 'Mobile'            } ,
  {name: 'Mosaic'         , value: '2D - Mosaic'       } ,
  {name: 'Mural'          , value: '2D - Mural'        } ,
  {name: 'Painting'       , value: '2D - Painting'     } ,
  {name: 'Photography'    , value: '2D - Photography'  } ,
  {name: 'Print Making'   , value: '2D - Print Making' } ,
  {name: 'Public Art'     , value: 'Public Art'        } ,
  {name: 'Reproduction'   , value: '2D - Poster'       } ,
  {
    name: 'Sculpture - Larger Than 31x31x31',
    value: 'Free Standing Sculpture - Larger Than 31x31x31',
  },
  {
    name: 'Sculpture - Smaller Than 30x30x30',
    value: 'Free Standing Sculpture - Smaller Than 30x30x30',
  },
  {name: 'Video'          , value: 'Video'             } ,
  {name: 'Wall Sculpture' , value: 'Wall Sculpture'    } ,
  {name: 'Wall Vinyl'     , value: 'Wall Vinyl'        } ,
  {name: 'Wood'           , value: 'Wood'              } ,
];

// Conversions for translating old mediums into new mediums.
export const ARTWORK_MEDIUM_CONVERSIONS = [
  ...ARTWORK_MEDIUMS,
  {name: 'Glass'        , value: '3D - Glass'                       } ,
  {name: 'Photography'  , value: '2D - black and white photography' } ,
  {name: 'Photography'  , value: '2D - color photography'           } ,
  {name: 'Public Art'   , value: 'Install'                          } ,
  {name: 'Public Art'   , value: 'Installation'                     } ,
  {name: 'Reproduction' , value: '2D - Giclee'                      } ,
  // {value: 'Digital File', name: ''},
];

export const ARTWORK_MEDIUM_VALUES = ARTWORK_MEDIUMS.map(m => m.value);
export const ARTWORK_MEDIUM_NAMES = ARTWORK_MEDIUMS.map(m => m.name);
export const ARTWORK_MEDIUM_VALUES_LEGACY = ARTWORK_MEDIUM_CONVERSIONS.map(m => m.value);

/**
 * Convert a legacy artwork medium into one of the new mediums.
 * Lookup a medium value in the ARTWORK_MEDIUM_CONVERSIONS map.
 * If it's found, use it's name to find the matching result
 * in the ARTWORK_MEDIUMS map and return that.
 */
export function getMedium(value) {
  const found = ARTWORK_MEDIUM_CONVERSIONS.find(m => m.value === value);
  if (found) {
    return ARTWORK_MEDIUMS.find(m => m.name === found.name);
  }
}

export const COST_TYPES = {
  artwork: 'ARTWORK',
  framing: 'FRAMING',
  other: 'OTHER',
}

export const NOTE_CATEGORIES = {
  PRIVATE: 'INTERNAL',
  PUBLIC: 'EXTERNAL',
  SUPPLIER: 'SUPPLIER',
};


// PROP TYPES
export const NumberOrStringPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
export const IdPropType = NumberOrStringPropType;
export const CSSValuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
export const RefPropType = PropTypes.oneOfType([
  PropTypes.shape({current: PropTypes.any}),
  PropTypes.func
]);

export const YupSchemaPropType = PropTypes.object;


// Note
export const NotePropTypeDef = {
  id: IdPropType,
  description: PropTypes.string.isRequired,
}
export const NotePropType = PropTypes.shape(NotePropTypeDef);


// Notes
export const NotesPropTypeDef = {
  public: NotePropType,
  private: NotePropType,
}
export const NotesPropType = PropTypes.shape(NotesPropTypeDef);


// User
export const UserPropTypeDef = {
  id: IdPropType.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  picture: PropTypes.string,
};
export const UserPropType = PropTypes.shape(UserPropTypeDef);


// Client
export const ClientPropTypeDef = {
  id: IdPropType.isRequired,
  name: PropTypes.string.isRequired,
};
export const ClientPropType = PropTypes.shape(ClientPropTypeDef);


// Artist
export const ArtistPropTypeDef = {
  id: IdPropType,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  phone_number: PropTypes.string,
  url: PropTypes.string,
};
export const ArtistPropType = PropTypes.shape(ArtistPropTypeDef);


// Vendor
export const VendorPropTypeDef = {
  name: PropTypes.string.isRequired,
};
export const VendorPropType = PropTypes.shape(VendorPropTypeDef);


// Job
export const JobPropTypeDef = {
  id: IdPropType.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  created_at : PropTypes.string.isRequired,
  start_date : PropTypes.string,
  due_date   : PropTypes.string,
  client: ClientPropType.isRequired,
};
export const JobPropType = PropTypes.shape(JobPropTypeDef);


/**
 * Uploaded Image
 * Metadata about a previously uploaded image as returned by the GraphQL API.
 */
export const UploadedImagePropTypeDef = {
  /**
   * The id of this image in the database.
   */
  id: IdPropType.isRequired,
  /**
   * The name of the actual file when it was uploaded.
   */
  file_name: PropTypes.string.isRequired,
  /**
   * A display name for the file.
   */
  title: PropTypes.string.isRequired,
  /**
   * The URL of the image in long term storage.
   */
  url: PropTypes.string.isRequired,
  /**
   * The id of the upload this image is associated with.
   */
  uploadId: IdPropType,
};
export const UploadedImagePropType = PropTypes.shape(UploadedImagePropTypeDef);

/**
 * AWS Image Upload
 * Data about an image that has been uploaded to AWS.
 */
export const AWSUploadPropTypeDef = {
  /**
   * The image data that was uploaded.
   */
  image: PropTypes.instanceOf(ImageTransport).isRequired,
  /**
   * The URL of the image in AWS.
   */
  location: PropTypes.string.isRequired,
  /**
   * The category of image being uploaded.
   */
  category: PropTypes.oneOf(['profile', 'artwork', 'floorplan']).isRequired,
};
export const AWSUploadPropType = PropTypes.shape(AWSUploadPropTypeDef);


// Floorplan
export const FloorplanPropTypeDef = {
  id: IdPropType.isRequired,
  title: PropTypes.string.isRequired,
  image: UploadedImagePropType.isRequired,
  notes: NotesPropType,
};
export const FloorplanPropType = PropTypes.shape(FloorplanPropTypeDef);


// Artwork Floorplan
export const ArtworkFloorplanPropTypeDef = {
  floorplanId: IdPropType.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  color: PropTypes.string,
  notes: NotesPropType,
};
export const ArtworkFloorplanPropType = PropTypes.shape(ArtworkFloorplanPropTypeDef);


// Cost
export const CostPropTypeDef = {
  note: PropTypes.string,
  description: PropTypes.string,
  wholesale: PropTypes.number.isRequired,
  retail: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
};
export const CostPropType = PropTypes.shape(CostPropTypeDef);


// Framing
export const FramingPropTypeDef = {
  id: IdPropType,
  notes: NotesPropType,
  cost: CostPropType.isRequired,
};
export const FramingPropType = PropTypes.shape(FramingPropTypeDef);


// Dimensions
export const DimensionsPropTypeDef = {
  id: IdPropType,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  depth: PropTypes.string,
};
export const DimensionsPropType = PropTypes.shape(DimensionsPropTypeDef);


// Artwork Summary
export const ArtworkSummaryPropTypeDef = {
  id: IdPropType,
  title: PropTypes.string.isRequired,
  medium: PropTypes.string,
  artist: ArtistPropType,
  images: PropTypes.arrayOf(UploadedImagePropType).isRequired,
};
export const ArtworkSummaryPropType = PropTypes.shape(ArtworkSummaryPropTypeDef);


// Artwork
export const ArtworkPropTypeDef = {
  ...ArtworkSummaryPropTypeDef,
  // vendor: VendorPropType,
  floorplans: PropTypes.arrayOf(ArtworkFloorplanPropType).isRequired,
  notes: NotesPropType,
  framing: FramingPropType,
  dimensions: DimensionsPropType.isRequired,
  // price
};
export const ArtworkPropType = PropTypes.shape(ArtworkPropTypeDef);


// Pin
// TODO Refactor pins to use Artworks
export const PinPropTypeDef = {
  // Use the dbId for actual requests to the backend.
  id: IdPropType.isRequired,
  // The identifier for this artwork on the server.
  // dbId: PropTypes.string,
  title: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
};
export const PinPropType = PropTypes.shape(PinPropTypeDef);


// Collection
export const CollectionPropTypeDef = {
  id: IdPropType.isRequired,
  title: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  user: UserPropType.isRequired,
  job: JobPropType,
  floor_plans: PropTypes.arrayOf(FloorplanPropType),
  artworks: PropTypes.arrayOf(ArtworkPropType),
  notes: NotesPropType,
  budget: PropTypes.number,
  gross: PropTypes.number,
};
export const CollectionPropType = PropTypes.shape(CollectionPropTypeDef);
