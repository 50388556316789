import { env } from './services/env';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

/**
 * Configure the Amplify library with the correct redirect URL.
 * For this to work, `src/aws-exports.js` needs to be generated
 * by the Amplify CLI. See the README on how to setup the developer
 * Amplify environment if you have issues.
 */
export function configureAmplify(
  // The redirect URL must include the trailing '/' in order
  // to match the OAuth configuration.
  redirectURL = window.location.origin + '/',
) {
  const config = {
    ...awsExports,
    oauth: {
      ...awsExports.oauth,
      redirectSignIn : redirectURL,
      redirectSignOut: redirectURL,
    }
  };

  if (!env.production && env.verbose) {
    console.log('[AMPLIFY] config', JSON.stringify(config, null, 2));
  }

  Amplify.configure(config);
}
