import React from 'react';
import PropTypes from 'prop-types';
import { Arrow } from 'react-laag';
import { useTransition, animated } from 'react-spring';
import composeRefs from '@seznam/compose-react-refs';

import {
  combineClasses,
  springs,
} from '~/util';

import styles from './DropdownMenu.module.scss';


function minimum(w, min) {
  return min ? Math.max(w, min) : w;
}

/*
 * Render the dropdown container. This will render the Menu,
 * position it relative to the input element and handle
 * animations.
 */
export const DropdownMenuWrapper = React.forwardRef(({
  isOpen,
  layerProps,
  layerSide,
  arrowStyle,
  triggerRect,
  // Need to rename animated so it does not conflict
  // with the react-spring animated component name.
  animated: useAnimation,
  children,
  className,
  minWidth,
  // trap these
  close,
  open,
  toggle,
  // pass the rest through
  ...menuProps
}, ref) => {
  const immediate = !useAnimation;
  // Firefox throws a ResizeObserver exception if triggerRect.width
  // changes late in a test run so if animations are off we set a static width.
  // TODO Cache the first width if in immediate mode.
  const width = !immediate ? minimum(triggerRect.width, minWidth) : minimum(350, minWidth);
  const layerStyles = {...layerProps.style, width};

  const content = (
    <React.Fragment>
      { children }
      <Arrow
        style={{
          ...arrowStyle,
          left: '50%',
        }}
        layerSide={layerSide}
        angle={60}
        size={5}
        roundness={1}
      />
    </React.Fragment>
  );

  // If animations are turned off, just render the
  // layer directly.
  let renderLayer = () => (isOpen &&
    <div
      data-test="dropdownOverlay"
      className={combineClasses(styles.suggestionsWrapper, layerSide)}
    >
      { content }
    </div>
  );

  const start = {
    width:   immediate ? width : 10,
    opacity: immediate ? 1 : 0,
    left:    immediate ? 0 : width/2,
  };
  const end = {
    width,
    left: 0,
    opacity: 1,
  };

  const transitions = useTransition(isOpen, null, {
    immediate: immediate,
    unique: true,
    config: springs.moderate,
    from:  { opacity: start.opacity, left: start.left, width: start.width },
    enter: { opacity: end.opacity,   left: end.left,   width: width },
    leave: { opacity: start.opacity, left: start.left, width: start.width },
  });

  // If animations are on, use a transition to render the layer.
  if (!immediate) {
    renderLayer = () => transitions.map(
      transitionProps => (
        transitionProps.item &&
          <animated.div
            data-test="dropdownOverlay"
            key={transitionProps.key}
            className={combineClasses(styles.suggestionsWrapper, layerSide)}
            style={{...transitionProps.props}}
          >
            { content }
          </animated.div>
        )
      );
  }

  return (
    <div data-testid="DropdownMenu"
      ref={composeRefs(layerProps.ref, ref)}
      className={combineClasses(styles.DropdownMenu, className)}
      style={layerStyles}
      {...menuProps}
    >
      { renderLayer() }
    </div>
  );
});

DropdownMenuWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  /**
   * props to add to the layer wrapper.
   */
  layerProps: PropTypes.object.isRequired,
  /**
   * props to add to the arrow component.
   */
  arrowStyle: PropTypes.object.isRequired,
  /**
   * The side that the layer is displayed on.
   */
  layerSide: PropTypes.string.isRequired,
  /**
   * Dimensions of the trigger element.
   */
  triggerRect: PropTypes.object.isRequired,
  animated: PropTypes.bool,
  /**
   * The menu content to render.
   */
  children: PropTypes.node.isRequired,
};

