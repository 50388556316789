import React from 'react';

import { combineClasses } from '~/util';

import { ReactComponent as Logomark } from '~/assets/dotfolio-logomark.svg';
import { ReactComponent as Logotype } from '~/assets/dotfolio-logotype.svg';

import './Logo.scss';

export default function Logo({
  className,
  ...rest
}) {
  return (
    <div className={combineClasses('logo', className)} {...rest}>
      <Logomark className="logomark" />
      <Logotype className="logotype" />
    </div>
  );
}
