import React from 'react';
import PropTypes from 'prop-types';

import { combineClasses } from '~/util';

import styles from './Card.module.scss';

/*
 * Wraps a card element such as a project
 * card on the projects dashboard and sets
 * styles and markup that are universal to
 * all cards.
 *
 * @prop {any} children The card contents to display.
 * @prop {string} [className] Additional classes to
 *   set on the card root element.
 * @prop {function} [onSelect] Callback when the card is clicked or the
 *   enter key is typed.
 */
export function Card({
  children,
  className,
  onSelect,
  clickable,
  ...rest
}) {
  const onClick = (e) => {
    if (onSelect) {
      onSelect(e);
    }
  }

  const onKeyUp = (e) => {
    if (e.keyCode === 13 && onSelect) {
      onSelect(e);
    }
  }

  return (
    <div
      data-test="card"
      className={combineClasses(
        styles.Card,
        onSelect || clickable ? 'clickable' : null,
      )}
      tabIndex={onSelect ? 0 : -1}
      onKeyUp={onSelect ? onKeyUp : null}
      onClick={onSelect ? onClick : null}
      {...rest}
    >
      <div className={combineClasses(styles.wrapper, className, 'card')}>
        { children }
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

