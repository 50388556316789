import { InMemoryCache } from 'apollo-cache-inmemory';

/**
 * Make an apollo cache client that can be used to cache results so they're
 * not always requested from the server.
 */
export function makeGraphQLCacheClient() {
  return new InMemoryCache({
    addTypename: true,
    // cacheRedirects allow us to redirect to queries to the cache if
    // the query is different but we know the data may already exist in
    // cache. For example, on the homepage we get cache all collections.
    // When we then visit a collection page, we want to pull the item from
    // cache even though the graphQL query is different.
    cacheRedirects: {
      Query: {
        collections: (_, query, { getCacheKey }) => {
          // If the query contains an id, try to get the item from cache first
          // in case we previously loaded it with a different query.
          if (query && query.where && query.where.id && query.where.id._eq) {
            return [
              getCacheKey({ __typename: 'collections', id: query.where.id._eq })
            ];
          }
          return _;
        }
      },
    },
  });
}
