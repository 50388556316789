import React from 'react';

import {
  combineClasses,
} from '~/util';

import styles from './ScrollareaWithToolbar.module.scss';

export function Toolbar({
  children,
  className,
  ...rest
}) {
  return (
    <div className={combineClasses(styles.toolbar, className)} data-test="toolbar" {...rest}>
      {children}
    </div>
  );
}

export function Scrollarea({
  className,
  children,
  ...rest
}) {
  return (
    <div className={combineClasses(styles.scrollarea, className)} data-test="scrollarea" {...rest}>
      {children}
    </div>
  );
}

export default function ScrollareaWithToolbar({
  children,
  className,
  ...rest
}) {
  return (
    <div
      data-test="scrollareaWithToolbar"
      {...rest}
      className={combineClasses(styles.ScrollareaWithToolbar, className)}
    >
      { children }
    </div>
  );
}
