import React from 'react';
import PropTypes from 'prop-types';

import {
  MenuWrapperWithArrow,
} from './MenuWrapperWithArrow.jsx';

/**
 * A menu wrapper that does not animate.
 */
export const StaticMenu = React.forwardRef(({
  isOpen,
  ...rest
}, ref) => {
  return isOpen
    ? <MenuWrapperWithArrow {...rest} />
    : null;
});

StaticMenu.props = {
  /**
   * An object of animation properties to be
   * passed to `react-spring` Transition component.
   * If you pass this property, the default animation
   * props will not be applied.
   * See https://www.react-spring.io/docs/props/transition
   */
  animationProps: PropTypes.object,
  /**
   * The content theme to use.
   */
  theme: PropTypes.oneOf(['dark', 'light']),
};
