import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'react-classset';


import {
  SectionTitle,
} from '../titles';
import {
  Loader,
} from '../loader';
import {
  combineClasses,
} from '~/util';

import {ReactComponent as CircleIcon} from '~/assets/icons/circle-check.svg';

import styles from './SaveProgress.module.scss';

/*
 * This component shows a saving progress animation. While the
 * `saved` prop is false, the progress animation will display
 * along with the `message` prop value. Once `saved` is set to
 * true, a "Save Complete" animation will be displayed. Once
 * the animation is finished, the `onComplete` callback will
 * be called, allowing you to change the page or remove the
 * component.
 */
export default function SaveProgress({
  message,
  state,
  className,
  onComplete,
  animated,
  ...rest
}) {
  const classes = classSet({
    [styles.SaveProgress] : true,
    [className]        : !!className,
    [styles.progress]  : state === 'progress',
    [styles.finishing] : state === 'finishing',
    [styles.done]      : state === 'done',
  });

  const rootRef = React.useRef();

  React.useEffect(() => {
    if (animated) {
      const check = rootRef.current.querySelector('.check');
      if (check) {
        check.addEventListener('animationend', onComplete);
        return () => check.removeEventListener('animationend', onComplete);
      }
    } else if (state === 'done') {
      // Wait a tick so we show the saved message for one tick (required for testing).
      setTimeout(onComplete);
    }
  });

  const icon = state === 'progress'
   ? <Loader data-test="progressIcon"      className={combineClasses(styles.icon, styles.progressIcon)} />
   : state === 'finishing'
   ? <CircleIcon data-test="finishingIcon" className={combineClasses(styles.icon, styles.savedIcon)} />
   : <CircleIcon data-test="doneIcon"      className={combineClasses(styles.icon, styles.savedIcon)} />

  return (
    <div data-test="saveProgress"
      data-state={state}
      className={classes}
      {...rest}
      ref={rootRef}
    >
      { icon }
      <SectionTitle data-test="message" className={styles.message}>{ message }</SectionTitle>
    </div>
  );
}

SaveProgress.states = {
  PROGRESS: 'progress',
  FINISHING: 'finishing',
  DONE: 'done',
};

SaveProgress.propTypes = {
  /*
   * The message to show the user as progress is made saving.
   */
  message: PropTypes.string.isRequired,
  /*
   * A string representing one of the 3 states of the component.
   * 'progress': Shows the message and the default loader component.
   * 'finishing': Shows the message and a spinning circle.
   * 'done': Shows the "Save Complete" animation and results in
   *   the `onComplete` callback being called once the animation
   *   is complete.
   */
  state: PropTypes.oneOf(['progress', 'finishing', 'done']),
  /*
   * A callback that will be called once the "Save Complete" animation
   * has finished.
   */
  onComplete: PropTypes.func.isRequired,
  /*
   * False = turn off animations. The `onComplete` callback will be
   * called one tick after the `saved` prop is set to true.
   */
  animated: PropTypes.bool,
};

SaveProgress.defaultProps = {
  state: 'progress',
};

