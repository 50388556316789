import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import * as Yup from 'yup';

import {
  LegacyArtistSchema,
  ArtistPropType,
} from '~/model';
import {
  FormikAutocomplete,
  useAutoSaveFormInitialized,
  // FormikDebug,
} from '../../../forms';

import {
  ArtistSuggestion,
} from '../ArtistSuggestion.jsx';
import { AutoSaveForm } from '../../../forms/AutoSaveForm.jsx';

import styles from './ArtistSearchForm.module.scss';

const ArtistSchema = Yup.object().shape({
  artist: LegacyArtistSchema
    .transform(v => v === '' ? null : v)
    // If an id for this artist is not defined, nullify the value.
    // This way we'll get an "Artist is required" error message.
    .transform(v => v && !v.id ? null : v)
    .nullable(),
});

export function ArtistSearchForm({
  name,
  values,
  onChange,
  onSearch,
  onReady,
  focus,
  animated,
  debounceSpeed,
  ...rest
}) {
  // In order to display the artist value as a combination
  // of first and last name, we need to nest the form values
  // under an object key and validate against that object.
  // For that reason, we'll inject the values under an artist key.
  const v = {artist: values};

  const handleChange = useAutoSaveFormInitialized(
    data => {
      // Unnest the data from the artist key before emitting.
      onChange({
        ...data,
        values: data.values.artist,
      });
    },
    onReady
  );

  return (
    <AutoSaveForm
      name={name}
      initialValues={v}
      schema={ArtistSchema}
      onChange={handleChange}
    >
      <Form {...rest}>
        <FormikAutocomplete
          data-test="artistSearchAutocomplete"
          className={styles.artistSearchInput}
          name="artist"
          label="Artist"
          animated={animated}
          debounceSpeed={debounceSpeed}
          suggestionToString={s => {
            // Manually create the full name because if we switch from the
            // create artist form we will not have a "fullName" prop.
            return s ? [s.first_name, s.last_name].filter(n => !!n).join(' ') : '';
          }}
          onSearch={onSearch}
          renderSuggestion={({itemProps, suggestion: {fullName, email}}) =>
            <ArtistSuggestion
              {...itemProps}
              name={fullName}
              email={email}
            />
          }
        >
          <input type="text" data-test="artistSearch" autoFocus={focus} />
        </FormikAutocomplete>
      </Form>
    </AutoSaveForm>
  );
}

ArtistSearchForm.propTypes = {
  name: PropTypes.string,
  values: ArtistPropType,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  animated: PropTypes.bool,
  debounceSpeed: PropTypes.number,
};

