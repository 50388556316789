import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-spring/renderprops';

import {
  MenuWrapperWithArrow,
} from './MenuWrapperWithArrow.jsx';

import {
  springs,
} from '~/util';


function getArrowTransformOrigin(arrowStyle, layerSide) {
  let x, y;
  // Examples:
  // side top: top='100%' left=58
  // side bottom: bottom='100%' left=58
  // side right: right='100%' top=34
  // side left: left='100%' top=34

  if (layerSide === 'bottom') {
    y = 'top';
    x = `${arrowStyle.left}px`;
  } else if (layerSide === 'top') {
    y = 'bottom';
    x = `${arrowStyle.left}px`;
  } else if (layerSide === 'right') {
    x = 'left';
    y = `${arrowStyle.top}px`;
  } else if (layerSide === 'left') {
    x = 'right';
    y = `${arrowStyle.top}px`;
  }
  return `${x} ${y}`;
}

/**
 * Menu wrapper that uses react-spring to
 * animated the menu opening and closing
 * by zooming in and out on the menu.
 */
export const ZoomInMenu = React.forwardRef(({
  isOpen,
  layerProps,
  layerSide,
  arrowStyle,
  animationProps = {
    from   : {opacity : 0, transform : 'scale(0.5)'},
    enter  : {opacity : 1, transform : 'scale(1)'},
    leave  : {opacity : 0, transform : 'scale(0.5)'},
    config : springs.fast,
  },
  ...rest
}, ref) => {
  const wrap = (render) => (
    <Transition
      items={isOpen}
      {...animationProps}
    >
      { show => show ? render : null }
    </Transition>
  );

  return wrap(props => {
    // Merge transition styles and positioning styles.
    const style = {
      ...props,
      ...layerProps.style,
      transformOrigin: getArrowTransformOrigin(arrowStyle, layerSide),
    };

    return (
      <MenuWrapperWithArrow
        {...rest}
        {...layerProps}
        style={style}
        layerSide={layerSide}
        arrowStyle={arrowStyle}
      />
    );
  });
});

ZoomInMenu.propTypes = {
  /**
   * An object of animation properties to be
   * passed to `react-spring` Transition component.
   * If you pass this property, the default animation
   * props will not be applied.
   * See https://www.react-spring.io/docs/props/transition
   */
  animationProps: PropTypes.object,
  /**
   * The content theme to use.
   */
  theme: PropTypes.oneOf(['dark', 'light']),
};

