import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import './MultiStep.scss';

export default function MultiStep({
  steps,
  url,
  renderStep,
  ...rest
}) {
  const history = useHistory();
  const location = useLocation();

  const [stepIndex, setStepIndex] = useState(0);

  const [touched, setTouched] = useState(() =>
    steps.reduce((acc, step) => {
      acc[step] = false;
      return acc;
    }, {})
  );

  // If we deeplinked into the flow, redirect to the beginning.
  const anyTouched = Object.keys(touched).findIndex((key) => touched[key] === true);
  const isFirstPath = location.pathname.endsWith( steps[0] );
  const isUnPathed  = location.pathname.endsWith( url );
  if ( anyTouched < 0 && !isFirstPath && !isUnPathed ) {
    return <Redirect to={url} />;
  }

  // Update the URL to match the current step.
  const updateURL = (name) => {
    history.push(`${url}/${name}`);
  }

  const logIndexError = (index) => {
    const message = 'Attempted to navigate MultiStep to a stepIndex that is out of bounds. ' +
      `There are currently ${steps.length} steps and you attempted to go to index ${index}`;
    console.error(message);
  };

  const previous = () => {
    const s = stepIndex - 1;
    if (s >= 0) {
      const last = steps[stepIndex];
      const next = steps[s];
      setTouched({
        ...touched,
        [last]: true
      });
      setStepIndex(s);
      updateURL(next);
    } else {
      logIndexError(s);
    }
  };

  const next = () => {
    const s = stepIndex + 1;
    if (s < steps.length) {
      const last = steps[stepIndex];
      const next = steps[s];
      setTouched({
        ...touched,
        [last]: true
      });
      setStepIndex(s);
      updateURL(next);
    } else {
      logIndexError(s);
    }
  };

  const goToIndex = (index) => {
    if (index > -1 && index < steps.length) {
      const last = steps[stepIndex];
      const next = steps[index];
      setTouched({
        ...touched,
        [last]: true
      });
      setStepIndex(index);
      updateURL(next);
    } else {
      logIndexError(index);
    }
  };

  const childProps = {
    ...rest,
    next,
    previous,
    goToIndex,
    reset: () => goToIndex(0),
  };

  const routes = steps.slice().reverse().map((step, i) => {
    const path = i === steps.length - 1
      ? [`${url}/${step}`, url]
      :  `${url}/${step}`;

    return (
      <Route
        path={path}
        render={() =>
          renderStep({
            ...childProps,
            stepName: step,
            stepIndex: steps.indexOf(step)
          })
        }
        key={step}
      />
    );
  });

  return (
    <Switch>
      { routes }
    </Switch>
  );
}

MultiStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
  renderStep: PropTypes.func.isRequired,
};

