import React from 'react';
import PropTypes from 'prop-types';
import {
  Arrow,
} from 'react-laag';

import {
  combineClasses,
} from '~/util';

import styles from './MenuWrapperWithArrow.module.scss';

/**
 * A simple wrapper around the Popover menu prop
 * which places an arrow relative to the menu content.
 */
export const MenuWrapperWithArrow = React.forwardRef(({
  layerProps,
  arrowStyle,
  layerSide,
  children,
  // trap these
  disableAnimations,
  close,
  open,
  toggle,
  triggerRect,
  theme = 'dark',
  ...rest
}, ref) => {
  return (
    <div data-testid="MenuWrapper"
      className={combineClasses(
        styles.MenuWrapperWithArrow,
        theme === 'dark' ? 'dark' : 'light'
      )}
      ref={ref}
      {...layerProps}
      {...rest}
    >
      <Arrow
        style={arrowStyle}
        backgroundColor={theme === 'dark' ? 'black' : 'white'}
        layerSide={layerSide}
        angle={60}
        size={5}
        roundness={1}
      />
      { children }
    </div>
  );
});

MenuWrapperWithArrow.propTypes = {
  /**
   * Any props to apply to the menu wrapper.
   */
  layerProps: PropTypes.object,
  /**
   * The style prop defining styling and placement for the arrow.
   */
  arrowStyle: PropTypes.object,
  /**
   * The side that the arrow will be attached to.
   */
  layerSide: PropTypes.string,
  /**
   * The theme to apply
   */
  theme: PropTypes.oneOf(['dark', 'light']),
  /**
   * The menu content to display.
   */
  children: PropTypes.node.isRequired,
};
