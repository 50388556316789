import { ImageTransport } from '~/model';

import { textToURLs, normalizeToList } from './format';

/**
 * Convert images from the API into
 * ImageTransport objects.
 *
 * @param {object[]} images
 * @return {ImageTransport[]}
 */
export function imagesToImageTransports(
  images = [],
) {
  images = normalizeToList(images);
  return images.map(image => new ImageTransport(
    image.url,
    image.file_name,
    image
  ));
}

/**
 * Convert the provided File objects into
 * ImageTransport objects, filtering any
 * objects that aren't files or images.
 *
 * @param {File|File[]} files
 * @return {ImageTransport[]}
 */
export function filesToImageTransports(
  files,
) {
  files = normalizeToList(files);

  // TODO Could also accept plain text files that
  // include lists of URLs.
  // Use a Set to guarantee uniqueness in the input list.
  return Array.from(new Set(
    files.filter(file =>
      // It must be a File or a Blob
      file instanceof Blob &&
      // Only image files are allowed.
      file.type.startsWith('image/')
    )
  )).map(file => new ImageTransport(file, file.name));
}

/**
 * Convert the provided array of URLs into an
 * array of ImageTransport objects filtering any
 * duplicates.
 *
 * @param {string|string[]} text - The list of URLs
 *   to convert into ImageTransport objects.
 * @return {ImageTransport[]}
 */
export function urlsToImageTransports(
  text,
) {
  const urls = normalizeToList(text);
  const list = urls.map(url => textToURLs(url))
    .flat();

  return Array.from(new Set(list))
    .map(url => new ImageTransport(url, url));
}

