import React from 'react';

// Don't import the full components package!
import { PageLoader } from '~/components/loader';
import { BundleLoadError } from '~/pages/bundle-load-error';
import { useAuthenticatedUser } from './useAuthenticatedUser';

export function WithAuthenticatedUser({
  authResponse,
  children,
  doProfileUpload,
  profileUploadDelay,
}) {
  const {loading, error, user} = useAuthenticatedUser(
    authResponse,
    doProfileUpload,
    profileUploadDelay
  );

  if (error) {
    console.error('Error retreiving authenticated user data:', error);
    return (
      <BundleLoadError
        message="There was an error logging you in."
      />
    );
  } else if (!loading && user) {
    // Render the children with a user prop
    return React.cloneElement(children, {user});
  }
  else {
    // Allow the page loader to keep running.
    return <PageLoader />;
  }
}
