import React from 'react';
import PropTypes from 'prop-types';

import {
  SectionTitle,
} from '../titles';
import {
  Loader,
} from '../loader';

import './NotFound.scss';

export default function NotFound({title, subtitle}) {
  return (
    <div className="not-found" data-test="notFound">
      <SectionTitle className="title" data-test="title">{ title }</SectionTitle>
      { subtitle &&
        <p className="subtitle" data-test="subtitle">{ subtitle }</p>
      }
      <Loader />
    </div>
  );
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
