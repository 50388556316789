import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classSet from 'react-classset';
import { Field } from 'formik';
import get from 'lodash.get';

import {
  HelpIcon,
} from '../help-icon';
import {
  useId,
} from '~/util';

import './LabeledInput.scss';

function searchForPossibleInputElements(root) {
  if (root) {
    let inputTypes = ['textarea', 'input'];
    let el;
    for (let i = 0; i < inputTypes.length; i++) {
      el = root.querySelector(inputTypes[i]);
      if (el) {
        break;
      }
    }
    return el;
  } else {
    return null;
  }
}

function getInputElement(root, children) {
  const c = React.Children.toArray(children);
  let el;
  if (c.length > 0 && c[0].type && typeof c[0].type === 'string') {
    el = root.querySelector(c[0].type);
  } else {
    el = searchForPossibleInputElements(root);
  }
  return el;
}

export default function LabeledInput({
  label,
  help,
  helpWarning,
  error,
  className,
  children,
  ...rest
}) {
  const errorMessageId = useId('labeled-input-error');
  const [input, setInput] = useState();
  const [required, setRequired] = useState(false);
  const [focused, setFocused] = useState(false);

  const rootRef = useRef();

  const isEmpty = () => !input || !input.value || input.value.trim() === '';

  const classes = classSet({
    'labeled-input': true,
    [className]: !!className,
    'required': required,
    'error': error,
    'focused': focused,
    'empty': isEmpty(),
  });

  // Get the nested input element and add event listeners.
  useEffect(() => {
    const inputEl = getInputElement(rootRef.current, children);

    if (!inputEl)
      console.warn('Could not determine the input element for LabeledInput:', rootRef.current);

    // Only want to do check this on mount.
    setFocused(
      inputEl &&
      document.activeElement &&
      document.activeElement === inputEl
    );

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    if (inputEl) {
      // Associate the input with it's error message.
      inputEl.setAttribute('aria-errormessage', errorMessageId);

      // Listen for focus events so we can re-render.
      inputEl.addEventListener('focus', onFocus);
      inputEl.addEventListener('blur', onBlur);
    }

    setInput(inputEl);

    return () => {
      if (inputEl) {
        inputEl.removeEventListener('focus', onFocus);
        inputEl.removeEventListener('blur', onBlur);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // Update input attributes as props change.
  useEffect(() => {
    if (input) {
      // Keep aria attributes up-to-date during validation.
      input.setAttribute('aria-invalid', String(!!error));

      // If the required attribute changes...
      setRequired( input.hasAttribute('required') );
    }
  }, [error, input]);


  const labelDef = <span className="form-label" data-test="placeholder">{label}</span>;

  const errorDef = (
    <span
      data-test="message"
      id={errorMessageId}
      className="form-error"
      // This text will change dynamically so tell screen readers
      // to announce changes.
      aria-live="polite"
    >
      &nbsp;{error}
    </span>
  );

  const header = label || error
    ? <div className="form-label-with-message">
        {labelDef}
        {errorDef}
      </div>
    : null;

  return (
    <label data-test="labeledInput" {...rest} className={classes} ref={rootRef}>
      { header }
      <span className="labeled-input-row-with-help">
        <span className="labeled-input-row">
          { children }
        </span>
        { help &&
          <HelpIcon
            warn={helpWarning}
            className="help"
            tooltipProps={{className: 'tooltip'}}
          >
            { help }
          </HelpIcon>
        }
      </span>
    </label>
  );
}

LabeledInput.propTypes = {
  /**
   * The `<input>` element to render.
   */
  children: PropTypes.element.isRequired,
  /**
   * The floating display label text.
   */
  label: PropTypes.string.isRequired,
  /**
   * An error message to display.
   */
  error: PropTypes.string,
};


export function FormikLabeledInput({
  name,
  label,
  className,
  children,
  ...rest
}) {
  return (
    <Field name={name}>
      {({form, field}) => {
        const nextProps = {
          ...field,
          form,
        };
        const touched = get(form.touched, name);
        const error = get(form.errors, name);

        return (
          <LabeledInput
            data-test='labeledInput'
            {...rest}
            className={className}
            label={label}
            error={touched ? error : null}
          >
            { React.cloneElement(children, nextProps) }
          </LabeledInput>
        );
      }}
    </Field>
  );
}

FormikLabeledInput.propTypes = {
  /**
   * The `<input>` element to render.
   */
  children: PropTypes.element.isRequired,
  /**
   * The floating display label text.
   */
  label: PropTypes.string.isRequired,
  /**
   * The name associating the child input
   * in the form values. See https://jaredpalmer.com/formik/docs/api/field#name
   */
  name: PropTypes.string.isRequired,
  /**
   * An error message to display. Generally you
   * can let this component and Formik handle error display
   * but you can also pass this prop if you need
   * to display an error returned by the server.
   */
  error: PropTypes.string,
};

