import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import {
  SectionTitle,
} from '../../titles';
import {
  ImageUpload,
  ImageList,
} from '../../images';
import {
  Button,
  ErrorButton,
} from '../../buttons';
import {
  FormColumn,
  FormLayout,
  FormikLabeledInput,
  TextareaAutosize,
  useCallWhenFormikReady,
  // FormikDebug,
} from '../../forms';
import {
  ImageTransport,
  ArtworkSchema,
  NotesPropType,
} from '~/model';
import {
  useScrollToTop,
  merge,
  normalizeFormDefaults,
} from '~/util';

import styles from './Upload.module.scss';

export function UploadForm({
  imageCount,
  values,
  onRemoveArtwork,
  onSubmit,
  onReady,
}) {
  const d = normalizeFormDefaults(ArtworkSchema.default());
  const v = merge(d, values);

  const whenReady = useCallWhenFormikReady();
  React.useEffect(() => whenReady(onReady), []); // eslint-disable-line react-hooks/exhaustive-deps

  // Prevent propagation of the paste event so the
  // document level paste handler doesn't handle
  // paste events that occur in the input elements.
  const onPaste = e => {
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.stopPropagation();
  };

  return (
    <Formik
      initialValues={v}
      validationSchema={ArtworkSchema}
      onSubmit={onSubmit}
    >
      {({values, touched, errors, isValid, setFieldTouched, setFieldValue}) => {
        return (
          <Form className="upload" data-test="uploadPage">
            <FormLayout>
              <FormColumn>
                <FormikLabeledInput
                  name="notes.public.description"
                  label="Notes for Client"
                  help="Artwork notes that you'd like the client to see in the proposal."
                  helpWarning
                >
                  <TextareaAutosize autoFocus data-testid="publicNotes" onPaste={onPaste} />
                </FormikLabeledInput>
                <FormikLabeledInput
                  name="notes.private.description"
                  label="Internal Notes"
                  help="Notes that will only be visible to 9 Dot Arts."
                >
                  <TextareaAutosize data-testid="privateNotes" onPaste={onPaste} />
                </FormikLabeledInput>
                <div className={styles.actions}>
                  <Button
                    data-test="next"
                    className={styles.nextButton}
                    type="submit"
                    disabled={!isValid || imageCount === 0}
                  >
                    Next
                  </Button>
                  {onRemoveArtwork &&
                    <ErrorButton data-testid="removeArtworkButton"
                      className={styles.removeButton}
                      onClick={onRemoveArtwork}
                    >
                      Remove From Collection
                    </ErrorButton>
                  }
                </div>
              </FormColumn>
            </FormLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

UploadForm.propTypes = {
  imageCount: PropTypes.number.isRequired,
  values: PropTypes.shape({
    // type: PropTypes.string,
    notes: NotesPropType,
  }),
  /**
   * A callback to remove the artwork from the current collection.
   * If not specified, the remove button is not shown.
   */
  onRemoveArtwork: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};


/**
 * @param {object} props
 * @param {object} [props.values]
 * @param {ImageTransport[]} [props.images]
 * @param {function} [props.onSubmit]
 * @param {function} [props.onRemoveArtwork]
 * @param {function} [props.onReady]
 * @param {function} [props.onDropError]
 * @param {boolean} [props.animated]
 */
export function Upload({
  values,
  images = [],
  onSubmit,
  onRemoveArtwork,
  onReady,
  onDropError,
  animated = true,
}) {
  useScrollToTop();

  const [removed, setRemoved] = React.useState([]);
  const [uploads, setUploads] = React.useState([]);

  // This is the list of images to display in the ImageList.
  // It is the original images - removed images + new uploads.
  const allImages = images.filter(i => removed.indexOf(i) < 0)
    .concat(uploads);

  const onAddImages = newImages => {
    setUploads(list => list.concat(newImages));
  };

  const removeImage = (index, image) => {
    const newUploads = uploads.filter(i => !i.equals(image));

    ReactDOM.unstable_batchedUpdates(() => {
      // If we removed a image that was newly added,
      // update the uploads list.
      if (newUploads.length !== uploads.length) {
        setUploads(newUploads);
      }
      // If we removed an image that was in the original
      // image list, add it to the removed list.
      else {
        setRemoved(r => r.concat([image]));
      }
    });
  }

  // Submit the values and images separately so
  // we can more easily identify new and removed images.
  const handleSubmit = values => onSubmit(values, allImages, uploads, removed);

  return (
    <ImageUpload
      images={allImages}
      onAddImages={onAddImages}
      onError={onDropError}
      animated={animated}
      className={styles.Upload}
    >
      {({uploadImages}) =>  {
        return (
          <>
            <div className={styles.header}>
              <SectionTitle className={styles.title}>Upload Artwork</SectionTitle>
              <ImageList
                images={allImages}
                onRemoveImage={removeImage}
                onBrowse={uploadImages}
                animated={animated}
              />
            </div>
            <UploadForm
              imageCount={allImages.length}
              values={values}
              onRemoveImage={removeImage}
              onRemoveArtwork={onRemoveArtwork}
              onSubmit={handleSubmit}
              onReady={onReady}
            />
          </>
        );
      }}
    </ImageUpload>
  );
}

Upload.propTypes = {
  // This needs to be kept loose so we can pass various
  // parts of the artwork metadata around.
  values: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.instanceOf(ImageTransport)),
  onSubmit: PropTypes.func.isRequired,
  onRemoveArtwork: PropTypes.func,
  onDropError: PropTypes.func,
  animated: PropTypes.bool,
};

