import React, {forwardRef} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  combineClasses,
} from '~/util';
import {
  Button,
} from './Buttons.jsx';

import styles from './IconButtons.module.scss';

import { ReactComponent as EditIcon } from '~/assets/icons/edit.svg';
import { ReactComponent as SendIcon } from '~/assets/icons/send.svg';
import { ReactComponent as BackIcon } from '~/assets/icons/back-arrow.svg';
import { ReactComponent as AddIcon } from '~/assets/icons/circle-plus.svg';
import { ReactComponent as UploadIcon } from '~/assets/icons/upload.svg';
import { ReactComponent as TrashIcon } from '~/assets/icons/trash.svg';
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg';
import { ReactComponent as ImageIcon } from '~/assets/icons/image-placeholder.svg';
import { ReactComponent as ChevronIcon } from '~/assets/icons/chevron.svg';
import { ReactComponent as DoubleChevronIcon } from '~/assets/icons/double-chevron.svg';
// import { ReactComponent as EmptyPageIcon } from '~/assets/icons/page.svg';
import { ReactComponent as PageIcon } from '~/assets/icons/file-text.svg';
import { ReactComponent as PrintIcon } from '~/assets/icons/printer.svg';
import { ReactComponent as SearchIcon } from '~/assets/icons/search.svg';
import { ReactComponent as CheckIcon } from '~/assets/icons/check.svg';
import { ReactComponent as GoogleLogo } from '~/assets/icons/logo-google-simple.svg';
import { ReactComponent as AlertIcon } from '~/assets/icons/alert-triangle.svg';

export const IconLink = forwardRef(({
  icon,
  children,
  className,
  ...rest
}, ref) => {
  let iconElement = null;
  if (icon) {
    iconElement = React.cloneElement(icon, {
      ...icon.props,
      // If there are additional children next to the icon, modify the styling.
      className: React.Children.count(children) > 0
        ? combineClasses(
            icon.props.className,
            styles.withText,
            styles.icon,
          )
        : icon.props.className,
    });
  }

  return (
    <Link
      className={combineClasses(styles.IconLink, styles.transparent, className)}
      {...rest}
      ref={ref}
    >
      { iconElement }
      { children }
    </Link>
  );
});

IconLink.propTypes = {
  /**
   * The icon component to render inside this anchor tag.
   */
  icon: PropTypes.element,
  /**
   * You can pass children if you also need text in this anchor tag.
   */
  children: PropTypes.node,
};

export const IconButton = forwardRef(({className, icon, children, ...rest}, ref) => {
  let iconElement = null;
  if (icon) {
    iconElement = React.cloneElement(icon, {
      ...icon.props,
      // If there are additional children next to the icon, modify the styling.
      className: React.Children.count(children) > 0
        ? combineClasses(
          icon.props.className,
          styles.withText,
          styles.icon,
        )
        : icon.props.className,
    });
  }

  return (
    <button
      ref={ref}
      className={combineClasses(styles.IconButton, styles.transparent, className)}
      type="button"
      {...rest}
    >
      { iconElement }
      { children }
    </button>
  );
});


export const AddToButton = forwardRef(({added, addToWhat: what, className, ...rest}, ref) =>
  <Button
    className={combineClasses(
      styles.AddToButton,
      added ? 'added' : null,
      className,
    )}
    {...rest}
  >
    <span className={styles.centeredTextWrapper}>
      <CheckIcon className={combineClasses(styles.addToIcon)} data-test="icon" />
      Add<span className={styles.pastTense} data-test="pastTense">ed</span> To {what}
    </span>
  </Button>
);


// VERTICAL
export const EditButton = forwardRef(({className, ...rest}, ref) => {
  return (
    <IconButton
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <EditIcon className={styles.edit} />
      }
    >
      Edit
    </IconButton>
  );
});

export const SendButton = forwardRef(({className, ...rest}, ref) => {
  return (
    <IconButton
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <SendIcon className={styles.send} />
      }
    >
      Send
    </IconButton>
  );
});

export const PrintButton = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconButton
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <PrintIcon className={styles.print} />
      }
    >
      { children || 'Print' }
    </IconButton>
  );
});

export const FileButton = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconButton
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <PageIcon className={styles.file} />
      }
    >
      { children || 'File' }
    </IconButton>
  );
});

export const PrintLink = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconLink
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <PrintIcon className={styles.print} />
      }
    >
      { children || 'Print' }
    </IconLink>
  );
});

export const AlertLink = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconLink
      className={combineClasses(styles.vertical, className)}
      {...rest}
      ref={ref}
      icon={
        <AlertIcon className={styles.alert} />
      }
    >
      { children || 'Warning' }
    </IconLink>
  );
});


// HORIZONTAL
export const UploadButton = forwardRef(({
  multiple,
  accept,
  onChange,
  className,
  children,
  ...rest
}, ref) => {
  // Use a label wrapping a hidden input element because browsers will block
  // uploads from a standard button click.
  // https://stackoverflow.com/questions/572768/styling-an-input-type-file-button/25825731#25825731
  return (
    <label
      className={combineClasses(styles.IconButton, styles.uploadButtonLabel, className)}
    >
      <UploadIcon className={combineClasses(styles.upload, styles.withText)} />
      <span>{ children || 'Browse For File' }</span>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        className={styles.fileInput}
        onChange={onChange}
        {...rest}
      />
    </label>
  );
});

UploadButton.propTypes = {
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

UploadButton.defaultProps = {
  multiple: true,
  accept: 'image/*',
};

export const DeleteButton = forwardRef(({children, ...rest}, ref) => {
  return (
    <IconButton
      {...rest}
      ref={ref}
      icon={
        <TrashIcon className={styles.trash} />
      }
    >
      { children }
    </IconButton>
  );
});

export const AddButton = forwardRef(({children, ...rest}, ref) => {
  return (
    <IconButton
      {...rest}
      ref={ref}
      icon={
        <AddIcon className={styles.add} />
      }
    >
      { children }
    </IconButton>
  );
});

export const ImageButton = forwardRef(({children, ...rest}, ref) =>
  <IconButton
    {...rest}
    ref={ref}
    icon={
      <ImageIcon className={styles.image} />
    }
  >
    { children }
  </IconButton>
);

export const ImageLink = forwardRef(({children, ...rest}, ref) =>
  <IconLink
    {...rest}
    ref={ref}
    icon={
      <ImageIcon className={styles.image} />
    }
  >
    { children }
  </IconLink>
);

export const GoogleSignInButton = forwardRef(({className, children, ...rest}, ref) =>
  <Button
    className={combineClasses(styles.GoogleSignInButton, className)}
    {...rest}
    ref={ref}
  >
    <GoogleLogo className={styles.icon} />
    Sign In With Google
  </Button>
);


// NO TEXT
export const BackLink = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconLink
      {...rest}
      ref={ref}
      className={combineClasses(className, styles.large)}
      icon={
        <BackIcon className={styles.back} />
      }
    >
    </IconLink>
  );
});

export const CloseButton = forwardRef(({...rest}, ref) => {
  return (
    <IconButton
      {...rest}
      ref={ref}
      icon={
        <CloseIcon className={styles.close} />
      }
    >
    </IconButton>
  );
});

export const RemoveButton = forwardRef(({className, ...rest}, ref) => {
  return (
    <IconButton
      className={combineClasses(styles.small, className)}
      {...rest}
      ref={ref}
      icon={
        <CloseIcon className={styles.remove} />
      }
    >
    </IconButton>
  );
});

export const ChevronButton = forwardRef(({className, ...rest}, ref) => {
  return (
    <IconButton
      className={className}
      {...rest}
      ref={ref}
      icon={
        <ChevronIcon className={styles.chevron} />
      }
    >
    </IconButton>
  );
});

export const DoubleChevronButton = forwardRef(({className, ...rest}, ref) => {
  return (
    <IconButton
      className={className}
      {...rest}
      ref={ref}
      icon={
        <DoubleChevronIcon className={styles.doubleChevron} />
      }
    >
    </IconButton>
  );
});

export const SearchButton = forwardRef(({children, className, ...rest}, ref) =>
  <IconButton
    className={combineClasses(styles.search, className)}
    {...rest}
    ref={ref}
    icon={
      <SearchIcon className={styles.search} />
    }
  >
    { children }
  </IconButton>
);

export const SearchLink = forwardRef(({children, className, ...rest}, ref) => {
  return (
    <IconLink
      className={combineClasses(styles.search, className)}
      {...rest}
      ref={ref}
      icon={
        <SearchIcon className={styles.search} />
      }
    >
      { children || 'Search' }
    </IconLink>
  );
});

