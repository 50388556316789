import React from 'react';
import PropTypes from 'prop-types';

import {
  combineClasses,
} from '~/util';

import styles from './FieldRow.module.scss';

export function FieldRow({
  responsive,
  children,
  className,
  ...rest
}) {
  return (
    <div
      data-testid="FieldRow"
      className={combineClasses(styles.FieldRow, className, responsive ? styles.responsive : '')}
      {...rest}
    >
      {children}
    </div>
  );
}

FieldRow.propTypes = {
  /**
   * If "true", then wrap the row into a horizontal column on
   * small screens.
   */
  responsive: PropTypes.bool,
  /**
   * The elements you'd like to distribute in a row.
   * Usually these would be `FormikLabeledInput` but can
   * be anything that React can render.
   */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
