import React from 'react';
import ReactDOM from 'react-dom';

import { env } from '../../env';

/**
 * This hook gets the data for the currently authenticated user.
 * Because this process is async, the hook returns the loading
 * state of the process in addition to the user data once
 * authentication is complete.
 *
 * PROCESS:
 * On page load, this hook will attempt to retrieve the
 * user's session data. If they are already authenticated,
 * it will return the user data. If they are not authenticated,
 * then it will return null data for the user and the App
 * component will render the login page. When the user logs
 * in, the page will refresh and this hook will be run again,
 * at which point the authenticated user data will be returned.
 *
 * NOTE:
 * There could be an issue with the current authentication process
 * where the user is in the process of creating artworks and
 * their session ends. In this case, the page refresh when they
 * re-login will cause them to lose their work. If we run into
 * this situation, it is possible to run authentication in a
 * popup window. To do this, we will probably need to integrate
 * with Google Auth directly as shown here:
 * https://docs.amplify.aws/lib/auth/advanced/q/platform/js#google-sign-in-react
 *
 * To help with this process, we might be able to use `react-google-login`
 * or similar to simplify the Google integration. Once it returns
 * the session token, we can pass it to Amplify.
 * https://www.npmjs.com/package/react-google-login
 */
export function useGoogleAuth(api) {
  // loading = true while the authentication state is unknown.
  const [loading, setLoading] = React.useState(true);
  // initialized = true once we've been authenticated the first
  // time. It's possible that later on, the session may time
  // out and authenticated gets set to false but initialized
  // is still true.
  const [initialized, setInitialized] = React.useState(false);
  // user = null if the user is unauthenticated.
  const [authResponse, setAuthResponse] = React.useState();
  const authenticated = !!authResponse;

  const updateState = authResponse => {
    ReactDOM.unstable_batchedUpdates(() => {
      // Only set initialized when we are logged in.
      if (authResponse) setInitialized(true);
      setAuthResponse(authResponse);
      setLoading(false);
    });
  }

  const logout = () => {
    return api.logout().then(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        setLoading(false);
        setAuthResponse(null);
      });
    });
  };

  React.useEffect(() => {
    // When running in a test environment, the sign in/out process
    // must not reload the page so we listen to the "sign[in|out]"
    // events from the API client in order to simulate auth state changes.
    if (env.mock) {
      api.addEventListener('signIn', (data) => {
        updateState(data)
      });

      api.addEventListener('signOut', () => {
        updateState(null);
      });
    }
  }, [api]);

  React.useEffect(() => {
    if (!authenticated) {
      api.getUser()
        .then(authResponse => {
          updateState(authResponse);
        })
        .catch((error) => {
          updateState(null);
        });
    }
  }, [api, authenticated]);

  return {
    loading,
    initialized,
    authenticated,
    authResponse,
    logout,
  };
}
