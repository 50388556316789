import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend'
import { separateSettledPromises } from '@thesoulfresh/utils';

import { ImageTransport, ImageTransportError } from '~/model';
import {
  filesToImageTransports,
  urlsToImageTransports,
  useImagePaste,
  useIsMounted,
  normalizeToList,
} from '~/util';


/**
 * Preload, de-dupe and limit a list of images.
 * @param {ImageTransport[]} images - The list of images
 *   to de-dupe and preload.
 * @param {number} [max] - The maximum number of images allowed.
 * @param {ImageTransport[]} existing - A list of images
 *   that should be check for duplicates.
 * @return {Promise<ImageTransport[]>}
 */
export function preloadImages(
  images,
  max,
  // The existing list is used to check for duplicates
  // when dropping new images. Duplicates are determined
  // by file name.
  existing = [],
) {
  const deduped = images.filter(image =>
    !existing.find(other => image.equals(other))
  );

  if (!deduped.length) {
    // @ts-ignore
    return Promise.reject(
      new ImageTransportError(
        {
          failed: images,
          reason: ImageTransportError.EMPTY,
        },
        'No images or urls found in input.'
      )
    );
  } else {
    // @ts-ignore
    return Promise.allSettled(
      // Preload all of the images so they can be
      // rendered immediately. Force preloading
      // in order to verify that any urls being
      // dropped point to images and can be downloaded.
      deduped.map(image => image.preload(true))
    )
      .then(separateSettledPromises)
      .then(([loaded, failed]) => {
        // Handle the images that succeeded.
        if (loaded.length) {
          if (max && loaded.length > 1)
            loaded = loaded.slice(0, max);

          return loaded;
        } else {
          const first = failed[0];
          return Promise.reject(
            new ImageTransportError(
              {
                failed: failed,
                error: first,
                reason: ImageTransportError.EMPTY,
              },
              first.message
                ? first.message
                : 'The Images or URLs could not be downloaded.'
            )
          );
        }
      });
  }
}

/**
 * @typedef {object} AcceptImagesResult
 * @property {*} ref - A ref that must be attached to
 *   the drop area where users can drop images.
 * @property {boolean} isOver - Whether the drop area
 *   is currently hovered over.
 * @property {function} addImages - A function that
 *   can be called to add ImageTransport objects. This function
 *   can take any object supported by ImageTransport including
 *   other ImageTransports. This means it can be passed directly
 *   to a file browsing input element.
 */
/**
 * Handle image files dropped or pasted into the page.
 *
 * @param {object} [options]
 * @param {function} [options.onSuccess] - Called with
 *   an array of ImageTransport objects when images
 *   are dropped or pasted.
 * @param {function} [options.onError] - Called with
 *   the ImageTransportError if an error occurs.
 * @param {number} [options.max] - The maximum number of
 *   items that can be dropped or pasted.
 * @param {ImageTransport[]} [options.existing] - An
 *   existing list of ImageTransports that should be
 *   checked for duplicate images.
 *
 * @return {AcceptImagesResult}
 */
export function useAcceptImages({
  onSuccess,
  onError,
  max,
  existing = []
} = {}) {
  const isMounted = useIsMounted();

  const handleDrop = (dropped, type) => {
    if (dropped.length) {
      preloadImages(dropped, max, existing)
        .then(result => {
          if (onSuccess && isMounted()) onSuccess(result);
        })
        .catch(error => {
          if (onError && isMounted()) onError(error);
        });
    } else {
      if (onError && isMounted()) {
        onError(new ImageTransportError(
          {
            type,
            action: ImageTransportError.DROP,
            reason: ImageTransportError.EMPTY,
          },
          'No images or URLs were detected in the items you dropped.'
        ));
      }
    }
  };

  const [{isOver}, drop] = useDrop({
    accept: [NativeTypes.FILE, NativeTypes.URL, NativeTypes.TEXT],
    drop: (item, monitor) => {
      switch (monitor.getItemType()) {
        case NativeTypes.FILE:
          // @ts-ignore
          handleDrop(filesToImageTransports(item.files), ImageTransportError.FILE);
          break;
        case NativeTypes.URL:
          // @ts-ignore
          handleDrop(urlsToImageTransports(item.urls), ImageTransportError.URL);
          break;
        case NativeTypes.TEXT:
          // @ts-ignore
          handleDrop(urlsToImageTransports(item.text), ImageTransportError.URL);
          break;
        default:
          if (onError) {
            onError(new ImageTransportError(
              {
                failed: item,
                action: ImageTransportError.DROP,
                reason: ImageTransportError.EMPTY,
              },
              'No images or URLs were detected in the items you dropped.'
            ));
          }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  useImagePaste(pasted => handleDrop(pasted), onError);

  return {
    isOver,
    ref: drop,
    addImages: input => {
      input = normalizeToList(input);

      const images = input.flatMap(item => {
        if (item instanceof ImageTransport) return item;
        else return filesToImageTransports(item);
      });

      handleDrop(images);
    },
  };
}
