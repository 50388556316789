import React from 'react';
import PropTypes from 'prop-types';
import MasonryInfiniteScroller from 'react-masonry-infinite';
import debounce from 'lodash.debounce';

import {
  combineClasses,
} from '~/util';

import styles from './ScrollareaWithToolbar.module.scss';

const gutter = 22;
export function generateMasonryColumns(start = gutter, width = 325) {
  return [1,2,3,4,5,6,7,8,9].map(columns => {
    if (columns === 1) {
      return { columns, gutter };
    } else {
      return { columns, gutter, mq: `${(width * columns) + start}px`}
    }
  });
}

export const COLUMNS_FULL_PAGE = generateMasonryColumns();
export const COLUMNS_LEFT_PANEL_PAGE = generateMasonryColumns(400);

export const ScrollareaWithCards = React.forwardRef(({
  className,
  children,
  sizes,
  loadMore=() => {},
  hasMore=false,
  ...rest
}, ref) => {
  const columns = sizes || COLUMNS_FULL_PAGE;
  const masonryRef = React.useRef();
  const masonryInitializedRef = React.useRef(false);

  const onRepack = debounce(
    () => {
      // TODO If there is nothing to repack, remove
      // the absolute positioning.
      const component = masonryRef.current;
      // Only call the repack method if we believe that
      // the masonry library is initialized. This is important
      // because children of this component will get mounted
      // before this component itself and if children call
      // onRepack during mounting, a null pointer exception
      // can occur.
      if (component && masonryInitializedRef.current) {
        try {
          component.forcePack();
        } catch (e) {
          console.warn('Masonry repack failed:', e);
        }
      }
    },
    60,
    {leading: true}
  );

  const onReady = () => {
    if (!masonryInitializedRef.current) {
      masonryInitializedRef.current = true;
      onRepack();
    }
  };

  // Wait a tick to do the first repack so that MasonryInfiniteScroller
  // has time to initialize completely.
  React.useEffect(() => { setTimeout(onReady); }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderChildren = children => {
    const output = children(onRepack);
    if (output) {
      return Array.isArray(output)
        ? output
        : [React.cloneElement(output, {
            key: output.props.key || 0
          })];
    } else {
      return [];
    }
  };

  // On small windows, the infinite scroll component should track window
  // scroll to determine when to load new content. On wider windows, it
  // should track the parent element scroll events.
  const useWindowScroll = document.body.offsetWidth < styles.windowScrollBreakpoint;

  return (
    <div
      className={combineClasses(styles.scrollarea, className)}
      data-test="scrollarea"
      {...rest}
      ref={ref}
    >
      <MasonryInfiniteScroller
        hasMore={hasMore}
        loadMore={loadMore}
        ref={masonryRef}
        sizes={columns}
        useWindow={useWindowScroll}
      >
        { renderChildren(children) }
      </MasonryInfiniteScroller>
    </div>
  );
});

const SizePropType = PropTypes.shape({
  mq: PropTypes.string,
  columns: PropTypes.number.isRequired,
  gutter: PropTypes.number.isRequired,
});

ScrollareaWithCards.propTypes = {
  /**
   * A array of column definitions that define the width
   * of each card, the gutter size between cards and
   * the breakpoints that define the layout column count.
   * For more details, see the `size` prop described at
   * https://github.com/scarletsky/react-masonry-layout#api
   */
  sizes: PropTypes.arrayOf(SizePropType),
};
