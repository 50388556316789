// TODO Use gql from graphql.macro to inline all of these
import {gql} from 'apollo-boost';
import { mergeConcat } from '~/util';
import {
  prepareNestedRelationships,
} from './transform';
import { env } from '~/services';

const imageSummary = `
  id
  title
  description
  file_name
  url
  width
  height
`;

const image = {
  summary: imageSummary,
  detail: `
    ${imageSummary}
  `,
};

const uploadSummary = `
  id
`;

const upload = {
  summary: `
    ${uploadSummary}
    image { ${image.summary} }
  `,
  detail: `
    ${uploadSummary}
    image { ${image.detail} }
    upload_tmp
  `
};

const note = {
  summary: `
    id
    category
    description
    is_public
    artwork_id
    framing_id
    floor_plan_id
    collection_id
  `
};

const dimensions = {
  summary: `
    id
    width
    height
    depth
  `
};

const userSummary = `
  id
  first_name
  last_name
  email
`;

const user = {
  summary: userSummary,
  detail: `
    ${userSummary}
    # Limit uploads to the most recent.
    uploads(limit: 1, order_by: {created_at: desc_nulls_last}) { ${upload.detail} }
  `,
};

const artist = {
  summary: `
    id
    email
    first_name
    last_name
    phone_number
    url
  `
};

const cost = {
  summary: `
    id
    retail_price_cents
    retail_currency
    wholesale_price_cents
    wholesale_currency
    quantity
    note
    description
  `
};

const framing = {
  summary: `
    id
    costs { ${cost.summary} }
    notes { ${note.summary} }
  `
};

const floorplanSummary = `
  id
  title
  collection_id
`;

const floorplan = {
  summary: floorplanSummary,
  detail: `
    ${floorplanSummary}
    uploads { ${upload.detail} }
    notes { ${note.summary} }
  `
};

const artworkFloorplanSummary = `
  id
  artwork_id
  floor_plan_id
  x
  y
  hex_color
`;

const artworkFloorplan = {
  summary: artworkFloorplanSummary,
  detail: `
    ${artworkFloorplanSummary}
    notes { ${note.summary} }
  `,
};

const artworkSummary = `
  id
  medium
  title
  sold
  uploads { ${upload.summary} }
  artist { ${artist.summary} }
  dimensions { ${dimensions.summary} }
`;

const artwork = {
  summary: artworkSummary,
  detail: `
    ${artworkSummary}
    artwork_floor_plans { ${artworkFloorplan.detail} }
    notes { ${note.summary} }
    costs { ${cost.summary} }
    framings { ${framing.summary} }
  `
}

const artCollection = {
  summary: `
    collection_id
    artwork { ${artwork.summary} }
  `,
  detail: `
    collection_id
    artwork { ${artwork.detail} }
  `,
};

// TODO Add budget
// TODO Add gross
// TODO Add clients
const collectionSummary = `
  id
  title
  created_at
  budget
  gross
  user { ${user.detail} }
`;

const collection = {
  summary: collectionSummary,
  detail: `
    ${collectionSummary}
    floor_plans { ${floorplan.detail} }
    art_collections { ${artCollection.detail} }
    notes { ${note.summary} }
  `
};

export const GET_USER = gql`
  query getUser($email: String!) {
    users(where: {email: {_eq: $email}}) {
      ${user.detail}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
   $email: String!,
   $firstName: String!,
   $lastName: String!,
  ) {
    insert_users(
      objects: {
        email: $email,
        first_name: $firstName,
        last_name: $lastName,
      },
      on_conflict: {
        constraint: users_email_key,
        update_columns: [first_name, last_name]
      }
    ) {
      affected_rows
      returning {
        ${user.detail}
      }
    }
  }
`;

export const ADD_USER_IMAGE = gql`
  mutation addUserImage(
    $uploads: [uploads_insert_input!]!
  ) {
    insert_uploads(
      objects: $uploads
    ) {
      affected_rows
      returning {
        ${upload.detail}
      }
    }
  }
`;


export const GET_COLLECTIONS = gql`
  query getCollections {
    collections {
      ${collection.detail}
    }
  }
`;

export const GET_COLLECTION = gql`
  query getCollection($id: bigint!) {
    collections(where: {id: {_eq: $id}}) {
      ${collection.detail}
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation createCollection(
    $title: String!
    $budget: bigint!
    $gross: bigint!
    $userId: bigint!
    $description: String
    $notes: notes_arr_rel_insert_input
  ) {
    insert_collections(objects: {
      title: $title,
      user_id: $userId,
      budget: $budget,
      gross: $gross,
      notes: $notes,
    }) {
      affected_rows
      returning {
        ${collection.detail}
      }
    }
  }
`;

export const ADD_ARTWORK_TO_COLLECTION = gql`
  mutation addArtworkToCollection(
    $artworkId: bigint!
    $collectionId: bigint!
    $floorplanId: bigint!
    $x: bigint!
    $y: bigint!
    $color: String!
    # Allow passing the artwork through variables
    # so the mocking can use it.
    ${env.mock ? '$artwork: artworks_insert_input!' : ''}
  ) {
    insert_art_collections(objects: {
      artwork_id: $artworkId,
      collection_id: $collectionId,
    }) {
      affected_rows
      returning {
        # Return the full artwork details because
        # the UI may only have the artwork summary.
        ${artCollection.detail}
      }
    }

    insert_artwork_floor_plans(objects: {
      artwork_id: $artworkId,
      floor_plan_id: $floorplanId,
      x: $x,
      y: $y,
      hex_color: $color
    }) {
      affected_rows
      returning {
        ${artworkFloorplan.detail}
      }
    }
  }
`;

export const REMOVE_ARTWORK_FROM_COLLECTION = gql`
  mutation removeArtworkFromCollection(
    $artworkId: bigint!
    $collectionId: bigint!
    $floorplanId: bigint!
  ) {
    delete_art_collections(where: {
      collection_id: {_eq: $collectionId},
      _and: {
        artwork_id: {_eq: $artworkId}
      }
    }) {
      affected_rows
    }

    delete_artwork_floor_plans(where: {
      artwork_id: {_eq: $artworkId},
      _and: {
        floor_plan_id: {_eq: $floorplanId}
      }
    }) {
      affected_rows
    }
  }
`;

export const GET_ARTIST_FRAGMENT = gql`
  fragment artist on artists {
    ${artist.summary}
  }
`;

export const GET_ARTWORK_FRAGMENT = gql`
  fragment artwork on artworks {
    ${artwork.detail}
  }
`;

export const GET_ARTWORK = gql`
  query getArtwork($id: bigint!) {
    artworks(where: {id: {_eq: $id}}) {
      ${artwork.detail}
    }
  }
`;

export const GET_FLOORPLAN_FRAGMENT = gql`
  fragment floor_plan on floor_plans {
    ${floorplan.detail}
  }
`;

export const CREATE_ARTWORK = gql`
  mutation createArtwork(
    $collectionId: bigint!
    $artwork: artworks_insert_input!
  ) {
    insert_art_collections(
      objects: [{
        collection_id: $collectionId,
        artwork: {
          data: $artwork
        }
      }],
    ) {
      affected_rows
      returning {
        ${artCollection.detail}
      }
    }
  }
`;

// --- ARTWORK ---
export function makeArtworkUpdate(conflicts) {
  return `
  insert_artworks(
    objects: [$artwork],
    on_conflict: {
      constraint: artworks_pkey,
      update_columns: [${conflicts}]
    }
  ) {
    affected_rows
    returning {
      ${artwork.detail}
    }
  }
  `;
}

// --- DIMENSIONS ---
export const UPDATE_DIMENSIONS_DEF = `
  insert_dimensions(
    objects: $dimensions,
    on_conflict: {
      constraint: dimensions_pkey,
      update_columns: [width, height, depth]
    }
  ) {
    affected_rows
    returning {
      ${dimensions.summary}
    }
  }
`;

// --- UPLOADS ---
export const CREATE_UPLOADS_DEF = `
  insert_uploads(
    objects: $uploads,
  ) {
    affected_rows
    returning {
      ${upload.detail}
    }
  }
`;

export const DELETE_ARTWORK_IMAGES_DEF = `
  update_uploads(
    where: { id: {_in: $imageIdsToRemove} },
    _set: {artwork_id: null}
  ) {
    affected_rows
    returning {
      id
    }
  }
`;

// --- COSTS ---
// Use an insert with an on_conflict key for updates
// because that is the only way to bulk update in hasura.
export const CREATE_COSTS_DEF = `
  insert_costs(
    objects: $costs,
    on_conflict: {
      constraint: costs_pkey,
      update_columns: [
        retail_price_cents,
        retail_currency,
        wholesale_price_cents,
        wholesale_currency,
        quantity,
        note,
        description
      ]
    }
  ) {
    affected_rows
    returning {
      ${cost.summary}
    }
  }
`;

export const DELETE_COSTS_DEF = `
  delete_costs(
    where: { id: {_in: $costIdsToRemove} },
  ) {
    affected_rows
    returning {
      id
    }
  }
`;

// --- NOTES ---
export const UPDATE_NOTES_DEF = `
  insert_notes(
    objects: $notes,
    on_conflict: {
      constraint: notes_pkey,
      update_columns: [description, category]
    }
  ) {
    affected_rows
    returning {
      ${note.summary}
    }
  }
`;

export const DELETE_NOTES_DEF = `
  delete_notes(
    where: { id: {_in: $noteIdsToRemove}}
  ) {
    affected_rows
    returning {
      id
    }
  }
`;

// --- FRAMINGS ---
export const INSERT_FRAMINGS_DEF = `
  insert_framings(
    objects: $framings,
  ) {
    affected_rows
    returning {
      ${framing.summary}
    }
  }
`;

export const DELETE_FRAMINGS_DEF = `
  delete_framings(
    where: { id: {_in: $framingIdsToRemove} },
  ) {
    affected_rows
    returning {
      id
    }
  }
`;


export function makeUpdateArtworkQuery(collectionId, artwork, updated, added, removed) {
  const queries = [];
  const queryParams = [];
  let variables = {};

  // Manage all inserts and updates as upserts.
  const upsert = mergeConcat(updated, added);

  // --- UPSERT ---
  if (upsert) {
    // ARTWORK
    if (upsert.artworks) {
      const artworkConflictColumns = Object.keys(upsert.artworks)
        // Don't update the artwork id
        .filter(k => k !== 'id')
        // If there's a new artist to create, update the artist_id after creation
        .map(k => k === 'artist' ? 'artist_id': k)
        .join(', ');

      queries.push(makeArtworkUpdate(artworkConflictColumns));
      queryParams.push('$artwork: artworks_insert_input!');
      variables.artwork = prepareNestedRelationships(upsert.artworks);
    }

    // DIMENSIONS
    if (upsert.dimensions) {
      queries.push(UPDATE_DIMENSIONS_DEF);
      queryParams.push('$dimensions: [dimensions_insert_input!]!');
      variables.dimensions = upsert.dimensions;
    }

    // ARTWORK COST
    if (upsert.costs) {
      queries.push(CREATE_COSTS_DEF);
      queryParams.push('$costs: [costs_insert_input!]!');
      variables.costs = upsert.costs;
    }

    // NOTES
    if (upsert.notes) {
      queries.push(UPDATE_NOTES_DEF);
      queryParams.push('$notes: [notes_insert_input!]!');
      variables.notes = upsert.notes;
    }

    // FRAMINGS
    if (upsert.framings) {
      queries.push(INSERT_FRAMINGS_DEF);
      queryParams.push('$framings: [framings_insert_input!]!');
      variables.framings = upsert.framings.map(f => prepareNestedRelationships(f));
    }

    // UPLOADS
    if (upsert.uploads && upsert.uploads.length > 0) {
      queries.push(CREATE_UPLOADS_DEF);
      queryParams.push('$uploads: [uploads_insert_input!]!');
      variables.uploads = added.uploads.map(u => ({
        // TODO Do this in diff.js
        artwork_id: artwork.id,
        ...prepareNestedRelationships(u),
      }));
    }
  }

  // --- REMOVE ---
  if (removed) {
    // UPLOADS
    if (removed.uploads && removed.uploads.length) {
      queries.push(DELETE_ARTWORK_IMAGES_DEF);
      queryParams.push('$imageIdsToRemove: [bigint!]!');
      variables.imageIdsToRemove = removed.uploads.map(i => i.id);
    }

    // COSTS
    if (removed.costs && removed.costs.length) {
      queries.push(DELETE_COSTS_DEF);
      queryParams.push('$costIdsToRemove: [bigint!]!');
      variables.costIdsToRemove = removed.costs.map(c => c.id);
    }

    // NOTES
    if (removed.notes && removed.notes.length) {
      queries.push(DELETE_NOTES_DEF);
      queryParams.push('$noteIdsToRemove: [bigint!]!');
      variables.noteIdsToRemove = removed.notes.map(n => n.id);
    }

    // FRAMINGS
    if (removed.framings && removed.framings.length) {
      queries.push(DELETE_FRAMINGS_DEF);
      queryParams.push('$framingIdsToRemove: [bigint!]!');
      variables.framingIdsToRemove = removed.framings.map(f => f.id);
    }
  }

  return {
    queryCount: queries.length,
    variables,
    mutation: `
      mutation updateArtwork(
        ${queryParams.join('\n')}
      ) {
        ${queries.join('\n')}
      }
    `,
  }
}

export const MOVE_ARTWORK = gql`
  mutation moveArtwork(
    $artworkId: bigint!,
    $floorplanId: bigint!,
    $x: bigint!,
    $y: bigint!
  ) {
    update_artwork_floor_plans(
      where: {
        artwork_id: {_eq: $artworkId},
        _and: {
          floor_plan_id: {_eq: $floorplanId}
        }
      },
      _set: {x: $x, y: $y}
    ) {
      affected_rows
      returning {
        ${artworkFloorplan.detail}
      }
    }
  }
`;

export const CREATE_FLOORPLAN = gql`
  mutation createFloorplan(
    $floorplan: floor_plans_insert_input!,
  ) {
    insert_floor_plans(objects: [$floorplan]) {
      affected_rows
      returning {
        ${floorplan.detail}
      }
    }
  }
`;
