
export function artworkToPin(artwork) {
  return {
    ...artwork.floorplans[0],
    ...artwork,
    url: artwork.images && artwork.images.length > 0 ? artwork.images[0].url : '',
  };
}

export function getArtworksForFloorplan(artworks, floorplanId) {
  return artworks
    // Create a copy, removing any artwork_floor_plans
    // that don't relate to the current floorplan.
    .map(a => ({
      ...a,
      floorplans: a.floorplans.filter(af => af.floorplanId === floorplanId)
    }))
    // Remove any artworks that don't have floorplan references
    // because they haven't been placed on the current floorplan.
    .filter(a => a.floorplans.length > 0);
}
