import React from 'react';
import { Client } from 'elasticsearch-browser';

import { Service } from '../service';
import { env } from '../env';
import {
  fromSearch,
} from './fromSearch';

export const SEARCH_DEFINITIONS = {
  ARTIST: {
    fields: [
      {name: 'first_name', weight: 2},
      {name: 'last_name', weight: 2},
      {name: 'email', weight: 3},
      {name: 'url', weight: 1},
    ]
  },
  ARTWORK: {
    fields: [
      {name: 'title'},
      {name: 'artwork_type', weight: 2},
      {name: 'medium', weight: 2},
      {name: 'style'},
      {name: 'specific_media'},
      {name: 'artist.first_name', weight: 3},
      {name: 'artist.last_name', weight: 3},
      {name: 'images.title'},
      {name: 'images.description'},
    ],
  }
};

/**
 * Get a list of field definitions from the SEARCH_DEFINITIONS
 * in the syntax used by Elastic search.
 */
function getQueryFields(type) {
  return SEARCH_DEFINITIONS[type].fields
    // .map(f => `${f.name}.analyzed${f.weight ? '^' + f.weight : ''}`);
    .map(f => `${f.name}${f.weight ? '^' + f.weight : ''}`);
}

/**
 * Create a simple Lucen query string. This allows users
 * to specify advanced query rules in a simplified API.
 * https://www.elastic.co/guide/en/elasticsearch/reference/7.x/query-dsl-simple-query-string-query.html#simple-query-string-syntax
 */
function makeSimpleLuceneQuery(term, fields) {
  return {
    simple_query_string: {
      query: term,
      fields,
    }
  };
}

/**
 * Create a query that uses Lucene's query string syntax.
 * https://www.elastic.co/guide/en/elasticsearch/reference/7.x/query-dsl-query-string-query.html#query-string-syntax
 * This should be used when you want to programmatically
 * control query specifics. Use `makeSimpleLuceneQuery` if
 * you want to allow users to create the query string.
 */
function makeLuceneQuery(term, fields) {
  return {
    query_string: {
      query: term,
      fuzziness: 'AUTO',
    }
  }
}

function makePrefixQuery(term, fields) {
  const lastTerm = getLastWord(term);
  return {
    bool: {
      must: {
        // Search each of the words in `term` against
        // the provided fields but treat the last term
        // as a prefix search (ie. it will match if the
        // field contains a term that begins with `lastTerm`).
        // We do the prefix part of the query here because it
        // "must" match.
        multi_match: {
          query: term,
          fields,
          type: 'best_fields',
          fuzziness: 'AUTO',
        }
      },
      should: {
        // Also treat the last term as a full search term
        // because the prefix search above will discard
        // results that are an exact match.
        // We do this in a "should" because the prefix above
        // will definitely match, while this one may not.
        multi_match: {
          query: lastTerm,
          fields,
        }
      }
    }
  };
}

/**
 * Get the last word in a space separated string.
 */
function getLastWord(term) {
  const terms = term.trim().split(' ');
  return terms[terms.length - 1];
}

/**
 * Determine if a search term contains the special characters
 * that indicate it is a Lucene query.
 */
function termIsQuery(term) {
  return ':+-|()~"*'.split('').findIndex(char => term.includes(char)) > -1;
}

export class SearchAPI extends Service {
  constructor(
    client,
    {
      artistIndex = env.artistSearchIndex,
      artworkIndex = env.artworkSearchIndex,
    } = {},
    debug
  ) {
    client = client || new Client({
      host: env.searchAPI,
    });

    super(client, debug);

    this.config = {artistIndex, artworkIndex};
    this.log('created with API:', env.searchAPI);
  }

  handleError(error) {
    const {message, response} = error;
    this.error('request failed: ', message);
    this.error('response:', response);
    this.error(error);

    // Re-throw to continue the chain.
    throw error;
  }

  artists(term, limit = 10, nextOffset = 0) {
    this.debug('artists', ...arguments);

    // Bail early if the search term is empty.
    if (!term.trim()) {
      return Promise.resolve({
        results: [],
        limit: limit,
        offset: nextOffset,
        total: 0,
        searchTerm: term,
      });
    }

    const fields = getQueryFields('ARTIST');

    let query = termIsQuery(term)
      ? makeLuceneQuery(term, fields)
      : makePrefixQuery(term, fields);

    const body = { query };

    this.debug('Query:', body);

    return this.client.search({
        index: this.config.artistIndex,
        body,
        from: nextOffset,
        size: limit,
      })
      .then((response) => {
        this.debug('artists API RESPONSE', response);
        const out = {
          results: response.hits.hits.map((item) => fromSearch.artist(item._source, item._id, item._score)),
          limit: limit,
          offset: nextOffset,
          total: response.hits.total.value,
          searchTerm: term,
        }

        this.log('artworks SUCCESS', out);
        return out;
      })
      .catch(this.handleError.bind(this));
  }

  artworks(term, limit = 10, nextOffset = 0) {
    this.debug('artworks', ...arguments);

    // Bail early if the search term is empty.
    if (!term.trim()) {
      return Promise.resolve({
        results: [],
        limit: limit,
        offset: nextOffset,
        total: 0,
        searchTerm: term,
      });
    }

    const fields = getQueryFields('ARTWORK');
    let query = termIsQuery(term)
      ? makeSimpleLuceneQuery(term, fields)
      : makePrefixQuery(term, fields);

    const body = { query };

    this.debug('Query:', body);

    return this.client.search({
      index: this.config.artworkIndex,
      body,
      from: nextOffset,
      size: limit,
    })
    .then((response) => {
      this.debug('artworks API RESPONSE', response);
      const out = {
        results: response.hits.hits.map((item) => fromSearch.artwork(item._source, item._id, item._score)),
        limit: limit,
        offset: nextOffset,
        total: response.hits.total.value,
        searchTerm: term,
      }

      this.log('artworks SUCCESS', out);
      return out;
    })
    .catch(this.handleError.bind(this));
  }
}

export const SearchAPIContext = React.createContext();
export const SearchAPIProvider = SearchAPIContext.Provider;

export function useSearchAPI() {
  return React.useContext(SearchAPIContext);
}
