import React from 'react';
import PropTypes from 'prop-types';

import {
  CloseButton,
} from '../buttons';
import {
  combineClasses,
} from '~/util';

import styles from './ModalEsque.module.scss';

export default function ModalEsque({
  onExit,
  className,
  children,
  ...rest
}) {
  return (
    <div data-test="modalEsque"
      className={combineClasses(styles.ModalEsque, className)}
      {...rest}
    >
      <CloseButton
        className={styles.exit}
        data-test="exitButton"
        onClick={onExit}
      />
      { children }
    </div>
  );
}

ModalEsque.propTypes = {
  onExit: PropTypes.func.isRequired,
};

