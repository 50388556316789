import React from 'react';
import { Storage } from 'aws-amplify';
import { v1 as generateUUID } from 'uuid';

import { Service } from '../service';

// TODO Test these new functions.
export function pad(d) {
  return d < 10 ? '0' + d : d;
}

export function formatDate(d) {
  return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1)}-${pad(d.getUTCDate())}`;
}

export function makePath(...args) {
  return args.filter(item => !!item).join('/');
}

/**
 * Returns a file path like `type/yyyy-mm-dd/uuid`
 */
export function generateUniqueFileKey(type) {
  return makePath(
    type,
    formatDate(new Date()),
    generateUUID()
  );
}

export function truncateURL(url) {
  // Truncate the url used in logs if it is a dataURI to keep the logs lighter;
  return typeof(url) === 'string' && url.indexOf('data') > -1 ? url.substring(0, 100) : url;
}

/**
 * Remove the access tokens from a file location returned by the Amplify
 * client because 1) they are temporary and we don't want to save them as
 * part of the URL and 2) the file permissions are set on the AWS bucket.
 * Unfortunately, there's currently no way to tell the Amplify client not
 * to include an access token when requesting a file's location.
 * @param {string} url - The url to strip of tokens.
 */
export function removeAccessTokens(url) {
  return typeof(url) === 'string' && url.indexOf('?') > -1 ? url.split('?')[0] : url;
}

export class AWSStorageAPI extends Service {
  constructor(onAuthFailure, client = Storage, debug = undefined) {
    super(client, debug);

    this.onAuthFailure = onAuthFailure;

    this.info(`created with client <${client.constructor.name}>`);
  }

  /**
   * @param {File|Blob|TypedArray|Buffer|String|ReadableStream} file - The image data to upload.
   * @param {string} mimeType - Mime type of the image.
   * @param {string} category - The type of thing being upload (ie. 'artwork', 'floorplan', etc).
   */
  uploadImage(file, mimeType, category = 'artwork') {
    this.debug('uploadImage', file, mimeType, category);

    const location = generateUniqueFileKey(category);

    return this.client.put(location, file, {
      level: 'public',
      contentType: mimeType,
    }).then(upload => {
      this.debug('PUT SUCCESS', upload);

      // Get the data about the file we just uploaded.
      return this.client.get(upload.key, {level: 'public'}).then(url => {
        const publicURL = removeAccessTokens(url);
        this.info('UPLOAD SUCCESS', truncateURL(publicURL));
        return publicURL;
      });
    }).catch(error => {
      if (
        error && error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        this.onAuthFailure(error);
      }
      this.error('UPLOAD FAILED', error);

      // Rethrow the error so it can be handled elsewhere.
      throw error;
    });
  }
}

export const AWSStorageAPIContext = React.createContext();
export const AWSStorageAPIProvider = AWSStorageAPIContext.Provider;

export function useAWSStorageAPI() {
  return React.useContext(AWSStorageAPIContext);
}

