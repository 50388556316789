import { urlToBlob, blobToDataURI } from '@thesoulfresh/utils';

export const IMAGE_TRANSPORTS = {
  FILE: 'FILE',
  BLOB: 'BLOB',
  URL: 'URL',
  BASE64: 'BASE64',
  EMPTY: 'EMPTY',
}

export class ImageTransport {
  /**
   * A structure defining images that can be
   * either URLs, File objects or Blob objects.
   *
   * @property {File} file - The File that backs this image.
   * @property {Blob} blob - The Blob that backs this image.
   * @property {string} url - The URL that backs this image.
   * @property {string} base64 - The base64 encoded string backing this image.
   * @property {string} mimeType - The mime-type of the image.
   * @property {string} name - The display name/file name of the image.
   * @property {object} upload - The Upload object associated with this
   *   image if it has already been uploaded.
   * @property {string} cache - The base64 encoded image data if the
   *   image is preloaded.
   *
   * @param {string|Blob} data - The image data or the URL
   *   to the image.
   * @param {string} name - The file name or display name of the image.
   * @param {object} [upload] - The upload data associated with this
   *   image if it has previously been uploaded. This should be the
   *   `ImageUpload` returned from the API.
   * @param {string} [mimeType] - The mime type of the image data
   *   if it is know. If not passed and the transport holds a File
   *   or Blob, then it will be auto detected.
   */
  constructor(data, name, upload, mimeType) {
    if (data instanceof File) {
      this.file = data;
      this.size = data.size;
    }
    else if (data instanceof Blob) {
      this.blob = data;
      this.size = data.size;
    }
    else if (typeof data === 'string') {
      if (data.startsWith('data:')) {
        this.base64 = data;
        try {
          this.size = atob(
            data.split('base64,')[1]
          ).length;
        } catch (e) {
          console.error('Failed to get base64 image file size.', e);
        }
      } else {
        this.url = data;
      }
    }

    this.upload = upload;
    this._mimeType = mimeType;
    this.name = name;
    // TODO Remove this eventually
    this.file_name = name;
  }

  /**
   * We consider the images the same if:
   * - They both have the same file name
   * - Or they both have the same url
   * - Or they don't have names but they point to the same
   *   File/Blob/base64 data
   * @param {ImageTransport} file
   */
  equals(file) {
    // TODO If the file/blob/base64 is small enough,
    // check the data as well.
    return (file.name && this.name === file.name) ||
      (this.url && this.url === file.url) ||
      (!this.name && this.file && this.file === file.file) ||
      (!this.name && this.blob && this.blob === file.blob) ||
      (!this.name && this.base64 && this.base64 === file.base64);
  }

  /**
   * Determine the type of data contained in this
   * image transport.
   * @return {string}
   */
  get type() {
    if (this.file) return IMAGE_TRANSPORTS.FILE;
    else if (this.blob) return IMAGE_TRANSPORTS.BLOB;
    else if (this.base64) return IMAGE_TRANSPORTS.BASE64;
    else if (this.url) return IMAGE_TRANSPORTS.URL;
    else return IMAGE_TRANSPORTS.EMPTY;
  }

  /**
   * Determine the type of file contained in this image
   * transport.
   * @return {string}
   */
  get mimeType() {
    if (this._mimeType) return this._mimeType;
    else if (this.file || this.blob) return this.file.type;
    else if (this.base64 || this.cache) {
      const data = this.base64 || this.cache;
      const back = data.split('data:')[1];
      return back.split(';base64')[0];
    }
    else return undefined;
  }

  /**
   * Get a URL that can be used as the `src` property
   * of an `<img>` element.
   * @return {string}
   */
  get src() {
    switch (this.type) {
      case IMAGE_TRANSPORTS.FILE:
      case IMAGE_TRANSPORTS.BLOB:
        return this.cache;
      case IMAGE_TRANSPORTS.BASE64:
        return this.base64;
      case IMAGE_TRANSPORTS.URL:
        // Default to the base64 data in order to save
        // network requests.
        return this.cache || this.url;
      default:
        return '';
    }
  }

  /**
   * Determine if this image has been upload to file
   * storage and saved in the API.
   * @return {boolean}
   */
  get uploaded() {
    return !!this.upload;
  }

  /**
   * Determine if the image data has already been
   * preloaded as base64 encoded data. Passing a URL
   * as the data is not considered preloaded because
   * the base64 data still needs to be loaded before
   * upload to the storage service.
   * @return {boolean}
   */
  get preloaded() {
    return !!(this.cache || this.base64);
  }

  /**
   * Preload the image data as a base64 encoded
   * string that can be applied to `<img>` elements.
   * This will resolve to itself (ImageTransport) when it
   * is done. Use the `force` parameter to force preloading
   * of URL backed images before uploading them to long term
   * storage.
   *
   * @param {boolean} [force] - Force preloading of images
   *   that do not require it to display the image.
   *   For example, images backed by a URL do not require
   *   preloading in order to display the image.
   * @return {Promise<ImageTransport>}
   */
  preload(force = false) {
    return new Promise((resolve, reject) => {
      if (
        this.preloaded ||
        (!force && this.url)
      ) {
        resolve(this);
      }
      else {
        /** @param {Blob} blob */
        const blobToBase64 = blob => blobToDataURI(blob)
          .then(data => {
            this.cache = data;
            resolve(this);
          })
          .catch(error => {
            reject(new ImageTransportError(
              {
                error,
                failed : this,
                action : ImageTransportError.READ,
                type   : this.type === IMAGE_TRANSPORTS.URL
                  ? ImageTransportError.URL
                  : ImageTransportError.FILE,
                reason : ImageTransportError.INVALID,
              },
              'The image could not be read.'
            ))
          });

        switch (this.type) {
          case IMAGE_TRANSPORTS.FILE:
          case IMAGE_TRANSPORTS.BLOB:
            const blob = this.file || this.blob;
            blobToBase64(blob)
            break;
          case IMAGE_TRANSPORTS.BASE64:
            resolve(this);
            break;
          case IMAGE_TRANSPORTS.URL:
            urlToBlob(this.url)
              .catch(error => {
                const customError = new ImageTransportError(
                  {
                    error,
                    failed : this,
                    action : ImageTransportError.DOWNLOAD,
                    type   : this.type === IMAGE_TRANSPORTS.URL
                      ? ImageTransportError.URL
                      : ImageTransportError.FILE,
                    // This could be empty or invalid but it doesn't
                    // seem important to be that specific at the moment.
                    reason : ImageTransportError.EMPTY,
                  },
                  `Could not download the URL ${this.url.substring(0, 30)}`
                );
                reject(customError);
                // Break the chain.
                throw customError;
              })
              .then(blob => {
                if (blob && blob.type.startsWith('image/')) {
                  this._mimeType = blob.type;
                  this.size = blob.size;
                  return blobToBase64(blob);
                } else {
                  const customError = new ImageTransportError(
                    {
                      failed : this,
                      action : ImageTransportError.DOWNLOAD,
                      type   : this.type === IMAGE_TRANSPORTS.URL
                        ? ImageTransportError.URL
                        : ImageTransportError.FILE,
                      // This could be empty or invalid but it doesn't
                      // seem important to be that specific at the moment.
                      reason : ImageTransportError.EMPTY,
                    },
                    `The URL did not contain image data: ${this.url.substring(0, 30)}`
                  );
                  reject(customError);
                  // Break the chain.
                  throw customError;
                }
              })
              // Swallow rejections from the first catch.
              .catch(() => {});
            break;
          default:
            resolve(this);
        }
      }
    });
  }
}


export class ImageTransportError extends Error {
  /**
   * Custom error reporting for Image Transports.
   * @param {object} [metadata]
   * @param {*} [metadata.failed] - The list of item or items that cause the error.
   * @param {*} [metadata.error] - The original error if there was one.
   * @param {string} [metadata.action] - The action being performed (PASTE, DROP, )
   * @param {string} [metadata.type] - The type of image (URL, FILE)
   * @param {string} [metadata.reason] - A generic reason for the failure (EMPTY, INVALID)
   * @param {...*} [params] - The parameters (like message)
   *   that usually get passed to an Error object.
   */
  constructor(
    {
      failed, // any list of failed items like File, Blob, ImageTransport, etc
      // succeeded, // any list of the items that did work?
      error,
      action, // PASTE, DROP,
      type, // FILE or URL
      reason, // EMPTY, INAVLID
    } = {},
    ...params
  ) {
    super(...params);
    // if (Error.captureStackTrace) Error.captureStackTrace(this, ImageTransportError);
    this.name = 'ImageTransportError';
    this.failed = Array.isArray(failed) ? failed : [failed];
    // this.succeeded = Array.isArray(succeeded) ? succeeded : [succeeded];
    this.error = error;
    this.action = action;
    this.type = type;
  }
}

// Simplify imports by just setting these ENUMs on the class directly.
// --- ACTION ---
ImageTransportError.PASTE = 'PASTE'; // clipboard errors
ImageTransportError.DROP = 'DROP'; // drag and drop errors
ImageTransportError.DOWNLOAD = 'DOWNLOAD'; // errors retrieving remote data
ImageTransportError.READ = 'READ'; // errors reading image data
// --- TYPE ---
ImageTransportError.FILE = 'FILE'; // File, Blob, base64 images
ImageTransportError.URL = 'URL'; // URLs
// --- REASON ---
ImageTransportError.INVALID = 'INVALID'; // Corrupt or wrong type
ImageTransportError.EMPTY = 'EMPTY'; // Nothing found or data filtered out

