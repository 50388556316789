import React from 'react';
import PropTypes from 'prop-types';

import { env } from '~/services';
import LoaderService from './loader.service';

import './PageLoader.scss';

/*
 * Start/Stop the global page loader embedded in the HTML page.
 * This component expects a loader element to exist on the page
 * (should be embedded in the index.html template so it loads
 * before the app is bootstraped).
 *
 * If not in "immediate" mode (the default), then the PageLoader
 * will remain active for "delay" milliseconds in case another
 * component needs to start the page loader.
 *
 * @See `loader.service.jsx`
 */
export default function PageLoader({
  immediate = env.test,
  delay = 500,
}) {
  React.useEffect(() => {
    LoaderService.start();

    return () => LoaderService.stop(immediate, delay);
  });
  return null;
}

PageLoader.propTypes = {
  /**
   * True = immediately remove the PageLoader on destroy.
   * False = delay removal of the PageLoader in case
   * is quickly re-added by another component.
   *
   * False by default unless running in a test environment.
   */
  immediate: PropTypes.bool,
  /**
   * The number of milliseconds to delay stopping the PageLoader
   * when not in immediate mode.
   */
  delay: PropTypes.number,
}

