import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import classSet from 'react-classset';

import { SmallTitle } from '../titles';
import { Image } from '../images';
import { ART_PIN_TYPE } from './constants';
import {
  IdPropType,
  PinPropType,
  CSSValuePropType,
} from '~/model';
import {
  combineClasses,
} from '~/util';

import './MapPin.scss';

// TODO Use this in MapPin
export function ArtPin({
  id,
  color,
  title,
  url,
  rootRef,
  pinRef,
  detailRef,
  imageRef,
  className,
  ...rest
}) {
  // This is used so that the pinRef, detailRef, ect
  // can be optional.
  // TODO Is there a better solution here?
  const mockRef = React.useRef();

  const colorStyle = {
    backgroundColor: color || '#000000'
  };

  return (
    <div
      id={id}
      ref={rootRef || mockRef}
      data-test="artPin"
      className={combineClasses('art-pin', className)}
      {...rest}
    >
      <div data-test="details"
        className="pin-details"
        ref={detailRef || mockRef}
        style={colorStyle}
      >
        <SmallTitle className="title">{ title }</SmallTitle>
        <div className="artwork-preview">
          <Image data-test="image"
            className="preview-image"
            ref={imageRef || mockRef}
            src={url}
            alt={title}
          />
        </div>
      </div>
      <div data-test="pin"
        className="pin"
        ref={pinRef || mockRef}
        style={colorStyle}>
      </div>
    </div>
  );
}

ArtPin.propTypes = {
  id: IdPropType,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  rootRef: PropTypes.object,
  pinRef: PropTypes.object,
  detailRef: PropTypes.object,
  imageRef: PropTypes.object,
};

/*
 * This component represents an artwork on the FloorplanBuilder.
 *
 * Styling:
 * This component expects to be placed inside an absolute or relative
 * positioned element.
 *
 * @prop {object} artwork The artwork model defining the artwork data.
 * @prop {boolean} [open] Whether this pin should be in the open state.
 * @prop {number} x The x location of the pin in pixels.
 * @prop {number} y The y location of the pin in pixels.
 * @prop {number} [z] The z index of the pin.
 * @prop {number} [scale] The scale at which to draw the pin (so it can
 *   be scaled down as the map zooms in).
 * @prop {string} [color] A HEX color to use as the background color for the pin.
 * @prop {Function} [onReady] A callback when this pin is fully loaded.
 * @prop {Function} [onDragStart] A callback when this pin starts being dragged.
 * @prop {Function} [onDragEnd] A callback when this pin stops being dragged.
 * @prop {Function} [onDrop] A callback when this pin is dropped on an acceptable target.
 * @prop {Function} [onOpen] A callback when this pin should be opened (it is the
 *   responsibility of a parent to set the open property on this pin).
 * @prop {Function} [onClose] A callback when this pin should be closed (it is the
 *   responsibility of a parent to set the open property on this pin).
 * @prop {Function} [onHoverOver] A callback when this pin is hovered or touched.
 * @prop {Function} [onHoverOut] A callback when this pin is nolonger hovered or loses
 *   touch focus.
 *
 * @typedef {object} Props
 * @extends {React.Component<Props>}
 */
export class MapPin extends React.Component {
  constructor(props) {
    super(props);

    this.imageLoaded = false;
    this.onReadyCalled = false;

    this.rootRef = React.createRef();
    this.pinRef = React.createRef();
    this.detailRef = React.createRef();
    this.imageRef = React.createRef();

    this.state = {
      // We need to set this in state so that moving the
      // pin doesn't generate a new color in mock API mode.
      color: props.color,
      dimensions: {
        open: null,
        closed: null,
      },
    };
  }

  get artwork() {
    return this.props.artwork;
  }

  get id() {
    return this.artwork.id;
  }

  get title() {
    return this.artwork.title;
  }

  get imageUrl() {
    return this.artwork.url;
  }

  get x() {
    return this.props.x;
  }

  get y() {
    return this.props.y;
  }

  get z() {
    return !isNaN(this.props.z) ? this.props.z : 1;
  }

  get scale() {
    return this.props.scale == null ? 1 : this.props.scale;
  }

  get dimensions() {
    return this.state.dimensions;
  }

  get color() {
    return this.state.color;
  }

  get interactive() {
    return this.props.interactive;
  }

  get dragging() {
    return this.props.dragging;
  }

  get onReady() {
    return this.props.onReady;
  }

  get onDragStart() {
    return this.props.onDragStart;
  }

  get onDragEnd() {
    return this.props.onDragEnd;
  }

  get onDrop() {
    return this.props.onDrop;
  }

  get onHoverOver() {
    return this.props.onHoverOver;
  }

  get onHoverOut() {
    return this.props.onHoverOut;
  }

  get onOpen() {
    return this.props.onOpen;
  }

  get onClose() {
    return this.props.onClose;
  }

  get open() {
    return this.props.open;
  }

  get rootElement() {
    return this.rootRef.current;
  }

  get pinElement() {
    return this.pinRef.current;
  }

  get detailElement() {
    return this.detailRef.current;
  }

  get imageElement() {
    return this.imageRef.current;
  }

  measurePin() {
    const pinRect = this.pinElement.getBoundingClientRect();
    const detailsRect = this.detailElement.getBoundingClientRect();
    return {
      pin: {width: pinRect.width, height: pinRect.height},
      details: {width: detailsRect.width, height: detailsRect.height},
    };
  }

  getPinOffset(d) {
    return {
      offsetX: d.details.width / 2,
      offsetY: d.details.height + (d.pin.height / 2),
    };
  }

  toggleDetails() {
    if (this.open) {
      this.hideDetails();
    } else {
      this.showDetails();
    }
  }

  showDetails() {
    // If we don't have measurements for the pin
    // in it's open state, calculate those.
    if (this.dimensions.open == null) {
      this.detailElement.addEventListener('transitionend', this.onTransitionEnd);
    }

    // Emit event so the parent has control over what is open or closed.
    if (this.onOpen) {
      this.onOpen(this.artwork);
    }
  }

  hideDetails() {
    // Emit event so the parent has control over what is open or closed.
    if (this.onClose) {
      this.onClose(this.artwork);
    }
  }

  onTransitionEnd = (e) => {
    this.detailElement.removeEventListener('transitionend', this.onTransitionEnd);

    this.setState({
      dimensions: {
        open: this.measurePin(),
        closed: this.dimensions.closed,
      }
    });
  }

  onMouseEnter = () => {
    if (this.onHoverOver) {
      this.onHoverOver(this.artwork);
    }
  }

  onMouseLeave = () => {
    if (this.onHoverOut) {
      this.onHoverOut(this.artwork);
    }
  }

  onMouseDown = (e) => {
    this.lastMouseDownEvent = e
  }

  onMouseUp = (e) => {
    const diffX = this.lastMouseDownEven ? this.lastMouseDownEvent.clientX - e.clientX : 0;
    const diffY = this.lastMouseDownEven ? this.lastMouseDownEvent.clientY - e.clientY : 0;
    const isSmallMove = (diff) => Math.abs(diff) <= 4;

    if (isSmallMove(diffX) && isSmallMove(diffY)) {
      this.toggleDetails();
    }

    this.lastMouseDownEvent = null;
  }

  onImageLoad = () => {
    this.imageLoaded = true;
    this.checkReady();
  }

  checkReady() {
    if (this.imageLoaded && !this.onReadyCalled) {
      this.onReadyCalled = true;
      if (this.onReady) {
        this.onReady(this.artwork);
      }
    }
  }

  componentDidMount() {
    if (this.imageElement.complete) {
      this.onImageLoad();
    } else {
      this.imageElement.addEventListener('load', this.onImageLoad);
    }

    this.setState({
      dimensions: {
        open: this.dimensions.open,
        closed: this.measurePin(),
      }
    });
  }

  componentDidUpdate() {
    this.checkReady();
  }

  render() {
    const classes = classSet({
      'art-pin': true,
      'dragging': this.dragging,
      'droppable': this.canDrop,
      'interactive': this.interactive,
      'open': this.open
    });

    const styles = {
      top: this.y,
      left: this.x,
      zIndex: this.z,
      transform: `scale(${this.scale})`,
      transformOrigin: '0 0',
    };

    const color = this.color
      ? {
        backgroundColor: this.color
      }
      : {};

    // Determine the offset that the drag image should be placed
    // with respect to the cursor. We have to do this due to the
    // transforms applied to the drag element.
    let dragOptions = {offsetX: 0, offsetY: 0};
    if (this.open && this.dimensions.open) {
      dragOptions = this.getPinOffset(this.dimensions.open);
    } else if (!this.open && this.dimensions.closed) {
      dragOptions = this.getPinOffset(this.dimensions.closed);
    }

    // TODO Separate out the pin display from the interactive functionality.
    const map = (
      <div
        ref={this.rootRef}
        data-test="artPin"
        className={classes}
        style={styles}
        id={this.id}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
      >
        <div data-test="details"
          className="pin-details"
          ref={this.detailRef}
          style={color}>
          <SmallTitle className="title">{ this.title }</SmallTitle>
          <div className="artwork-preview">
            <Image data-test="image"
              className="preview-image"
              ref={this.imageRef}
              src={this.imageUrl}
              alt={this.title} />
          </div>
        </div>
        <div data-test="pin"
          className="pin"
          ref={this.pinRef}
          style={color}>
        </div>
      </div>
    );

    if (this.interactive) {
      return (
        this.props.connectDragSource(
          this.props.connectDragPreview(map , dragOptions)
        )
      );
    } else {
      return map;
    }
  }
}

MapPin.propTypes = {
  artwork: PinPropType.isRequired,
  open: PropTypes.bool,
  x: CSSValuePropType.isRequired,
  y: CSSValuePropType.isRequired,
  z: PropTypes.number,
  scale: PropTypes.number,
  color: PropTypes.string,
  // TODO Separate interactive and non-interactive
  // versions so we don't have to pass this prop.
  interactive: PropTypes.bool,
  onReady: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onHoverOver: PropTypes.func,
  onHoverOut: PropTypes.func,
};

MapPin.defaultProps = {
  interactive: true,
};

const dragSpec = {
  beginDrag: (props) => {
    if (props.onDragStart) {
      props.onDragStart(props.artwork);
    }

    return props.artwork;
  },
  endDrag: (props, monitor) => {
    if (props.onDragEnd) {
      props.onDragEnd(props.artwork);
    }

    if (props.onDrop) {
      const r = monitor.getDropResult();
      if (r) {
        props.onDrop(r);
      }
    }
  }
};

const mapDragToProps = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    dragging: monitor.isDragging()
  };
};

const dragOptions = {};

const DraggableArtPin = DragSource(
  ART_PIN_TYPE,
  dragSpec,
  mapDragToProps,
  dragOptions
)(MapPin);


export default DraggableArtPin;

