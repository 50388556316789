import React from 'react';
import PropTypes from 'prop-types';

import { PageTitle } from '../titles';
import './FormLayout.scss';

/*
 * This component represents the layout of a form with title
 * content and action buttons.
 *
 * @prop {Function | JSX} children The children will be rendered
 *   as the columns of the form. Each child should be a FormColumn
 *   component.
 * @prop {Function | JSX | JSX[]} [actions] The list of buttons that will
 *   perform form actions like submit and cancel. This can be
 *   either a render function, an array of JSX definitions or
 *   a single JSX definition.
 * @prop {String} [title] The title of this form.
 *
 * To use this component, pass the `children` prop as the content
 * for the form and the `actions` prop as the action buttons at
 * the bottom of the form. See the `FormColumn` component for
 * different ways you can pass the children.
 *
 * The actions are optional, allowing you to change the layout of
 * the actions area by simply rendering something else after the form.
 * See below for an example.
 *
 * Example:
 * In this example, the Details and Costs columns
 * of the form will be layed out horizontally on
 * large screens and vertically on mobile devices.
 * You'll also get a title element and actions buttons
 * at the bottom of the form.
 *
 * ```jsx
 * const submit = <Button type="submit">Submit</Button>
 * const cancel = <Button type="button">Cancel</Button>
 *
 * <FormLayout title="Add Collection" actions={[submit, cancel]}>
 *   <FormColumn>
 *     <h3>Details</h3>
 *     ...
 *   </FormColumn>
 *   <FormColumn>
 *     <h3>Costs</h3>
 *     ...
 *   </FormColumn>
 * </FormLayout>
 * ```
 *
 * Example:
 * This example shows you how to skip rendering of the title
 * and actions area. It also shows how you can render the
 * actions area of the form yourself.
 *
 * ```jsx
 * <FormLayout>
 *   <FormColumn>
 *     <h3>Details</h3>
 *     ...
 *   </FormColumn>
 *   <FormColumn>
 *     <h3>Costs</h3>
 *     ...
 *   </FormColumn>
 * </FormLayout>
 *
 * <!-- Actions area -->
 * <div className="align-left purple">
 *   <Button type="submit">Submit</Button>
 * </div>
 * ```
 *
 * Example:
 * This example shows how you can pass a render
 * function as the actions prop.
 *
 * ```jsx
 * <FormLayout
 *   title="Foo Form"
 *   actions={() => (
 *     <React.Fragment>
 *       <Button type="submit">Submit</Button>
 *       <Button type="button">Cancel</Button>
 *     </React.Fragment>
 *   )}
 * >
 * </FormLayout>
 * ```
 *
 * @typedef {object} Props
 */
export default function FormLayout({
  title,
  actions,
  children,
}) {
  const titleElement = title
    ? <PageTitle className="form-title" data-test="layoutTitle">{ title }</PageTitle>
    : null;

  let wrapActions = (a) => (
    <div className="form-actions" data-test="layoutActions">
      { a }
    </div>
  );

  let actionsElement = null;
  if (actions) {
    if (actions.length) {
      actionsElement = wrapActions(
        actions.map((action, i) => (
          <span key={i}>{ action }</span>
        ))
      );
    } else {
      actionsElement = wrapActions(actions);
    }
  }

  return (
    <div className="form-layout" data-test="formLayout">
      { titleElement }
      <div className="form-columns" data-test="columns">
        { children }
      </div>
      { actionsElement }
    </div>
  );
}

FormLayout.propTypes = {
  /**
   * An array of elements to render
   * underneith the layout.
   */
  actions: PropTypes.any,
  /**
   * The form name.
   */
  title: PropTypes.string,
};

