
/*
 * Animation settings for react-spring
 */
export const springs = {
  moderate: {
    mass: 1,
    tension: 400,
    friction: 36,
  },
  fast: {
    mass: 1,
    tension: 600,
    friction: 30,
  }
};
