import React from 'react';

import {
  combineClasses,
} from '~/util';
import {
  DimensionsPropType,
} from '~/model';

import styles from './DimensionsDisplay.module.scss';


export function DimensionsDisplay({
  dimensions,
  className,
  ...rest
}) {
  if (dimensions?.width || dimensions?.height || dimensions?.depth) {
    // This order is important:
    const elements = [dimensions.height, dimensions.width, dimensions.depth]
      .filter(i => i != null && i !== 0 && i !== '0');

    return (
      <div data-testid="DimensionsDisplay"
        className={combineClasses(styles.DimensionsDisplay, className)}
        {...rest}
      >
        { elements.map((e, i) =>
          <span data-testid="dimension" key={i} className={styles.item}>
            { e }
          </span>
        )}
      </div>
    );
  } else {
    return null;
  }
}

DimensionsDisplay.propTypes = {
  /**
   * The dimensions to display.
   */
  dimensions: DimensionsPropType,
};

