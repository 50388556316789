import {
  copy,
} from '~/util';

// precision = one hundred million
export const COORDINATE_PRECISION = 100000000;


/**
 * Create a new object from the original with any properties that are
 * objects converted into nested relationship objects (ie {data: value).
 *
 * Ex:
 * ```
 *   prepareNestedObjects({foo: {bar: 'baz'}}); // -> {foo: {data: {bar: 'baz'}}}
 * ```
 */
export function prepareNestedRelationships(original) {
  if (typeof(original) !== 'object') return original;

  const out = copy(original);
  for (let key in out) {
    const current = out[key];
    if (Array.isArray(current)) {
      out[key] = {
        data: current.map(i => prepareNestedRelationships(i))
      };
    } else if (current && typeof(current) === 'object') {
      out[key] = {
        data: prepareNestedRelationships(current)
      };
    }
  }
  return out;
};

/**
 * Perform the inverse of `prepareNestedRelationships`.
 */
export function collapseNestedRelationships(original) {
  if (typeof(original) !== 'object') return original;

  const out = copy(original);
  for (let key in out) {
    const current = out[key];
    if (current && typeof(current) === 'object') {
      if (Array.isArray(current)) {
        out[key] = current.map(o => collapseNestedRelationships(o));
      } else if (current.data) {
        out[key] = collapseNestedRelationships(current.data);
      } else {
        out[key] = collapseNestedRelationships(current);
      }
    }
  }
  return out;
}

