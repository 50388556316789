import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import {
  combineClasses,
} from '~/util';

import styles from './Select.module.scss';

// TODO Not default export
export default function Select({
  required,
  children,
  value,
  ...rest
}) {
  return (
    <div
      className={combineClasses(
        styles.Select,
        required ? styles.required : null,
        !!value ? styles.valid : null,
      )}
    >
      <select
        className={styles.nativeSelect}
        required={required}
        value={value}
        {...rest}
      >
        { children }
      </select>
    </div>
  );
}

Select.propTypes = {
  children: PropTypes.any.isRequired,
  required: PropTypes.bool,
};

export function FormikSelect({
  children,
  name,
  ...rest
}) {
  return (
    <Field name={name}>
      {({field}) =>
        <Select {...rest} {...field}>
          { children }
        </Select>
      }
    </Field>
  );
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  required: PropTypes.bool,
};
