import React from 'react';

import { combineClasses } from '~/util'

import { Action } from './Action.jsx';

import styles from './ReusableActions.module.scss';

import { ReactComponent as EditIcon } from '~/assets/icons/edit.svg';
import { ReactComponent as SendIcon } from '~/assets/icons/send.svg';
import { ReactComponent as BackIcon } from '~/assets/icons/back-arrow.svg';
import { ReactComponent as AddIcon } from '~/assets/icons/circle-plus.svg';
// import { ReactComponent as UploadIcon } from '~/assets/icons/upload.svg';
import { ReactComponent as TrashIcon } from '~/assets/icons/trash.svg';
import { ReactComponent as CloseIcon } from '~/assets/icons/close.svg';
import { ReactComponent as ImageIcon } from '~/assets/icons/image-placeholder.svg';
// import { ReactComponent as ChevronIcon } from '~/assets/icons/chevron.svg';
// import { ReactComponent as DoubleChevronIcon } from '~/assets/icons/double-chevron.svg';
import { ReactComponent as PageIcon } from '~/assets/icons/file-text.svg';
import { ReactComponent as PrintIcon } from '~/assets/icons/printer.svg';
import { ReactComponent as SearchIcon } from '~/assets/icons/search.svg';
// import { ReactComponent as CheckIcon } from '~/assets/icons/check.svg';
// import { ReactComponent as GoogleLogo } from '~/assets/icons/logo-google-simple.svg';

export const IconAction = React.forwardRef(({
  className,
  ...rest
}, ref) =>
  <Action
    feel="link"
    size="m"
    iconButton
    className={combineClasses(styles.iconButton, className)}
    {...rest}
    ref={ref}
  />
);

export const PrintAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<PrintIcon className={styles.print} />}
    {...rest}
    ref={ref}
  />
);

export const EditAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<EditIcon className={styles.edit} />}
    {...rest}
    ref={ref}
  />
);

export const SendAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<SendIcon className={styles.send} />}
    {...rest}
    ref={ref}
  />
);

export const FileAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<PageIcon className={styles.file} />}
    {...rest}
    ref={ref}
  />
);

export const DeleteAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<TrashIcon className={styles.trash} />}
    {...rest}
    ref={ref}
  />
);

export const AddAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<AddIcon className={styles.add} />}
    {...rest}
    ref={ref}
  />
);

export const ImageAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<ImageIcon className={styles.image} />}
    {...rest}
    ref={ref}
  />
);

export const BackAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<BackIcon className={styles.back} />}
    {...rest}
    ref={ref}
  />
);

export const CloseAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<CloseIcon className={styles.close} />}
    {...rest}
    ref={ref}
  />
);

export const SearchAction = React.forwardRef(({
  ...rest
}, ref) =>
  <IconAction
    icon={<SearchIcon className={styles.search} />}
    {...rest}
    ref={ref}
  />
);


