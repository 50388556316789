import React from 'react';

import {
  combineClasses,
} from '~/util';

import styles from './Titles.module.scss';

export function PageTitle({
  className,
  children,
  ...rest
}) {
  return (
    <h1 className={combineClasses(styles.PageTitle, className)} {...rest} >
      { children }
    </h1>
  );
}

export function SectionTitle({
  className,
  children,
  ...rest
}) {
  return (
    <h2 className={combineClasses(styles.SectionTitle, className)} {...rest} >
      { children }
    </h2>
  );
}

export function Title({
  className,
  children,
  ...rest
}) {
  return (
    <h3 className={combineClasses(styles.Title, className)} {...rest} >
      { children }
    </h3>
  );
}

export function SubTitle({
  className,
  children,
  ...rest
}) {
  return (
    <h4 className={combineClasses(styles.SubTitle, className)} {...rest} >
      { children }
    </h4>
  );
}

export function SmallTitle({
  className,
  children,
  ...rest
}) {
  return (
    <h5 className={combineClasses(styles.SmallTitle, className)} {...rest} >
      { children }
    </h5>
  );
}

export function TinyTitle({
  className,
  children,
  ...rest
}) {
  return (
    <h6 className={combineClasses(styles.TinyTitle, className)} {...rest} >
      { children }
    </h6>
  );
}

