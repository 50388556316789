import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  MultiStep,
} from '../multi-step';
import {
  CloseButton
} from '../buttons';

import {
  Upload,
  Details,
  Framing,
} from './steps';
import {
  SaveModal,
} from './save-modal';
import {
  combineClasses,
} from '~/util';
import {
  ImageTransport,
} from '~/model';

import styles from './CreateArtworkForm.module.scss';

export function CreateArtworkForm({
  url,
  type,
  history,
  location,
  values,
  images,
  onSubmit,
  onArtistSearch,
  onComplete,
  onRemoveArtwork,
  onExit,
  onReady,
  onDropError,
  animated,
  className,
  ...rest
}) {
  const [error, setError]               = useState();
  const [artworkSaved, setArtworkSaved] = useState(false);
  const [uploadTotal, setUploadTotal]   = useState();
  const [uploadCount, setUploadCount]   = useState(0);
  const [submitting,  setSubmitting ]   = useState(false);
  const formValuesRef = React.useRef(values ? {...values} : null);

  const imagesRef = React.useRef(images);
  const clearError = () => setError(null);

  const handleSubmit = (page3Values, onSubmitError) => {
    const artwork = formValuesRef.current;

    // Set the images for the artwork. This ensures the images
    // weren't mangled going through the Formik transformations.
    artwork.images = imagesRef.current;
    const newImageCount = imagesRef.current.filter(image => !image.uploaded).length;

    const done = () => {
      setArtworkSaved(true);
    };

    const handleError = (error) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSubmitting(false);
        setError(error);
        onSubmitError(error);
      });
    };

    ReactDOM.unstable_batchedUpdates(() => {
      setUploadTotal(newImageCount);
      setSubmitting(true);
      // Delay one tick so that the submitting state is set
      // before any errors or other updates can happen.
      setTimeout(() => {
        onSubmit(artwork, setUploadCount, done, handleError);
      });
    });
  };

  const upload = ({next}) => (
    <Upload
      images={imagesRef.current}
      values={formValuesRef.current}
      onSubmit={(values, images) => {
        imagesRef.current = images;
        formValuesRef.current = values;
        clearError();
        next();
      }}
      onRemoveArtwork={onRemoveArtwork}
      onDropError={onDropError}
      error={error}
      onReady={onReady}
      animated={animated}
    />
  );

  const details = ({next, previous}) => (
    <Details values={formValuesRef.current}
      onPrevious={values => {
        formValuesRef.current = values;
        clearError();
        previous();
      }}
      onArtistSearch={onArtistSearch}
      onSubmit={(values) => {
        formValuesRef.current = values;
        clearError();
        next();
      }}
      onReady={onReady}
      animated={animated}
    />
  );

  const framing = ({previous, reset}) => (
    <Framing values={formValuesRef.current}
      onPrevious={(values) => {
        formValuesRef.current = values;
        clearError();
        previous();
      }}
      onSubmit={(values) => {
        formValuesRef.current = values;
        clearError();
        handleSubmit(values, reset);
      }}
      onReady={onReady}
      animated={animated}
    />
  );

  const modal = submitting
    ? <SaveModal
        uploadCount={uploadCount}
        uploadTotal={uploadTotal}
        artworkSaved={artworkSaved}
        onComplete={onComplete}
      />
    : null;

  return (
    <div
      data-testid="CreateArtworkForm"
      className={combineClasses(styles.CreateArtworkForm, className)}
      {...rest}
    >
      <CloseButton className={styles.exit} data-testid="exit" onClick={onExit} />
      <MultiStep
        steps={['upload', 'details', 'framing']}
        url={url}
        renderStep={(props) => {
          switch(props.stepName) {
            case 'upload':
              return upload(props);
            case 'details':
              return details(props);
            case 'framing':
              return framing(props);
            // no default
          }
        }}
      />
      { modal }
    </div>
  );
}

CreateArtworkForm.propTypes = {
  /**
   * The base URL to which the current form step will be appended.
   */
  url: PropTypes.string.isRequired,
  /**
   * The type of artwork being created (original, commission, reproduction).
   */
  type: PropTypes.string.isRequired,
  /**
   * The initial values for the form.
   * This should be a value matching ArtworkPropType or a
   * partial implementation there of.
   */
  values: PropTypes.object,
  /**
   * The list of ImageTransport objects to display.
   */
  images: PropTypes.arrayOf(PropTypes.instanceOf(ImageTransport)),
  /**
   * @callback onSubmit - Used to handle submitting of the new artwork and its images.
   * TODO Document values structure
   * @param {Object} values - The artwork metadata being submitted.
   * @param {Function} setUploadCount - A callback to set the number of images
   *   that have been uploaded so far.
   * @param {Function} done - A callback to indicate that all images have been
   *   uploaded and the artwork metadata saved.
   * @param {Function} handleError - A callback to call if an error occurs during
   *   the artwork save or image upload processes.
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * @callback onArtistSearch - The callback used to handle artist search events.
   * @param {string} term - The search term to use.
   */
  onArtistSearch: PropTypes.func.isRequired,
  /**
   * @callback onComplete - Called once the saving animations have finished and
   *   we are ready to redirect the user.
   */
  onComplete: PropTypes.func.isRequired,
  /**
   * @callback onRemoveArtwork - Remove the artwork from the current collection.
   * If this is not passed, then the remove button is not shown.
   */
  onRemoveArtwork: PropTypes.func,
  /**
   * A callback used when the user wants to cancel artwork creation.
   */
  onExit: PropTypes.func.isRequired,
  /**
   * A callback used when an error occurs droping or pasting
   * images into the page.
   */
  onDropError: PropTypes.func,
  /**
   * Whether or not to animate transitions (useful for testing).
   */
  animated: PropTypes.bool,
};

CreateArtworkForm.defaultProps = {
  animated: true,
};

