import React from 'react';

import {
  ArtistPropType,
} from '~/model';
import {
  combineClasses,
} from '~/util';
import {
  TitleValuePair
} from '../../../title-value-pair';

import styles from './ArtistDetails.module.scss';

function format(v) {
  return v || 'N/A';
}

export function ArtistDetails({
  artist = {},
  className,
  ...rest
}) {
  return (
    <table data-testid="ArtistDetails"
      className={combineClasses(styles.ArtistDetails, className)}
      {...rest}
    >
      <tbody>
        <tr className={styles.row} >
          <td className={styles.cell}>
            <TitleValuePair
              data-testid="firstName"
              title="First Name"
              value={format(artist?.first_name)}
            />
          </td>
          <td className={styles.cell}>
            <TitleValuePair
              data-testid="lastName"
              title="Last Name"
              value={format(artist?.last_name)}
            />
          </td>
        </tr>
        <tr className={styles.row} >
          <td className={styles.cell}>
            <TitleValuePair
              data-testid="email"
              title="Email"
              value={format(artist?.email)}
            />
          </td>
          <td className={styles.cell}>
            <TitleValuePair
              data-testid="phone"
              title="Phone Number"
              value={format(artist?.phone_number)}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

ArtistDetails.propTypes = {
  artist: ArtistPropType,
};
