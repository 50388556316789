import React from 'react';
import PropTypes from 'prop-types';

import {
  ArtistPropType,
} from '~/model';
import {
  combineClasses,
} from '~/util';

import { Title } from '../../../titles';
import { ArtistSuggestion } from '../ArtistSuggestion.jsx';

import styles from './SimilarArtistsList.module.scss';

function NoSimilarArtists() {
  return (
    <div className={styles.NoSimilarArtists} data-testid="NoSimilarArtists">
      No Similar Artists
    </div>
  );
}

function SimilarArtist({
  artist: {similarity, artist},
  onSelect,
}) {
  const similarityClass = similarity >= 3
    ? 'identical'
    : similarity >= 2
    ? 'high'
    : similarity >= 1.5
    ? 'medium'
    // To be a low match, we need at least
    // a levenshtein distance > 1 which will
    // result in a similarity of 1.5
    : similarity >= 1
    ? 'low'
    : '';

  const tabIndex = similarity > 1 ? 0 : -1;

  return (
    <ArtistSuggestion
      data-testid="ArtistSuggestion"
      className={combineClasses(styles.SimilarArtist, similarityClass)}
      name={artist.fullName}
      email={artist.email}
      onClick={() => onSelect(artist)}
      tabIndex={tabIndex}
    />
  );
}

function ArtistList({
  artists,
  onSelectArtist,
}) {
  return (
    <ol className={styles.ArtistList} data-testid="ArtistList">
      { artists.map((artist, i) =>
        <li className={styles.similarArtistWrapper} key={i}>
          <SimilarArtist
            artist={artist}
            onSelect={onSelectArtist}
          />
        </li>
      )}
    </ol>
  );
}

export function SimilarArtistsList({
  artists = [],
  onSelectArtist,
  error,
  className,
  ...rest
}) {
  return (
    <div
      className={combineClasses(styles.SimilarArtistsList, className)}
      {...rest}
    >
      { !error
        ? <Title>Similar Artists</Title>
        : <Title className={styles.error} data-testid="error">Artist Already Exists</Title>
      }
      <div className={styles.similarArtistContent}>
        { !artists || artists.length === 0
          ? <NoSimilarArtists />
          : <ArtistList
              artists={artists}
              onSelectArtist={onSelectArtist}
            />
        }
      </div>
    </div>
  );
}

const SimilarArtistPropType = PropTypes.shape({
  artist: ArtistPropType.isRequired,
  similarity: PropTypes.number.isRequired,
});

SimilarArtistsList.propTypes = {
  artists: PropTypes.arrayOf(SimilarArtistPropType).isRequired,
  onSelectArtist: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

