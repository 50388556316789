import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';

import {
  getRoute,
} from '~/routes';

// Don't import the full components package!
import { PageLoader } from '~/components/loader';
import { NotFound } from '~/components/not-found';
import { toast } from '~/components/toasts';
import { useCollectionGraphAPI } from '../graph-api';

/**
 * Returns either a list of collections or a single
 * collection if an id is passed.
 */
export function useCollections(id) {
  const api = useCollectionGraphAPI();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [collections, setCollections] = React.useState();

  React.useEffect(() => {
    const hasId = !isNaN(id);
    const request = hasId
      ? () => api.watchCollection(id)
      : () => api.watchCollections();

    const observable = request().subscribe({
      next: result => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (result) {
            setCollections(result);
          } else {
            let message = hasId
              ? `We were unable to find collection ${id}. Check the URL you used and try again in a minute.`
              : "We were unable to retrieve collections. Please try again in a minute.";
            setError(new Error(message));
          }
          setLoading(false);
        });
      },
      error: err => {
        ReactDOM.unstable_batchedUpdates(() => {
          setError(err);
          setLoading(false);
        });
      }
    });

    return () => observable.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    error,
    collections,
  }
}

export function WithCollections({
  id,
  children,
  ...rest
}) {
  const { collections, loading, error } = useCollections(id);

  if (error) {
    console.error(error);

    const hasId = !isNaN(id);

    if (hasId) {
      // Wait till the current render cycle is finished so we don't get a
      // “Cannot update during an existing state transition” error.
      setTimeout(() => toast.error(error.message));

      return <Redirect to={getRoute('HOME')} />;
    } else {
      return (
        <NotFound
          title="Collections Not Found"
          subtitle={error.message}
        />
      );
    }
  }
  else if (loading) {
    return <PageLoader immediate />;
  }
  else {
    return children(collections)
  }
}
