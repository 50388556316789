

/**
 * Pick the first item from an array or return null
 * if the array is empty. Optionally, transform the
 * data returned if the list contains a value.
 *
 * @param {*[]} list - The list to pick from.
 * @param {function} [callback] - An optional callback
 *   to transform the value. Receives the first item
 *   from the list.
 */
export function pickFirst(list, cb = v => v) {
  return list && list.length > 0 ? cb(list[0]) : undefined;
}

/**
 * Clamp an number between min and max.
 * @param {number} value
 * @param {number} [min] - Defaults to 0
 * @param {number} [max] - Defaults to 1
 */
export function clamp(value, min = 0, max = 1) {
  return Math.max( Math.min(value, max), min );
}

/**
 * Convert all 'undefined' or 'null' properties on the input object
 * into empty strings without modifying the input object.
 *
 * @param {object} o
 * @return {object}
 */
export function normalizeFormDefaults(o) {
  if (o == null) return '';
  else if (typeof(o) !== 'object') return o;

  const out = {};

  for (let key in o) {
    if (Array.isArray(o[key])) {
      out[key] = o[key].map(normalizeFormDefaults);
    } else if (typeof(o[key]) === 'object') {
      out[key] = normalizeFormDefaults(o[key]);
    } else if (o[key] === undefined) {
      out[key] = '';
    } else {
      out[key] = o[key];
    }
  }

  return out;
}

/**
 * The inverse of normalizeFormDefaults
 */
export function normalizeFormValues(o) {
  if (o === '') return null;
  else if (typeof(o) !== 'object') return o;

  const out = {};

  for (let key in o) {
    if (Array.isArray(o[key])) {
      out[key] = o[key].map(normalizeFormValues);
    } else if (o[key] != null && typeof(o[key]) === 'object') {
      out[key] = normalizeFormValues(o[key]);
    } else if (o[key] === '') {
      out[key] = null;
    } else {
      out[key] = o[key];
    }
  }

  return out;
}

/**
 * Make the shape of object "A" match the shape of object "B".
 * In other words, strip any properties on object "A" that
 * don't exist in object "B".
 *
 * Shape matching is done recursively through properties that
 * are objects on both A and B.
 * If a property on "A" is an object but that property on "B"
 * is null, then "A" will be used as is.
 */
export function matchObjectShapes(a, b) {
  const out = {};

  for (let key in a) {
    // If B has this key, transfer it.
    if (b[key] !== undefined) {
      // Apply this key to the output.
      out[key] = a[key];

      // If this key is an object, recursively match
      // those object shapes.
      if (
        typeof(out[key]) === 'object' &&
        typeof(b[key]) === 'object' &&
        out[key] &&
        b[key]
      ) {
        out[key] = matchObjectShapes(a[key], b[key]);
      }
    }
  }

  return out;
}

/**
 * Find objects in list 2 that do not exist in list 1.
 * @param {*[]} list1 - The original list
 * @param {*[]} list2 - The new list to search for differences.
 * @param {function} compare - The function used to compare objects.
 */
export function findNewObjectsInList(
  list1,
  list2,
  compare = (a, b) => a === b
) {
  if (!list2) return [];
  if (!list1) return list2;
  return list2.filter(existing => !list1.find(item => compare(existing, item)));
}

/**
 * Find items in list 2 that were removed from list 1.
 * @param {*[]} list1 - The original list
 * @param {*[]} list2 - The new list to search for differences.
 * @param {function} compare - The function used to compare objects.
 */
export function findItemsRemovedFromList(
  list1,
  list2,
  compare = (a, b) => a === b
) {
  if (!list1) return [];
  if (!list2) return list1;
  return list1.filter(existing => !list2.find(item => compare(existing, item)));
}

/**
 * @param {object} original
 * @param {object} updated
 * @param {function} compare
 * @param {function} filter
 * @return {object} An object with the keys matching the differences
 *   between the two objects with the value from the updated version.
 */
export function diffObjects(
  old,
  updated,
  valuesAreTheSame = (old, updated, prop) => old && old[prop] === updated[prop],
  skipProp = null
) {
  let out = null;
  for (let prop in updated) {
    const checkThisProp = skipProp ? !skipProp(old, updated, prop) : true;
    if (checkThisProp && !valuesAreTheSame(old, updated, prop)) {
      if (!out) out = {};
      out[prop] = updated[prop];
    }
  }
  return out;
}

/**
 * Get a list of objects that have changed between list1 and list2.
 * This method returns the original object (as opposed to the changed properties)
 * so you can easily figure out which objects have changed. To get just
 * the updated properties of the objects that have changed, chain this
 * method with a `map` call to output the fields you want.
 *
 * @param {object[]} original
 * @param {object[]} updated
 * @param {function} find - Find an item from the original list in the updated list.
 *   You can also use this function to filter out any objects you don't want to diff
 *   by returning false.
 * @param {function} valuesAreTheSame - See `diffObjects`
 * @param {function} filter - See `diffObjects`
 */
export function diffObjectsInList(
  list1,
  list2,
  find = (a, b) => a === b,
  valuesAreTheSame = undefined,
  skipProp = undefined,
) {
  if (!list1 && !list2) return [];
  if (!list1) return list2;
  if (!list2) return list1;
  const out = [];
  list1.forEach(old => {
    const updated = list2.find(updated => find(old, updated));
    if (updated) {
      const diff = diffObjects(old, updated, valuesAreTheSame, skipProp);
      if (diff) out.push(updated);
    }
  });
  return out;
}

