import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
} from '../../buttons';
import {
  combineClasses,
} from '~/util';

import styles from './ArtistSuggestion.module.scss';

export function ArtistSuggestion({
  name,
  email,
  className,
  ...rest
}) {
  return (
    <Button data-testid="suggestion"
      className={combineClasses(className, styles.ArtistSuggestion)}
      transparent
      {...rest}
    >
      <span className={styles.artistName} >{ name }</span>
      <span className={styles.artistEmail}>{ email }</span>
    </Button>
  );
}

ArtistSuggestion.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
};
