import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEqual from 'lodash.isequal'
import debounce from 'awesome-debounce-promise';

import {
  merge,
  normalizeFormDefaults,
  normalizeFormValues,
  useIsMounted,
  matchObjectShapes,
} from '~/util';
import {
  YupSchemaPropType,
} from '~/model';


export function AutoSaveForm({
  name,
  initialValues,
  children,
  schema,
  strip,
  onChange,
}) {
  // Checking for mounted state is supposedly an "anit-pattern"
  // but because Formik validation is async and can't be cancelled
  // by us, we need to use it to ensure events are not emitted after
  // unmounting.
  const isMounted = useIsMounted();

  const dirty = React.useRef(false);

  const [v] = React.useState(() => {
    // Create defaults for all fields in the schema.
    const defaults = schema.default();
    // Ensure that we have any initial values passed in and
    // all of the default properties.
    const merged = merge(defaults, initialValues);
    // Ensure all properties have values.
    let normalized = normalizeFormDefaults(merged);

    // If strip is true, remove any properties not
    // defined in the schema.
    if (strip) {
      normalized = matchObjectShapes(normalized, normalizeFormDefaults(defaults));
    }
    return normalized;
  });

  const lastValidValue = React.useRef();
  const lastChangeValue = React.useRef({values: null});

  const emit = (values, valid, validating, errors) => {
    if (onChange && isMounted()) {
      onChange({
        name,
        values,
        // `valid` will be null if validity is unknow (ie. still validating)
        valid,
        validating,
        previousValidValues: lastValidValue.current,
        // You can use this to identify the initial state update
        // before the user made any changes to the form.
        dirty: dirty.current,
        errors,
      });
    }

    // The first time we complete validation, set dirty to true.
    if (!validating) dirty.current = true;
  };

  const [validate] = React.useState(() => {
    return debounce(
      values => {
        if (isMounted()) {
          values = normalizeFormValues(values);
          if (!isEqual(values, lastChangeValue.current.values)) {
            lastChangeValue.current = {values};

            // valid = null because we don't know if the values are valid yet.
            emit(values, null, true);

            return schema.validate(values, {abortEarly: false})
              .then(() => {
                emit(values, true, false);
                lastValidValue.current = values;
              })
              .catch(errors => {
                const e = errors.inner.reduce((acc, e) => (acc[e.path] = e.message, acc), {});
                lastChangeValue.current.errors = e;
                emit(values, false, false, e);
                return e;
              });
          } else {
            // On blur, Formik will call validate even if no values have changed
            // so we'll manually bypass validation if nothing has changed.
            return Promise.resolve(lastChangeValue.current.errors);
          }
        }

        // If we unmounted, then just pretend to be valid.
        return true;
      }
    );
  });

  return (
    <Formik
      initialValues={v}
      validateOnMount={true}
      validate={validate}
    >
      { children }
    </Formik>
  );
}

AutoSaveForm.propTypes = {
  /**
   * A name that you can use to disambiguate this form's change events
   * and those of other forms. It is simply passed through with the update
   * events and is not used by this component.
   */
  name: PropTypes.string,
  /**
   * The values with which to initialize this form.
   */
  initialValues: PropTypes.object,
  /**
   * The form contents.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * The Yup schema to use for validation.
   */
  schema: YupSchemaPropType.isRequired,
  /**
   * This callback gets called when changes to the form occur.
   * @param {object}  data
   * @param {string}  data.name - The name passed to this form
   * @param {*}       data.values - The form values
   * @param {boolean} data.valid - Whether the form is currently valid
   * @param {boolean} data.validating - Whether the form is currently performing validation
   * @param {*}       data.perviousValidValues - The last valid value from the form
   * @param {object}  data.errors - A has of form errors where each key is the errored field
   *   and the value is the error message.
   */
  onChange: PropTypes.func.isRequired,
};

