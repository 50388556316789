import React from 'react';
import {
  gql,
  Observable,
} from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { HttpLink } from 'apollo-link-http';

import {
  copy,
  merge,
} from '~/util';
import {
  toCollectionGraph,
  fromCollectionGraph,
  prepareNestedRelationships,
  diff,
} from './transform';
import {
  awsUploadToImage,
  awsURLUploadToImage,
} from '../file-storage';
import {
  COST_TYPES,
} from '~/model';
import { Service } from '../service';
import { env } from '../env';
import { LoggingLink } from './logging-link';
import {
  makeGraphQLErrorLink,
} from './errors';
import {
  makeGraphQLCacheClient,
} from './cache';

import * as graph from './collection-graph-api-definitions';

/*
 * Use this class to make GraphQL requests.
 *
 * Global error handling is taken care of
 * with the client configuration in `HomeWithServer.jsx`
 */
export class CollectionGraphAPI extends Service {
  constructor(onAuthFailure, authToken, client, debug) {
    if (!client) {
      const retry = new RetryLink();

      const http = new HttpLink({
        uri: env.graphAPI,
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      const errorLink = makeGraphQLErrorLink(onAuthFailure);

      const links = [errorLink, retry, http];

      if (env.verbose) links.unshift(new LoggingLink());

      client = new ApolloClient({
        link: ApolloLink.from(links),
        cache: makeGraphQLCacheClient(),
      });
    }

    super(client, debug);

    this.info('created with API', env.graphAPI);
  }

  /**
   * Clear the data cache (for example after logout).
   * Returns a promise that will resolve after the
   * cache has been cleared.
   *
   * For more info, see:
   * https://www.apollographql.com/docs/react/caching/cache-interaction/#resetting-the-store
   */
  clear() {
    return this.client.clearStore();
  }

  getCachedCollections() {
    const { collections } = this.client.readQuery({
      query: graph.GET_COLLECTIONS,
    });
    return collections;
  }

  updateCachedCollections(collections) {
    this.client.writeQuery({
      query: graph.GET_COLLECTIONS,
      data: {collections},
    });
  }

  getCachedCollection(collectionId) {
    const { collections } = this.client.readQuery({
      query: graph.GET_COLLECTION,
      variables: {id: collectionId}
    });

    // Add the new floorplan to the collection.
    if (collections && collections.length > 0) {
      return collections[0];
    } else {
      return null;
    }
  }

  updateCachedCollection(collectionId, collection) {
    this.client.writeQuery({
      query: graph.GET_COLLECTION,
      variables: {id: collectionId},
      data: {collections: [ collection ]},
    });
  }

  getCachedArtist(id) {
    return this.client.readFragment({
      id: `artists:${id}`,
      fragment: graph.GET_ARTIST_FRAGMENT,
    });
  }

  getCachedArtwork(id) {
    return this.client.readFragment({
      id: `artworks:${id}`,
      fragment: graph.GET_ARTWORK_FRAGMENT,
    });
  }

  updateCachedArtwork(artwork) {
    this.client.writeFragment({
      id: `artworks:${artwork.id}`,
      fragment: graph.GET_ARTWORK_FRAGMENT,
      data: artwork
    });
  }

  getCachedFloorplan(id) {
    return this.client.readFragment({
      id: `floor_plans:${id}`,
      fragment: graph.GET_FLOORPLAN_FRAGMENT,
    });
  }

  updateCachedFoorplan(floorplan) {
    this.client.writeFragment({
      id: `floor_plans:${floorplan.id}`,
      fragment: graph.GET_FLOORPLAN_FRAGMENT,
      data: floorplan,
    });
  }

  /**
   * Get an observable that retrieves the list of
   * collections and emits whenever the list changes.
   */
  watchCollections() {
    return new Observable(o => this.client.watchQuery({
      query: graph.GET_COLLECTIONS,
      fetchResults: true,
      fetchPolicy: 'cache-first',
    }).subscribe({
      next: results => {
        this.log('GET_COLLECTIONS NEXT >', results);

        const out = results.data.collections.map(fromCollectionGraph.collection);
        this.info('watchCollections NEXT >', out);
        o.next(out);
      },
      // NOTE: This error must be bound to `o` correctly.
      error: err => o.error(err),
    }));
  }

  /**
   * Get an observable that retrieves the data for a
   * collection and emits if the data for that collection
   * changes.
   */
  watchCollection(id) {
    return new Observable(o => {
      return this.client.watchQuery({
        query: graph.GET_COLLECTION,
        variables: {id},
        fetchResults: true,
        fetchPolicy: 'cache-first',
      }).subscribe({
        next: results => {
          this.log('GET_COLLECTION NEXT >', results);

          const collections = results.data.collections;
          const collection = collections.length > 0
            ? fromCollectionGraph.collection( results.data.collections[0] )
            : null;

          this.info('watchCollection', id, 'NEXT >', collection);
          o.next(collection);
        },
        error: o.error,
      });
    });
  }

  /**
   * Get data about a user by email.
   */
  getUser(email) {
    this.debug('getUser', email);

    const variables = {email};

    return this.client.query({
      query: graph.GET_USER,
      variables,
    }).then(results => {
      const user = results.data.users[0];
      const out = user ? fromCollectionGraph.user(user) : null;
      this.info('getUser SUCCESS', out);
      return out;
    });
  }

  /**
   * Update the data for an authenticated user.
   * @param {AuthResponsePropType} authResponse - The object returned from
   * the OAuth response.
   */
  updateAuthenticatedUser(email, firstName, lastName) {
    this.debug('updateAuthenticatedUser', arguments);

    const variables = {
      email,
      firstName,
      lastName,
    }

    return this.client.mutate({
      mutation: graph.UPDATE_USER,
      variables,
    }).then(results => {
      const out = fromCollectionGraph.user(results.data.insert_users.returning[0]);
      this.info('updateUser SUCCESS', out);
      return out;
    });
  }

  /**
   * Add a profile picture to the user's uploads.
   * @param {number} id - The id of the user.
   * @param {AWSUploadPropType} awsUpload - The upload object with image data
   * and S3 file url.
   */
  addUserImage(id, awsUpload) {
    this.debug('addUserImage', id, awsUpload);
    // Hardcode the image title so we have a good display title
    // when looking at the data.
    const upload = awsURLUploadToImage(awsUpload, 'Profile Picture');

    const variables = {
      uploads: [toCollectionGraph.insert.userImage(id, upload)],
    };

    return this.client.mutate({
      mutation: graph.ADD_USER_IMAGE,
      variables,
    }).then(results => {
      const out = fromCollectionGraph.upload(results.data.insert_uploads.returning[0]);
      this.info('addUserImage SUCCESS', out);
      return out;
    });
  }

  /**
   * Get all collection summaries. This method returns
   * the collection data (not a subscription).
   */
  getCollections() {
    this.debug('getCollections');

    return this.client.query({
      query: graph.GET_COLLECTIONS,
    }).then(results => {
      // Flatten the result a little to make it easier to use.
      const out = results.data.collections.map(fromCollectionGraph.collection)
      this.info('getCollections SUCCESS', out);
      return out;
    });
  }

  /**
   * Get the details for a single collection. This method
   * returns the collection data (not a subscription).
   */
  getCollection(id) {
    return this.client.query({
      query: graph.GET_COLLECTION,
      variables: {id}
    }).then(results => {
      const out = fromCollectionGraph.collection( results.data.collections[0] )
      this.info('getCollection SUCCESS', out);
      return out;
    });
  }

  /**
   * Create a collection.
   * @param {Job} job - The job object returned from the GraphQL job search.
   *   See `job.summary` in the definitions file.
   * @param {string} title - The name of this collection.
   * @param {number} budget - The budget for this collection in pennies.
   * @param {number} userId - The id of the user creating the collection.
   * @param {NotePropType} [notes] - An optional notes object with public
   *   and private notes.
   */
  createCollection(collection, userId) {
    this.debug('createCollection', ...arguments);

    const notes = toCollectionGraph.notes(collection.notes);
    const variables = {
      title: collection.title,
      userId,
      budget: collection.budget,
      gross: collection.budget,
      // Optional fields...
      notes: !notes ? null : { data: notes },
    };

    return this.client.mutate({
      mutation: graph.CREATE_COLLECTION,
      variables,
      update: (cache, {data}) => {
        const returned = data.insert_collections.returning[0];

        const collections = this.getCachedCollections();
        if (collections) {
          this.updateCachedCollections([
            ...collections,
            returned
          ]);
        }
      }
    }).then(result => {
      const out = fromCollectionGraph.collection(result.data.insert_collections.returning[0])
      this.info('createCollection SUCCESS', out);
      return out;
    });
  }

  /**
   * Create a floorplan with its associated image and file upload data.
   *
   * @param {int|string} collectionId - The id of the collection this floorplan belongs to.
   * @param {FloorplanPropType} floorplan - The floorplan definition.
   * @param {AWSUploadPropType} upload - The image and its associated url.
   */
  createFloorplan(collectionId, floorplan, upload) {
    this.debug('createFloorplan', ...arguments);

    const variables = {
      floorplan: toCollectionGraph.insert.floorplan(
        floorplan,
        collectionId,
        awsUploadToImage(upload, floorplan.title),
      ),
    };

    return this.client.mutate({
      mutation: graph.CREATE_FLOORPLAN,
      variables,
      update: (cache, {data}) => {
        const result = data.insert_floor_plans.returning[0];

        const collection = this.getCachedCollection(collectionId);
        if (collection) {
          const newFloorplan = copy(result);

          this.updateCachedCollection(collectionId, {
            ...collection,
            floor_plans: collection.floor_plans.concat([newFloorplan])
          });
        }
      }
    }).then(result => {
      const out = fromCollectionGraph.floorplan(result.data.insert_floor_plans.returning[0])
      this.info('createFloorplan SUCCESS', out);
      return out;
    });
  }

  /**
   * Create an artwork with its associated image and file upload data.
   *
   * @param {int|string} collectionId - The id of the collection this floorplan belongs to.
   * @param {ArtworkCreationSchema} artwork - The floorplan definition.
   * @param {AWSUploadPropType[]} uploadData - Data describing each of the uploaded files and its cache location.
   *   Structure of this object is an UploadPropType wit an additional location property
   * @param {ArtworkFloorplanPropType} [artworkFloorplan] - The optional placement of this artwork on a floorplan and its color.
   */
  createArtwork(collectionId, artwork, awsUploads, artworkFloorplan) {
    this.debug('createArtwork', ...arguments);

    if (artworkFloorplan) {
      artwork.floorplans = [artworkFloorplan];
    }

    artwork.images = awsUploads.map(u => awsUploadToImage(u, artwork.title));
    const graphArt = toCollectionGraph.insert.artwork(artwork);

    // TODO Where do we want to keep this?
    if (graphArt.artist) {
      graphArt.artist.on_conflict = {
        constraint: 'artists_pkey',
        update_columns: ['id'],
      };
    }

    const variables = {
      collectionId,
      artwork: graphArt,
    };

    return this.client.mutate({
      mutation: graph.CREATE_ARTWORK,
      variables,
      update: (cache, {data}) => {
        const result = data.insert_art_collections.returning[0];

        const collection = this.getCachedCollection(collectionId);
        if (collection) {
          const newArtCollection = copy(result);
          // Ensure the images all have a URL. If the server did not
          // return one, then use the base64 encoded image data as the URL.
          newArtCollection.artwork.uploads = newArtCollection.artwork.uploads
            .map((u, i) => ({
              ...u,
              image: {
                ...u.image,
                // If the upload from AWS has a location, then use that.
                // Otherwise, use the raw image data (this should only happen
                // when running in the mock environment).
                url: u.image.url ||
                  awsUploads.find(aws => aws.image.name === u.image.file_name).image.url
              }
            }));

          const updated = {
            ...collection,
            art_collections: collection.art_collections.concat([newArtCollection]),
          };

          this.updateCachedCollection(collectionId, updated);
        }
      }
    }).then(result => {
      const artCollection = result.data.insert_art_collections.returning[0];
      const out = fromCollectionGraph.artwork(artCollection.artwork)
      this.info('createArtwork SUCCESS', out);
      return out;
    });
  }

  // TODO Instead of saving updates to the artwork in one save event,
  // we should update the UI to save individual models as the user edits them.
  // This would greatly reduce the complexity of updating artworks.
  updateArtwork(collectionId, originalArtwork, modifiedArtwork, newImages) {
    this.debug('updateArtwork:', ...arguments);

    // Convert from the UI API into the Graph API format.
    const oldArtworkForGraph = toCollectionGraph.artwork(originalArtwork);
    const newArtworkForGraph = toCollectionGraph.artwork(modifiedArtwork);

    let {updated, added, removed} = diff.artworks(oldArtworkForGraph, newArtworkForGraph);

    // Merge in new image uploads.
    if (newImages && newImages.length > 0) {
      if (!added) added = {};
      added.uploads = newImages.map(u =>
        toCollectionGraph.upload(awsUploadToImage(u, modifiedArtwork.title))
      );
    }

    this.debug('updateArtwork: updated', updated);
    this.debug('updateArtwork: added', added);
    this.debug('updateArtwork: removed', removed);

    // If there were no changes, return the existing artwork.
    if (!updated && !added && !removed) {
      this.info('updateArtwork detected no changes');
      return Promise.resolve(modifiedArtwork);
    }

    const {queryCount, mutation, variables} = graph.makeUpdateArtworkQuery(
      collectionId,
      newArtworkForGraph,
      updated,
      added,
      removed
    );

    // Bail early if we didn't generate any queries to run.
    if (queryCount < 1) {
      this.log('updateArtwork produced no effects');
      return Promise.resolve(modifiedArtwork);
    }

    this.debug('FULL MUTATION:');
    this.debug(variables);
    this.debug(mutation);

    if (env.mock && variables.artwork) {
      // This sucks but it's not possible to use a GraphQL insert
      // with an on_conflict resolution to handle artwork updates
      // when running in a mock environment. This is because doing
      // so runs an `insert_*` mock resolver and the mock resolver
      // only receives the values we want to change. As a result,
      // we don't have enough data to generate the GraphQL
      // response so Apollo fills in the gaps with fake data which
      // ends up corrupting the artwork in cache. I've tried a bunch
      // of ways to get around this but I always end in a place where
      // I might not know the id or __typename of a cached relationship.
      //
      // For that reason, we set the artwork variable to the full artwork
      // object when running with mocks so the mock resolver can return
      // the expected artwork object.
      //
      // TODO The best fix I can think of for this is to refactor
      // the edit artwork flow so that we present separate forms
      // for saving each relationship individually. This way we
      // can call GraphQL `update_*` methods and provide mock
      // resolves for those methods.
      variables.artwork = {
        ...variables.artwork,
        ...prepareNestedRelationships(newArtworkForGraph),
      }
    }

    return this.client.mutate({
      mutation: gql`${mutation}`,
      variables,
      update: (cache, {data}) => {
        // UPLOADS
        const createdUploads = data.insert_uploads ? data.insert_uploads.returning : [];
        const removedUploads = data.update_uploads ? data.update_uploads.returning : [];
        // DIMENSIONS
        const updatedDimensions = data.update_dimensions && data.update_dimensions.returning.length
          ? data.update_dimensions.returning[0] : null;
        // COSTS
        const updatedCosts = data.insert_costs && data.insert_costs.returning.length
          ? data.insert_costs.returning : null;
        // FRAMING
        const removedFramings = data.delete_framings && data.delete_framings.returning.length
          ? data.delete_framings.returning : null;
        const updatedFramings = data.insert_framings && data.insert_framings.returning.length
          ? data.insert_framings.returning : null;
        // NOTES
        const addedNotes = data.insert_notes && data.insert_notes.returning.length
          ? data.insert_notes.returning : null;
        const removedNotes = data.delete_notes && data.delete_notes.returning.length
          ? data.delete_notes.returning : null;
        // ARTWORK
        const updatedArtworks = data.insert_artworks && data.insert_artworks.returning.length
          ? data.insert_artworks.returning : null;

        const oldArtwork = this.getCachedArtwork(modifiedArtwork.id);
        if (oldArtwork) {
          const newArtwork = copy(oldArtwork);

          // Update artwork properties.
          if (updated && updated.artworks) {
            Object.keys(updated.artworks).forEach(key => {
              if (key === 'artist_id' || key === 'artist') {
                newArtwork.artist = updatedArtworks[0].artist;
              }
              else if (key === 'notes') {
                // TODO Is there a better way to do this?
                newArtwork.notes = updated.artworks.notes.data.map(n => ({
                  ...n,
                  __typename: 'notes',
                }));
              }
              // For all other keys, set the value from the updated object.
              else if (key !== 'id')
                newArtwork[key] = updated.artworks[key];
            });
          }

          // Update dimensions.
          if (updatedDimensions)
            newArtwork.dimensions[0] = merge(newArtwork.dimensions[0], updatedDimensions);

          // Update framing.
          if (updatedFramings) {
            newArtwork.framings = updatedFramings;
          }

          // Update costs.
          if (updatedCosts) {
            // ARTWORK COST
            const ac = updatedCosts.find(c => c.description === COST_TYPES.artwork);
            if (ac) {
              newArtwork.costs[0] = ac;
            } else {
              // FRAMING COST
              const fc = updatedCosts.find(c => c.description === COST_TYPES.framing);
              if (fc) {
                newArtwork.framings[0].costs[0] = fc;
              }
            }
          }

          // Remove framings.
          if (removedFramings) newArtwork.framings = [];

          const addNoteIds = (addedNotes, existingNotes, type, id) => {
            addedNotes.forEach(note => {
              if (note[type] && note[type] === id) {
                const index = existingNotes.findIndex(existing =>
                  existing.category === note.category &&
                  existing.is_public === note.is_public
                );
                if (index > -1) {
                  existingNotes[index] = note;
                } else {
                  existingNotes.push(note);
                }
              }
            });
          };

          const removeNotes = (removedNotes, existingNotes) => {
            if (removedNotes && existingNotes) {
              removedNotes.forEach(note => {
                const index = existingNotes.findIndex(existing => existing.id === note.id);
                if (index > -1) {
                  existingNotes.splice(index, 1);
                }
              });
            }
          };

          // Update notes.
          if (addedNotes) {
            // ARTWORK NOTES
            if (newArtwork.notes) {
              addNoteIds(addedNotes, newArtwork.notes, 'artwork_id', newArtwork.id);
            }

            // FRAMING NOTES
            if (newArtwork.framings && newArtwork.framings.length > 0 && newArtwork.framings[0].notes) {
              addNoteIds(addedNotes, newArtwork.framings[0].notes, 'framing_id', newArtwork.framings[0].id);
            }
          }

          // Removed notes.
          if (removedNotes) {
            if (newArtwork.notes) {
              removeNotes(removedNotes, newArtwork.notes);
            }

            if (newArtwork.framings && newArtwork.framings.length > 0 && newArtwork.framings[0].notes) {
              removeNotes(removedNotes, newArtwork.framings[0].notes);
            }
          }

          // Remove images from the artwork.
          removedUploads.forEach(({id}) => {
            newArtwork.uploads.splice(
              newArtwork.uploads.findIndex(u => u.id === id),
              1
            );
          });

          // Add images to the artwork.
          createdUploads.forEach(upload => {
            newArtwork.uploads.push(upload)
          });

          this.updateCachedArtwork(newArtwork);
        }
      }
    }).then(result => {
      const a = fromCollectionGraph.artwork(this.getCachedArtwork(modifiedArtwork.id));
      this.info('updateArtwork SUCCESS', a);
      return a;
    });
  }

  updateArtworkCoordinates(artworkId, floorplanId, collectionId, x, y) {
    this.debug('updateArtworkCoordinates', ...arguments);

    const variables = {
      artworkId,
      floorplanId,
      x: toCollectionGraph.coordinate(x),
      y: toCollectionGraph.coordinate(y),
    };

    return this.client.mutate({
      mutation: graph.MOVE_ARTWORK,
      variables,
      update: (cache, {data}) => {
        const result = data.update_artwork_floor_plans.returning[0];

        const collection = this.getCachedCollection(collectionId);
        if (collection) {
          // TODO Is there a more succinct way to do this?
          const artCollections = collection.art_collections.map(ac => {
            const artwork = ac.artwork;

            if (artwork.id === artworkId) {
              const floorplanIndex = artwork.artwork_floor_plans.findIndex(af => af.floor_plan_id === floorplanId);
              if (floorplanIndex > -1) {
                const newAFP = artwork.artwork_floor_plans.slice();
                newAFP[floorplanIndex] = result;

                return {
                  ...ac,
                  artwork: {
                    ...artwork,
                    artwork_floor_plans: newAFP
                  }
                }
              }
            }

            return ac;
          });

          const updated = {
            ...collection,
            art_collections: artCollections,
          }

          this.updateCachedCollection(collectionId, updated);
        }
      }
    }).then(result => {
      const out = fromCollectionGraph.artworkFloorplan(result.data.update_artwork_floor_plans.returning[0])
      this.info('updateArtworkCoordinates SUCCESS', out);
      return out;
    });
  }

  addArtworkToCollection(collectionId, artwork, floorplanId, x, y, color) {
    this.debug('addArtworkToCollection', ...arguments);

    const variables = {
      collectionId,
      artworkId: artwork.id,
      floorplanId,
      x: toCollectionGraph.coordinate(x),
      y: toCollectionGraph.coordinate(y),
      color,
    }

    if (env.mock) {
      // send the full artwork so we can use it in the mocks
      // even if we don't use it as part of the GraphQL query.
      variables.artwork = toCollectionGraph.insert.artwork(artwork);
    }

    return this.client.mutate({
      mutation: graph.ADD_ARTWORK_TO_COLLECTION,
      variables,
      update: (cache, {data}) => {
        this.debug('ADD_ARTWORK_TO_COLLECTION UPDATE CACHE', data);

        const artCollectionResult = data.insert_art_collections.returning[0];
        const artFloorplanResult = data.insert_artwork_floor_plans.returning[0];

        const collection = this.getCachedCollection(collectionId);
        if (collection) {
          const artCollection = copy(artCollectionResult);

          const floorplans = artCollection.artwork.artwork_floor_plans;
          const matched = floorplans.find(f => f.floor_plan_id === floorplanId);

          // When running with mocks, the artwork result will not include
          // the expected floorplan so we have to add it manually.
          if (!matched) {
            floorplans.push(artFloorplanResult);
          }

          const artworkList = [artCollection];
          const artCollections = collection.art_collections.length
            ? collection.art_collections.concat(artworkList)
            : collection.art_collections = artworkList;

          const updated = {
            ...collection,
            art_collections: artCollections,
          };

          this.updateCachedCollection(collectionId, updated);
        }
      }
    }).then(results => {
      this.info('ADD_ARTWORK_TO_COLLECTION SUCCESS', results);
      return fromCollectionGraph.artCollection(results.data.insert_art_collections.returning[0]);
    });
  }

  removeArtworkFromCollection(collectionId, artwork, floorplanId) {
    this.debug('removeArtworkFromCollection', ...arguments);

    const variables = {
      collectionId,
      artworkId: artwork.id,
      floorplanId,
    }

    return this.client.mutate({
      mutation: graph.REMOVE_ARTWORK_FROM_COLLECTION,
      variables,
      update: (cache, {data}) => {
        const result = data.delete_art_collections;

        const collection = this.getCachedCollection(collectionId);
        if (result.affected_rows > 0 && collection) {
          const artCollections = collection.art_collections.filter(ac => ac.artwork.id !== artwork.id);

          const updated = {
            ...collection,
            art_collections: artCollections,
          };

          this.updateCachedCollection(collectionId, updated);
        }
      }
    }).then(results => {
      this.info('REMOVE_ARTWORK_FROM_COLLECTION SUCCESS', results);
      // No need to normalize because we are returning the original artwork.
      return artwork;
    });
  }
}

export const CollectionGraphAPIContext = React.createContext();
export const CollectionGraphAPIProvider = CollectionGraphAPIContext.Provider;

export function useCollectionGraphAPI() {
  return React.useContext(CollectionGraphAPIContext);
}
