import React from 'react';
import PropTypes from 'prop-types';

import {
  NumberInput,
} from '../number-input';
import {
  useCallWhenFormikReady,
} from '../formik-utils';
import {
  NumberOrStringPropType,
} from '~/model';

export function FormikNumberInput({
  name,
  value,
  form,
  onChange, // Trap onChange so it's never called.
  ...rest
}) {
  const whenReady = useCallWhenFormikReady();

  const handleChange = ({floatValue}) => {
    whenReady(() => form.setFieldValue(name, floatValue));
  };

  return (
    <NumberInput data-test="formikNumberInput"
      name={name}
      value={value}
      {...rest}
      onValueChange={handleChange}
    />
  );
}


// For more props
// @see https://www.npmjs.com/package/react-number-format
FormikNumberInput.propTypes = {
  /**
   * The initial value of the input.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The locale to use when formatting the currency.
   * See the `locale` property of `Intl.NumberFormat`
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
   */
  locale: PropTypes.string,
  /**
   * The default value of the input. This value will
   * be used whenever the input is empty and does not
   * have focus.
   */
  defaultValue: NumberOrStringPropType,
};

