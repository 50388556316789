import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  SecondaryButton,
  FormColumn,
  FormLayout,
} from '~/components';
import {
  useScrollToTop,
  mergeReplace,
} from '~/util';

import {
  ArtworkDetailsForm,
  ArtworkPurchasingForm,
  SearchOrCreateArtist,
} from '../forms';

import styles from './Details.module.scss';

export function formsAreValid(forms) {
  const keys = Object.keys(forms);
  if (keys.length === 0) return false;
  else {
    for (let i = 0; i < keys.length; i++) {
      if (!forms[keys[i]].valid) return false;
    }
  }

  return true;
}

export function Details({
  values,
  onPrevious,
  onSubmit,
  onArtistSearch,
  onChange,
  onReady,
  animated = true,
  delay,
}) {
  useScrollToTop();

  const [state, setState] = React.useState({});
  const valid = formsAreValid(state);

  const getFormValues = () => {
    let out = {};
    Object.keys(state).forEach(key => out = mergeReplace(out, state[key].values));
    // Merge back in values from previous steps with
    // the exception of the artist data which we
    // need to use as is.
    return {
      ...mergeReplace(values, out),
      artist: out.artist,
    };
  };

  const handleChange = (data) => {
    const key = data.name;
    setState(s => ({
      ...s,
      [key]: data,
    }));

    if (onChange) onChange(getFormValues());
  }

  // Handle artist changes separately because we
  // want to nest those under an artist key.
  const onArtistChange = data => {
    handleChange({
      ...data,
      // Nest the values under the artist key.
      values: {artist: data.values},
    });
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(getFormValues());
    }
  };

  const previous = (
    <SecondaryButton
      data-test="previous"
      className={styles.previous}
      onClick={() => onPrevious(getFormValues())}
    >
      Previous
    </SecondaryButton>
  );

  const next = (
    <Button
      data-test="next"
      className={styles.next}
      type="submit"
      disabled={!valid}
      onClick={handleSubmit}
    >
      Next
    </Button>
  );

  return (
    <FormLayout
      title="Details"
      actions={[previous, next]}
    >
      <FormColumn title="Artwork Details">
        <ArtworkDetailsForm
          strip
          name="details"
          values={values}
          onChange={handleChange}
          onReady={onReady}
        />
      </FormColumn>

      <FormColumn title="Purchasing Details">
        <ArtworkPurchasingForm
          strip
          name="purchasing"
          values={values}
          onChange={handleChange}
          onReady={onReady}
        />
      </FormColumn>

      <FormColumn title="Artist Details" className={styles.SearchOrCreateArtist}>
        <SearchOrCreateArtist
          strip
          name="artist"
          values={values?.artist}
          onChange={onArtistChange}
          onSearch={onArtistSearch}
          onReady={onReady}
          animated={animated}
          delay={delay}
        />
      </FormColumn>
    </FormLayout>
  );
}

Details.propTypes = {
  // This needs to be kept loose so we can pass various
  // parts of the artwork metadata around.
  values: PropTypes.object,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onArtistSearch: PropTypes.func.isRequired,
  animated: PropTypes.bool,
  /**
   * Debounce delay when running search requests.
   */
  delay: PropTypes.number,
};

