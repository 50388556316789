import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import {
  combineClasses,
} from '~/util';
import {
  Action,
} from '../buttons';

import {
  Image,
} from '../images';

import styles from './UserIcon.module.scss';

/*
 * Represents a user with their profile image
 * or their initials if they do not have an image.
 *
 * @prop {string} name The user's display name.
 * @prop {string} [image] The url to the user's display image.
 * @prop {boolean} [showName] True = show the user's name next
 *   to their image. False = only show the user bubble with
 *   their image or initials.
 * @prop {string} [className] Additional classes to apply to
 *   the user icon's root element.
 */
export const UserIcon = React.forwardRef(({
  name = '',
  image,
  showName,
  showTooltip,
  className,
  onClick,
  ...rest
}, ref) => {

  const stringToId = (s, defaultS) => s ? s.replace(' ', '-') : defaultS;
  const tooltipId = `tooltip-${stringToId(name, 'name')}-${stringToId(className, 'user-icon')}-${stringToId(rest['data-test'], 'userIcon')}`;

  const nameElement = showName
    ? <div className={styles.name} data-test="name">{ name }</div>
    : null;

  const initials = name.split(' ')
    .map((n) => n.substr(0, 1).toUpperCase())
    .splice(0, 2)
    .join('');

  const iconProps = {
    'data-test': 'icon',
    className: [styles.icon, 'icon'].join(' '),
  };

  if (!showName && showTooltip) {
    iconProps['data-tip'] = name;
    iconProps['data-for'] = tooltipId;
  }

  const icon = image
    ? <Image alt={name} src={image} {...iconProps} />
    : <div {...iconProps}>{ initials || '?' }</div>;

  return (
    <div
      data-test="userIcon"
      className={combineClasses(
        styles.UserIcon,
        className,
        !image ? styles.noImage : null,
      )}
      ref={ref}
      {...rest}
    >
      { nameElement }
      { icon }
      { !showName && showTooltip &&
        <ReactTooltip place="top" effect="solid" id={tooltipId} />
      }
    </div>
  );
});

UserIcon.styles = styles;

// TODO This is nolonger necessary?
export default UserIcon;

UserIcon.propTypes = {
  /**
   * The name of the user to display.
   */
  name: PropTypes.string.isRequired,
  /**
   * The URL to the user's profile picture.
   */
  image: PropTypes.string,
  /**
   * True = show the name next to the user.
   * False = show the user's name in a tooltip.
   */
  showName: PropTypes.bool,
  /**
   * If `showName = false` and `showTooltip = true`,
   * show a tooltip with the user's name on hover.
   */
  showTooltip: PropTypes.bool,
};

export const UserIconAction = React.forwardRef(({
  name,
  image,
  showName,
  showTooltip,
  className,
  ...rest
}, ref) =>
  <Action
    className={combineClasses(styles.UserIconAction, className)}
    button
    transparent
    feel="link"
    {...rest}
    ref={ref}
  >
    <UserIcon
      name={name}
      image={image}
      showName={showName}
      showTooltip={showTooltip}
    />
  </Action>
);

UserIconAction.styles = styles;

