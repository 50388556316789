import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  ArtistPropType,
} from '~/model';

import {
  AddButton,
  SearchButton,
} from '../../../buttons';

import { ArtistSearchForm } from '../artist-search';
import { CreateOrSelectArtist } from './CreateOrSelectArtist.jsx';
import { ArtistDetails } from './ArtistDetails.jsx';

import styles from './SearchOrCreateArtist.module.scss';


export function SearchOrCreateArtist({
  name,
  values,
  onSearch,
  onChange,
  onReady,
  animated,
  delay,
}) {
  const state = React.useRef({
    valid: null,
    validating: true,
    values,
    previousValidValue: null,
  });
  let artist = state.current.values;

  const [focusSearchInput, setFocusSearchInput] = React.useState(false);
  const [createView, setCreateView] = React.useState(() => artist && artist.first_name && !artist.id);

  const clearArtist = () => state.current.values = artist = {};

  const showArtistForm = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setFocusSearchInput(true);
      setCreateView(true);
    });
  };

  const showSearchForm = () => {
    clearArtist();
    ReactDOM.unstable_batchedUpdates(() => {
      setFocusSearchInput(!artist.id);
      setCreateView(false);
    });
  };

  const onArtistSearchChange = data => {
    state.current = data;
    onChange(data);
  };

  const onSelectSimilarArtist = artist => {
    state.current = {
      ...state.current,
      valid: true,
      validating: false,
      values: artist,
    };
    onChange(state.current);

    ReactDOM.unstable_batchedUpdates(() => {
      setFocusSearchInput(false);
      setCreateView(false);
    });
  };

  const onNewArtistChange = data => {
    state.current = data;
    onChange(data);
  };

  if (createView) {
    const data = !artist?.id
      // If we have filled out the artist data previously
      // (ie. left the page and came back), then use the artist
      // data we populated.
      ? artist
      // If the user searched for and selected an artist
      // (ie. the artist had an id), then only populate the
      // first and last name as a convenience in case their
      // search had no results.
      : {
          first_name: artist?.first_name,
          last_name:  artist?.last_name,
        };

    return (
      <div>
        <CreateOrSelectArtist
          data-testid="CreateOrSelectArtist"
          name={name}
          values={data}
          onSearch={onSearch}
          onSelectArtist={onSelectSimilarArtist}
          onChange={onNewArtistChange}
          onReady={onReady}
          delay={delay}
        />
        <SearchButton data-testid="searchArtistButton"
          className={styles.searchArtistButton}
          onClick={showSearchForm}
        >
          Search Artists
        </SearchButton>
      </div>
    );
  } else {
    return (
      <div>
        <ArtistSearchForm
          data-testid="ArtistSearchForm"
          name={name}
          values={artist}
          onChange={onArtistSearchChange}
          onSearch={onSearch}
          onReady={onReady}
          focus={focusSearchInput}
          animated={animated}
          debounceSpeed={delay}
        />
        <ArtistDetails artist={artist} />
        <AddButton data-testid="createArtistButton"
          className={styles.createArtistButton}
          onClick={showArtistForm}
        >
          Create New Artist
        </AddButton>
      </div>
    );
  }
}

SearchOrCreateArtist.propTypes = {
  name: PropTypes.string,
  values: ArtistPropType,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  animated: PropTypes.bool,
  /**
   * The debounce delay to use when searching for artists.
   */
  delay: PropTypes.number,
};
