import React from 'react';
import PropTypes from 'prop-types';

import {
  SectionTitle
} from '../titles';

import {
  combineClasses,
} from '~/util';

/*
 * This column represents a single column in a multi-column
 * form layout. It basically renders its contents with the
 * correct title element. Use it in tandem with `FormLayout`
 * to create responsive, multi-column forms.
 *
 * Example:
 * ```jsx
 * <FormColumn title="Foo">
 *   <input type="username" required />
 *   <input type="password" required />
 * </FormColumn>
 * ```
 */
export default function FormColumn({
  title,
  children,
  className,
  ...rest
}) {
  const titleElement = title
    ? <SectionTitle className="light" data-test="columnTitle">{ title }</SectionTitle>
    : null;

  return (
    <section
      data-test="column"
      {...rest}
      className={combineClasses('form-column', className)}
    >
      { titleElement }
      { children }
    </section>
  );
}

FormColumn.propTypes = {
  /**
   * Any child elements you want to render in this column.
   */
  children: PropTypes.node.isRequired,
  /**
   * A title to display above this column.
   */
  title: PropTypes.string,
};
