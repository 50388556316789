import React from 'react';

import {
  combineClasses,
} from '~/util';

import styles from './Buttons.module.scss';

export function Button({
  transparent,
  className,
  children,
  ...rest
}) {
  return (
    <button
      className={combineClasses(
        styles.Button,
        className,
        transparent ? 'transparent' : ''
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
}

export function SecondaryButton({
  className,
  children,
  ...rest
}) {
  return (
    <Button className={combineClasses(styles.secondary, className)} {...rest}>
      {children}
    </Button>
  );
}

export function PrimaryButton({
  className,
  children,
  ...rest
}) {
  return (
    <Button className={combineClasses(styles.primary, className)} {...rest}>
      {children}
    </Button>
  );
}

export function SuccessButton({
  className,
  children,
  ...rest
}) {
  return (
    <Button className={combineClasses(styles.success, className)} {...rest}>
      {children}
    </Button>
  );
}

export function WarnButton({
  className,
  children,
  ...rest
}) {
  return (
    <Button className={combineClasses(styles.warn, className)} {...rest}>
      {children}
    </Button>
  );
}

export function ErrorButton({
  className,
  children,
  ...rest
}) {
  return (
    <Button className={combineClasses(styles.error, className)} {...rest}>
      {children}
    </Button>
  );
}

