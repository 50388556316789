import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import TextareaAutosize from 'react-autosize-textarea';

import {
  SecondaryButton,
  Button,
} from '../../buttons';
import {
  FormColumn,
  FormLayout,
  FormikLabeledInput,
  FormikCurrencyInput,
  FormikNumberInput,
  FieldRow,
  useCallWhenFormikReady,
  // FormikDebug,
} from '../../forms';
import {
  ArtworkPage3Schema,
} from '~/model';
import {
  merge,
  useScrollToTop,
} from '~/util';

import styles from './Framing.module.scss';

export function Framing({
  values,
  onPrevious,
  onSubmit,
  onReady,
}) {
  useScrollToTop();

  const v = merge({
      framing: {
        cost: {
          note: '',
          description: '',
          wholesale: '',
          retail: '',
          quantity: 1,
        },
        notes: {
          public: {
            description: '',
          },
          private: {
            description: '',
          },
        },
      },
    },
    values
  );

  const whenReady = useCallWhenFormikReady();
  React.useEffect(() => whenReady(onReady), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = values => {
    if (
      !values.framing?.cost?.retail &&
      !values.framine?.cost?.wholesale &&
      !values.framing?.notes?.public?.description &&
      !values.framing?.notes?.private?.description
    ) {
      delete values.framing;
    }
    onSubmit(values);
  }

  return (
    <Formik
      initialValues={v}
      validationSchema={ArtworkPage3Schema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({dirty, isValid, values}) => {
        const previous = (
          <SecondaryButton
            data-test="previous"
            className={styles.previous}
            onClick={() => onPrevious(values)}
          >
            Previous
          </SecondaryButton>
        );

        const submit = (
          <Button
            data-test="next"
            className={styles.submit}
            type="submit"
            disabled={!isValid}
          >
            Submit
          </Button>
        );

        return (
          <Form className={styles.Framing} data-test="framingPage">
            <FormLayout
              title="Framing"
              actions={[previous, submit]}
            >
              <FormColumn title="Framing Cost">
                <FieldRow className={styles.cost}>
                  <FormikLabeledInput name="framing.cost.retail" label="Retail Price">
                    <FormikCurrencyInput
                      currency="USD"
                      allowNegative={false}
                      allowZero
                      data-test="retail"
                      autoFocus
                    />
                  </FormikLabeledInput>
                  <FormikLabeledInput name="framing.cost.wholesale" label="Wholesale Cost">
                    <FormikCurrencyInput
                      currency="USD"
                      allowNegative={false}
                      allowZero
                      data-test="wholesale"
                    />
                  </FormikLabeledInput>
                  <FormikLabeledInput name="framing.cost.quantity" label="Quantity">
                    <FormikNumberInput data-testid="quantity" required defaultValue="1" />
                  </FormikLabeledInput>
                </FieldRow>

                <FormikLabeledInput
                  name="framing.notes.public.description"
                  label="Notes for Client"
                  help="A description about the artwork framing that will be displayed to the client in the proposal."
                  helpWarning
                >
                  <TextareaAutosize data-test="description" />
                </FormikLabeledInput>
                <FormikLabeledInput
                  name="framing.notes.private.description"
                  label="Internal Notes"
                  help="Notes that will only be visible to 9 Dot Arts."
                >
                  <TextareaAutosize data-test="notes" />
                </FormikLabeledInput>
              </FormColumn>
            </FormLayout>

          </Form>
        );
      }}
    </Formik>
  );
}

Framing.propTypes = {
  // This needs to be kept loose so we can pass various
  // parts of the artwork metadata around.
  values: PropTypes.object,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
