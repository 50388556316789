import { env } from '~/services';

export const selectors = {
  id: 'loader',
  stop: 'stop',
}

// Use 'trace' to see which component started the PageLoaders.
const logLevel = 'debug'; //'trace';

let LoaderService;
let timer;

/*
 * This loader service will start/stop the global
 * loader element by adding/removing the stop class
 * on the loader element.
 *
 * This component expects a loader element to exist on the page
 * (should be embedded in the index.html template so it loads
 * before the app is bootstraped).
 *
 * @see `public/index.html` for more info.
 */
export default LoaderService = {
  selectors: selectors,

  getLoader() {
    return document.getElementById(selectors.id);
  },

  start() {
    const l = LoaderService.getLoader();
    if (l && l.classList.contains(selectors.stop)) {
      if (env.verbose) console[logLevel]('[LOADER SERVICE] START', Date.now());
      l.classList.remove(selectors.stop);
    }
  },

  /**
   * Stop the global loader. Passing false for the immediate flag
   * will delay stopping the loader for `delay` milliseconds in case
   * another component needs to quickly restart it. This prevents
   * the loader animation from jumping between page transitions
   * and other loading events.
   * @param {boolean} [immediate]
   * @param {number} [delay]
   */
  stop(immediate = true, delay = 60) {
    const l = LoaderService.getLoader();
    if (l && !l.classList.contains(selectors.stop)) {
      const stop = () => {
        if (env.verbose) console[logLevel]('[LOADER SERVICE] STOP', delay, Date.now());
        l.classList.add(selectors.stop);
        timer = null;
      }

      if (immediate) stop()
      else {
        if (timer) timer = clearTimeout(timer);
        timer = setTimeout(stop, delay);
      }
    }
  },
};
