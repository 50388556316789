import {
  clamp,
} from '~/util';
import {
  COST_TYPES,
  NOTE_CATEGORIES,
} from '~/model';
import {
  COORDINATE_PRECISION,
  prepareNestedRelationships,
} from './util';

export const toCollectionGraph = {
  /**
   * Convert a coordinate location from a fraction between
   * 0 and 1 into an integer of COORDINATE_PRECISION.
   */
  coordinate(value) {
    return Math.round(clamp(value) * COORDINATE_PRECISION);
  },

  note(n) {
    const out = {
      // Default to private.
      is_public: !!n.is_public,
      description: n.description,
      // Default the category to match the public flag.
      category: n.category || (n.is_public ? NOTE_CATEGORIES.PUBLIC : NOTE_CATEGORIES.PRIVATE),
    };

    if (n.id) out.id = n.id;

    return out;
  },

  /**
   * Convert a notes object with public/private fields into an array
   * of note objects with description/is_public fields.
   */
  notes(notes) {
    if (notes) {
      const out = Object.keys(notes).reduce((acc, key) => {
        let note = notes[key];
        if (note && note.description) {
          // If the is_public flag isn't specified, set one based on the key.
          if (note.is_public === undefined) {
            note = {...note, is_public: key === 'public'};
          }

          // If the note doesn't have a category, set one using the object key.
          if (note.category === undefined) {
            note = {...note, category: NOTE_CATEGORIES[key.toUpperCase()]};
          }

          acc.push( toCollectionGraph.note(note) );
        }

        return acc;
      }, []);

      if (out.length > 0) return out;
    }

    // Return an empty array because the mocks throw an exception
    // if returning null and it also ensures that the mocks don't
    // generate mock notes when we intend to have no notes.
    return [];
  },

  /**
   * @param {FramingPropType} framing - The non-graph shape.
   */
  framingCost(framing) {
    const out = {
      retail_price_cents: framing.cost.retail,
      retail_currency: framing.cost.retailCurrency || 'USD',
      wholesale_price_cents: framing.cost.wholesale,
      wholesale_currency: framing.cost.wholesaleCurrency || 'USD',
      quantity: framing.cost.quantity,
      note: toCollectionGraph.framingCostDescription(framing),
      description: COST_TYPES.framing,
    };
    if (framing.cost.id) out.id = framing.cost.id;
    return out;
  },

  framing(f) {
    const out = {
      costs: [toCollectionGraph.framingCost(f)],
    };

    if (f.notes) out.notes = toCollectionGraph.notes(f.notes);
    if (f.id) out.id = f.id;

    return out;
  },

  /**
   * Create the cost description specific to purchasing an artwork.
   * @param {ArtworkPropType} a
   */
  artworkCostDescription(a, separator = '\n') {
    const artist = a.artist;
    const d = a.dimensions;

    const out = [
      `Artwork Title: ${a.title}`,
    ];

    if (a.dimensions) {
      out.push(`Dimensions: H ${d.height} x W ${d.width} ${!d.depth ? '' : 'x D ' + d.depth}`);
    }

    // `Vendor: ${a.vendor.name}`,

    if (a.artist) {
      const artistContact = [artist.email, artist.phone_number, artist.url].reduce(
        (acc, curr) => curr ? (acc.push(curr), acc) : acc,
        []
      ).join(', ');

      out.push(
        `Artist: ${a.artist.first_name} ${a.artist.last_name}`,
        `Artist Contact: ${artistContact}`,
      )
    }

    if (a.notes) {
      if (a.notes.private) out.push(`Internal Notes: ${a.notes.private.description}`);
      if (a.notes.public) out.push(`Notes for Client: ${a.notes.public.description}`);
    }

    return out.join(separator);
  },

  dimensions(d) {
    const out = {
      width: d.width,
      height: d.height,
      depth: d.depth,
    };

    if (d.id) out.id = d.id;
    return out;
  },

  /**
   * Create a generic cost description.
   * @param {CostPropType} cost
   */
  framingCostDescription(cost) {
    const out = [];
    // `Vendor: ${cost.vendor.name}`,

    if (cost.notes) {
      if (cost.notes.private && cost.notes.private.description)
        out.push(`Internal Notes: ${cost.notes.private.description}`);
      if (cost.notes.public && cost.notes.public.description)
        out.push(`Notes for Client: ${cost.notes.public.description}`);
    }

    return out.join('\n');
  },

  artworkCost(artwork) {
    const out = {
      retail_price_cents: artwork.price.retail,
      retail_currency: artwork.price.retailCurrency || 'USD',
      wholesale_price_cents: artwork.price.wholesale,
      wholesale_currency: artwork.price.wholesaleCurrency || 'USD',
      quantity: artwork.price.quantity,
      note: toCollectionGraph.artworkCostDescription(artwork),
      description: COST_TYPES.artwork,
    };

    if (artwork.price.id) out.id = artwork.price.id;

    return out;
  },

  image(i) {
    const out = {
      title: i.title,
      description: i.description,
      url: i.url,
      file_name: i.file_name,
      width: i.width,
      height: i.height,
    };

    if (i.id) out.id = i.id;

    return out;
  },

  /**
   * Convert the upload metadata (ie. upload_tmp field).
   * It takes the shape:
   * {
   *   "id":"1296677eb83b3499a38771be06298063_1585696637",
   *   "storage":"cache_upload",
   *   "metadata":{
   *     "size":322575,
   *     "file_name":"Continental Event 1st floor.png",
   *     "mime_type":"image/png"
   *   }
   * }
   */
  uploadData(u) {
    return JSON.stringify(u.upload_tmp);
  },

  /**
   * Convert an upload object originally retrieved from the Graph.
   */
  upload(u) {
    const upload = {
      image: toCollectionGraph.image(u),
    };

    if (u.uploadId) upload.id = u.uploadId;
    if (u.upload_tmp) upload.upload_tmp = toCollectionGraph.uploadData(u);
    if (u.user_id) upload.user_id = u.user_id;

    return upload;
  },


  user(u) {
    const out = {
      id: u.id,
      first_name: u.firstName,
      last_name: u.lastName,
      email: u.email,
    };

    if (u.image) out.uploads = [toCollectionGraph.upload(u.image)];

    return out;
  },


  artist(a) {
    const out = {
      first_name: a.first_name,
      last_name: a.last_name,
      email: a.email,
      phone_number: a.phone_number,
      // url: a.url,
    };

    if (a.id) out.id = a.id;
    return out;
  },

  floorplan(f) {
    const out = {
      title: f.title,
      collection_id: f.collection_id,
    };

    if (f.id) out.floor_plan_id = f.id;
    if (f.notes && (f.notes.public || f.notes.private)) out.notes = toCollectionGraph.notes(f.notes);
    if (f.image) out.uploads = [toCollectionGraph.upload(f.image)];

    return out;
  },

  artworkFloorplan(af) {
    const out = {
      floor_plan_id: af.floorplanId,
      x: toCollectionGraph.coordinate(af.x),
      y: toCollectionGraph.coordinate(af.y),
      hex_color: af.color,
    };

    if (af.id) out.id = af.id;
    if (af.notes) out.notes = toCollectionGraph.notes(af.notes);

    return out;
  },

  artwork(a) {
    const artwork = {
      medium: a.medium,
      title: a.title,
      sold: a.sold,
      // artwork_type: a.type,
      dimensions: a.dimensions ? [toCollectionGraph.dimensions(a.dimensions)] : null,
      costs: [toCollectionGraph.artworkCost(a)],
      uploads: a.images.map(toCollectionGraph.upload),
    };

    if (a.artist)
      artwork.artist = toCollectionGraph.artist(a.artist);
    if (a.notes)
      artwork.notes = toCollectionGraph.notes(a.notes);
    if (a.framing)
      artwork.framings = [toCollectionGraph.framing(a.framing)];
    if (a.floorplans)
      artwork.artwork_floor_plans = a.floorplans.map(toCollectionGraph.artworkFloorplan);
    if (a.id)
      artwork.id = a.id;

    return artwork;
  },

  // When inserting objects to the graph, nested object relationships
  // need to be specified using the relationship syntax. These insert
  // methods will do that for you.
  insert: {
    artwork(artwork, uploads = []) {
      // Create objects ready for the graph.
      let a = toCollectionGraph.artwork(artwork);

      // Add in the uploads prepared with nested image relationships.
      return prepareNestedRelationships(a);
    },

    floorplan(floorplan, collectionId, upload) {
      let f = toCollectionGraph.floorplan({
        ...floorplan,
        collection_id: collectionId,
        image: upload,
      });

      return prepareNestedRelationships(f);
    },

    userImage(userId, upload) {
      const u = toCollectionGraph.upload(upload);
      u.user_id = userId;
      return prepareNestedRelationships(u);
    }
  },
};

