import React from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef {object} ImageProps
 * @property {*} [ref]
 * @property {string} [alt]
 */
/**
 * @type React.FC<ImageProps>
 */
export const Image = React.forwardRef(({
  alt,
  ...rest
}, ref) => {
  return <img ref={ref} alt={alt} {...rest} crossOrigin="anonymous" />
});

Image.propTypes = {
  /**
   * The alt text describing this image. You should always
   * pass a description for your images.
   */
  alt: PropTypes.string.isRequired,
}
