import React from 'react';

import { useCollectionGraphAPI } from '../graph-api';
import { ImageTransport } from '~/model';
import {
  useImageUpload,
} from './useImageUpload';

/**
 * @typedef UploadImageCallback
 * @type {callback}
 * @param {number} userId
 * @param {string} picture - URL of the image to upload
 * @param {string} category - the category of upload (ex. 'profile')
 *   This will be used as the description of the graph image.
 * @param {string} title - The title of the resulting graph image.
 */
/**
 * Upload an image associated with the specified user id.
 * @return UploadImageCallback
 */
export function useUploadUserImage() {
  const api = useCollectionGraphAPI();
  const uploadImage = useImageUpload();

  return React.useCallback((userId, picture, category, title) => {
    return uploadImage(new ImageTransport(picture, title), category)
      .then(awsUpload =>
        api.addUserImage(userId, awsUpload[0])
      );
  }, [uploadImage, api]);
}
