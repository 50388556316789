import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from '~/util';
import { ArtistPropType } from '~/model';
import { Loader } from '../../../loader';
import { FieldRow } from '../../../forms';
import { SimilarArtistsList } from './SimilarArtistsList.jsx';
import { CreateArtistForm } from '../create-artist';
import { checkForSimilarArtists } from './artist-similarity';

import styles from './CreateOrSelectArtist.module.scss';

export function CreateOrSelectArtist({
  name,
  values,
  onChange,
  onSearch,
  onSelectArtist,
  onReady,
  delay = 500,
  ...rest
}) {
  const [loading, setLoading] = React.useState(false);
  const [similarArtists, setSimiliarArtists] = React.useState([]);
  const [error, setError] = React.useState(false);
  const state = React.useRef();

  const handleArtistSearch = useDebouncedCallback(
    values => checkForSimilarArtists(values, onSearch),
    delay
  );

  // Handle form changes.
  const onCreateArtistFormChange = React.useCallback(data => {
    const setStateAndEmit = s => {
      state.current = s;
      if (onChange) onChange(s);
    };

    // Clear error messages.
    setError(false);

    if (data.dirty) {
      // Emit the update but indicate that we are still validating.
      setStateAndEmit({
        ...data,
        valid: null,
        validating: true,
        // Maintain the previous valid value because we don't yet
        // know if this new value is valid.
        previousValidValues: state.previousValidValue,
      });

      // Show the loader.
      setLoading(true);

      handleArtistSearch(data.values)
        .then(({similar}) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setSimiliarArtists(similar);
            setLoading(false);
          });

          setStateAndEmit(data);
        })
        .catch(({similar, errors}) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setSimiliarArtists(similar);
            setError(errors);
            setLoading(false);
          });

          setStateAndEmit({
            ...data,
            errors,
            valid: false,
            validating: false,
          });
        });
    } else {
      // Forward the change event.
      setStateAndEmit(data);
    }
  }, [onChange, handleArtistSearch]);

  return (
    <FieldRow data-testid="CreateOrSelectSimilarArtist"
      className={styles.CreateOrSelectSimilarArtist}
      responsive
      {...rest}
    >
      <CreateArtistForm
        className={styles.CreateArtistForm}
        name={name}
        values={values}
        onChange={onCreateArtistFormChange}
        onReady={onReady}
      />
      <div className={styles.similarArtistsWrapper}>
        <SimilarArtistsList
          artists={similarArtists}
          onSelectArtist={onSelectArtist}
          error={!!error}
        />
        { loading &&
          <Loader className={styles.similarArtistsLoader} fast />
        }
      </div>
    </FieldRow>
  );
}

CreateOrSelectArtist.propTypes = {
  name: PropTypes.string,
  values: ArtistPropType,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectArtist: PropTypes.func.isRequired,
  onReady: PropTypes.func,
  animated: PropTypes.bool,
  delay: PropTypes.number,
};

