import React from 'react';

import {
  PageLoader,
} from './components';
import { BundleLoadError } from './pages/bundle-load-error';

import {
  GoogleLogin,
  // Login,
} from './pages/login';
import {
  AmplifyAuthClientMock,
} from './services/auth/mocks';

import {
  AuthAPI,
  AuthAPIProvider,
  useAuth,
} from './services/auth';
import { env } from './services/env';
import defaultHistory from './routes/history';
import { configureAmplify } from './aws-configure';

import './App.scss';

console.log('------ STARTING APP ------');
env.log();

// Configure the Amplify client here because
// it needs to happen before authentication and
// it should only happen once at page load.
if (!env.mock) configureAmplify();

// Create two separate bundles so the login page can
// load as quickly as possible.
const Home = env.mock
  ? React.lazy(() => import('./pages/home/WithMocks.jsx'))
  : React.lazy(() => import('./pages/home/WithServer.jsx'));


function makeMockAuthClient() {
  const simulatePreviouslyLoggedIn = true;
  const simulateLoginFailure = false;
  const api = new AmplifyAuthClientMock(simulatePreviouslyLoggedIn, simulateLoginFailure);
  return new AuthAPI(api, api);
}

function makeAuthClient() {
  return new AuthAPI();
}

export default function App({
  history = defaultHistory,
  storageService,
  graphService,
  authService,
  doProfileUpload,
  animated,
}) {
  const [auth] = React.useState(() => {
    if (authService) return authService;
    else if (env.mock) return makeMockAuthClient();
    else return makeAuthClient();
  });
  const {loading, authenticated, authResponse, logout} = useAuth(auth);

  const onAuthSuccess = () => {/* noop for now */};

  const onAuthFailure = () => {
    // Clear the auth state.
    logout();
  };

  const wrap = component => (
    <div className="App">
      <AuthAPIProvider value={auth}>
        { component }
      </AuthAPIProvider>
    </div>
  );

  // While we are waiting to find out the authenticated state
  // of the user, let the page loader continue to run.
  if (loading) {
    return <PageLoader />;
  }
  else if (!authenticated) {
    return wrap(
      <GoogleLogin onSuccess={onAuthSuccess} />
    );
  }
  else {
    return wrap(
      <BundleLoadError>
        <React.Suspense fallback={null}>
          <Home
            authResponse={authResponse}
            onAuthFailure={onAuthFailure}
            authService={auth}
            history={history}
            storageService={storageService}
            graphService={graphService}
            doProfileUpload={doProfileUpload}
            animated={animated}
          />
        </React.Suspense>
      </BundleLoadError>
    );
  }
}

