import React from 'react';
import { separateSettledPromises } from '@thesoulfresh/utils';

import {
  ImageTransport,
  ImageTransportError,
} from '~/model';
import {
  urlsToImageTransports,
} from '../images';

let pasteId = 0;

/**
 * Convert `text/plain` data into an ImageTransport
 * if it contains one or more URLs.
 * @param {DataTransferItem} item
 * @return {Promise<ImageTransport>}
 */
export function getClipboardURLData(item) {
  return new Promise((resolve, reject) => {
    item.getAsString(data => {
      const error = new ImageTransportError(
        {
          item: data,
          action: ImageTransportError.PASTE,
          type: ImageTransportError.URL,
          reason: ImageTransportError.INVALID,
        },
        'The text you pasted is not a URL or list of URLs.'
      );

      if (data) {
        const images = urlsToImageTransports(data);

        if (images.length) resolve(images);
        else {
          console.debug('[Clipboard] "plain/text" item does not contain any URLs.', data);
          reject(error);
        }
      } else {
        reject(error);
      }
    });
  });
}

/**
 * Convert `image/*` data into an ImageTransport.
 * @param {DataTransferItem} item
 * @return {Promise<ImageTransport>}
 */
export function getClipboardImageData(item) {
  return new Promise((resolve, reject) => {
    try {
      const file = item.getAsFile();
      if (file) {
        const ext = file.type.replace('image/', '');
        const name = `clipboard-image-${++pasteId}.${ext}`;

        resolve(
          new ImageTransport(
            // Convert to a new file so we can specify the name.
            new File(
              [file],
              name,
              {type: file.type, lastModified: file.lastModified}
            ),
            name,
            undefined,
            file.type
          )
        );
      } else {
        reject(new ImageTransportError(
          {
            failed: item,
            action : ImageTransportError.PASTE,
            type   : ImageTransportError.FILE,
            reason : ImageTransportError.INVALID,
          },
          'Failed to load image from clipboard.'
        ));
      }
    } catch (error) {
      console.debug('[Clipboard] Failed to get File reference from clipboard data.', error);
      reject(new ImageTransportError(
        {
          failed: item,
          error,
          action : ImageTransportError.PASTE,
          type   : ImageTransportError.FILE,
          reason : ImageTransportError.INVALID,
        },
        'Failed to load image from clipboard.'
      ));
    }
  });
}

/**
 * Convert the clipboard data into an ImageTransport.
 * @param {DataTransferItem} item
 * @return {Promise<ImageTransport>}
 */
export function getClipboardData(item) {
  if (item.type === 'text/plain' || item.type === 'text/uri-list') {
    return getClipboardURLData(item);
  } else {
    // Because URLs strings can contain multiple images,
    // this API should always return an array. Since we
    // know image items are always singular, return an
    // array with a single item.
    // TODO Is it possible to paste multiple in other browsers?
    return getClipboardImageData(item)
      .then(image => [image]);
  }
}

/**
 * This hook adds a clipboard paste listener to the document
 * while the component is mounted and calls the `onSuccess`
 * method when images are pasted anywhere in the document.
 * On unmount, the clipboard listener is removed.
 *
 * @param {function} onSuccess - This callback will be called
 *   when image data is pasted into the browser. The callback
 *   is called with an array of `File` objects.
 * @param {function} [onError] - This callback will be called
 *   if items are pasted into the browser that are not images
 *   or are images that could not be read.
 */
export function useImagePaste(onSuccess, onError) {
  React.useEffect(() => {
    const onPaste = event => {
      const items = (event.clipboardData  || event.originalEvent.clipboardData).items;
      const pasted = Array.from(items)
        .filter(i =>
          i.type.startsWith('image/') ||
          i.type === 'text/uri-list' ||
          i.type === 'text/plain'
        );

      if (!pasted.length) {
        if (onError) {
          onError(new ImageTransportError(
            {
              item: pasted,
              action: ImageTransportError.PASTE,
              reason: ImageTransportError.EMPTY,
            },
            'The clipboard does not contain any images or urls.'
          ));
        }
      } else {
        Promise.allSettled(pasted.map(getClipboardData))
          .then(separateSettledPromises)
          .then(([loaded, failed]) => {
            if (loaded.length) {
              onSuccess(loaded.flat());
            } else {
              onError(new ImageTransportError(
                {
                  item: failed,
                  action: ImageTransportError.PASTE,
                  reason: ImageTransportError.EMPTY,
                },
                'The clipboard does not contain any images or urls.'
              ));
            }
          });
      }
    };

    document.addEventListener('paste', onPaste, false);
    return () => {
      document.removeEventListener('paste', onPaste, false);
    }
  }, [onSuccess, onError]);
}

