import {
  userLocale,
} from '~/util';

/*
 * Convert an integer representing 100ths of a unit
 * into that unit.
 * For example:
 *   11122 -> 111.22
 *   null -> 0
 */
export function fromHundredths(pennies) {
  return pennies ? pennies / 100 : 0;
}

/*
 * Convert a fractional unit into an integer representing
 * 100ths of that unit.
 * For example:
 *   111.22 -> 11122
 *   null -> 0
 */
export function toHundredths(dollars) {
  return dollars ? Math.round(dollars * 100) : 0;
}

/*
 * Translate an integer from English into the language
 * specified by the locale passed. If no locale is
 * passed, then the user's current locale is used.
 * The returned number will have no formatting.
 */
export function translateInteger(number, locale = userLocale()) {
  const f = new Intl.NumberFormat(locale, {
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return f.format(number);
}

/*
 * Determine the character used to group large numbers
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeThousandsSeparator(locale = userLocale()) {
  const f = new Intl.NumberFormat(locale, {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const value = 111111;
  // TODO Can I always assume that I can translate '1' and replace
  // that translation inside the formatted version of '11111'?
  // For example, what if `f.format(11111)` returned "one thousand..."?
  const num = translateInteger(1, locale);
  return f.format(value).replace(new RegExp(num, 'g'), '').trim()[0];
}

/*
 * Determine the style of grouping used for large numbers
 * in the locale specified (or the user's current locale
 * if none is passed).
 *
 * @return 'thousand', 'lahk', 'wan'
 */
export function localeThousandsGroupStyle(locale = userLocale()) {
  const f = new Intl.NumberFormat(locale, {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const value = 111111;
  const separator = localeThousandsSeparator(locale);
  const index = f.format(value).indexOf(separator);
  return index === 1
    ? 'lakh'
    : index === 2
    // Not sure that Ecmascript supports wan style groupings.
    ? 'wan'
    : 'thousand';
}

/*
 * Determine the character used to signify fractions
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeDecimalSeparator(locale = userLocale()) {
  const f = new Intl.NumberFormat(locale, {
    useGrouping: false,
    minimumFractionDigits: 2,
  });
  const value = 1.11;
  // TODO Can I always assume that I can translate '1' and replace
  // that translation inside the formatted version of '11111'?
  // For example, what if `f.format(11111)` returned "one thousand..."?
  const num = translateInteger(1, locale);
  return f.format(value).replace(new RegExp(num, 'g'), '').trim()[0];
}

function determineLocaleCurrencySymbol(value, currency, locale = userLocale(), currencyDisplay = 'symbol') {
  const f = new Intl.NumberFormat(locale || undefined, {
    style: 'currency',
    currency,
    currencyDisplay,
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const num = translateInteger(value, locale);
  return f.format(value).replace(new RegExp(num, 'g'), '').trim();
}

/*
 * Determine the symbol used for a currency code,
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeCurrencySymbol(currency, locale) {
  return determineLocaleCurrencySymbol(1, currency, locale, 'symbol');
}

/*
 * Determine the full name used for a currency code and value,
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeCurrencyName(value, currency, locale) {
  return determineLocaleCurrencySymbol(value, currency, locale, 'name');
}

/*
 * Determine if a currency symbol/name should be prefixed
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeCurrencyIsPrefixed(locale = userLocale(), currencyDisplay = 'symbol') {
  const f = new Intl.NumberFormat(locale || undefined, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay,
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const value = 1;
  const num = translateInteger(value, locale);

  return f.format(1).indexOf(num) > 0;
}

function determineLocaleUnitSymbol(value, unit, locale = userLocale(), unitDisplay = 'short') {
  const f = new Intl.NumberFormat(locale || undefined, {
    style: 'unit',
    unit,
    unitDisplay,
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const num = translateInteger(value, locale);
  return f.format(value).replace(new RegExp(num, 'g'), '').trim();
}

export function supportsLocaleUnits() {
  try {
    determineLocaleUnitSymbol(1, 'inch');
    return true;
  } catch (e) {
    return false;
  }
}

/*
 * Determine the symbol for the given unit
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeUnitSymbol(unit, locale, unitDisplay) {
  return determineLocaleUnitSymbol(1, unit, locale, unitDisplay);
}

/*
 * Determine the full name for the given unit
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeUnitName(value, unit, locale) {
  return determineLocaleUnitSymbol(value, unit, locale, 'long');
}

/*
 * Determine the unit symbol/name should be prefixed
 * in the locale specified (or the user's current locale
 * if none is passed).
 */
export function localeUnitIsPrefixed(unit, locale = userLocale(), unitDisplay = 'short') {
  const f = new Intl.NumberFormat(locale || undefined, {
    style: 'unit',
    unit,
    unitDisplay,
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const value = 1;
  const num = translateInteger(value, locale);

  return f.format(1).indexOf(num) > 0;
}

