import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import {
  combineClasses,
} from '~/util';

import styles from './Action.module.scss';


/**
 * Merge the children and icon props into a single
 * renderable node.
 */
function setChildren(children, icon) {
  let iconElement = null;
  if (icon) {
    const siblings = React.Children.count(children) > 0;
    iconElement = React.cloneElement(icon, {
      ...icon.props,
      className: combineClasses(
        // Add generic icon styling
        styles.icon,
        // Include the className on props
        icon.props ? icon.props.className : null,
        // If there are additional children next to the icon,
        // add spacing between them.
        siblings ? styles.withText : null,
      ),
    });
  }

  return (
    <>
      { iconElement }
      { children }
    </>
  );
}

/**
 * Convert a React Router `to` object and convert it
 * into a URL string.
 */
function makeLinkFromObject(to) {
  let href = '';
  if (to.pathname) href += to.pathname;
  if (to.hash) href += to.hash;
  if (to.search) href += to.search;
  return href;
}

/**
 * Renders either a react router link component
 * or a standard browser link.
 */
const ActionLink = React.forwardRef(({
  blank,
  unrouted,
  href,
  to = href,
  icon,
  feel = 'link',
  className,
  children,
  ...rest
}, ref) => {
  // Hash links should be unrouted because react router
  // does not handle them well.
  const hasHash = (typeof(to) === 'object')
    ? !!to.hash
    : to
    ? to.split('#').length > 1
    : '';

  const classes = combineClasses(
    className,
    styles.ActionLink,
    feel === 'link' ? styles.LinkStyle : styles.ButtonStyle,
  );

  if (blank || hasHash || unrouted) {
    const href = (typeof(to) === 'object')
      ? makeLinkFromObject(to)
      : to;
    const target = blank
      ? { target: "_blank", rel: "noopener noreferrer" }
      : {};

    return (
      <a
        className={classes}
        href={href}
        tabIndex="0"
        {...target}
        {...rest}
        children={setChildren(children, icon)}
        ref={ref}
      />
    );
  } else {
    return (
      <RouterLink
        className={classes}
        data-router-link
        to={to}
        tabIndex="0"
        {...rest}
        children={setChildren(children, icon)}
        ref={ref}
      />
    );
  }
});

ActionLink.styles = styles;

ActionLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  blank: PropTypes.bool,
  unrouted: PropTypes.bool,
  icon: PropTypes.element,
  display: PropTypes.oneOf(['primary', 'secondary', 'success', 'warn', 'error']),
  feel: PropTypes.oneOf(['button', 'link']),
  transparent: PropTypes.bool,
  unrounded: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};


/**
 * Renders a standard HTML button, defaulting to type "button"
 */
const ActionButton = React.forwardRef(({
  className,
  icon,
  feel = 'button',
  children,
  ...rest
}, ref) =>
  <button
    className={combineClasses(
      styles.ActionButton,
      className,
      feel === 'link' ? styles.LinkStyle : styles.ButtonStyle,
    )}
    type="button"
    {...rest}
    children={setChildren(children, icon)}
    ref={ref}
  />
);

ActionButton.styles = styles;

ActionButton.propTypes = {
  icon: PropTypes.element,
  display: PropTypes.oneOf(['primary', 'secondary', 'success', 'warn', 'error']),
  feel: PropTypes.oneOf(['button', 'link']),
  transparent: PropTypes.bool,
  unrounded: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};


/**
 * Renders either a button or a link depending on the
 * `isButton` prop.
 */
export const Action = React.forwardRef(({
  button,
  display = 'secondary',
  unrounded,
  transparent,
  vertical,
  size,
  iconButton,
  className,
  children,
  ...rest
}, ref) => {
  const classes = combineClasses(
    className,
    styles[display],
    styles[size],
    unrounded ? styles.unrounded : null,
    transparent ? styles.transparent : null,
    vertical ? styles.vertical : null,
    !children ? styles.noText : null,
    iconButton ? styles.iconButton : null,
  );

  return button
    ? <ActionButton className={classes} {...rest} ref={ref} children={children} />
    : <ActionLink   className={classes} {...rest} ref={ref} children={children} />
});

Action.styles = styles;

Action.propTypes = {
  /**
   * If true, render a `<Button>` component. Otherwise,
   * render a `<Link>`.
   */
  button: PropTypes.bool,
  /**
   * Either an object as accepted by React Router or a string to
   * use as an href. Only useful if you are rendering a link.
   * See https://reactrouter.com/web/api/Link/to-object for React
   * Router docs.
   */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * You can also pass an href string like a standard but the `to`
   * prop is used if you pass both. Only useful when rendering links.
   */
  href: PropTypes.string,
  /**
   * __true__: set `target="_blank" rel="noopener noreferrer"`.
   * __false__: link internally using react router.
   * Only useful when rendering links.
   */
  blank: PropTypes.bool,
  /**
   * __true__: force this link to be a standard HTML link that will not
   * be routed by React Router. Only usefull when rendering links.
   */
  unrouted: PropTypes.bool,
  /**
   * An icon to render before the children. For more advanced usecases
   * you can pass the icon in the children prop and handle styling yourself.
   */
  icon: PropTypes.element,
  /**
   * The role of this action. "Secondary" is the same as not passing
   * a display.
   */
  display: PropTypes.oneOf(['primary', 'secondary', 'success', 'warn', 'error']),
  /**
   * Whether this action should look like a action or a link.
   * The default depends on whether you are rendering a link or
   * a buttons.
   */
  feel: PropTypes.oneOf(['button', 'link']),
  /**
   * Whether this action should have a transparent background.
   * This is only applicable when using the action feel.
   */
  transparent: PropTypes.bool,
  /**
   * Whether this action should remove the rounded corners.
   * This is useful when you need the action to fit nicely
   * in a box layout.
   */
  unrounded: PropTypes.bool,
  /**
   * Sets the size of the content to small, medium or large.
   */
  size: PropTypes.oneOf(['s', 'm', 'l']),
  /**
   * Whether the icon and text should be stacked vertically.
   */
  vertical: PropTypes.bool,
  /**
   * Special styling for icon buttons.
   */
  iconButton: PropTypes.bool,
};

