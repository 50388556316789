import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
} from '../titles';
import { combineClasses } from '~/util';
import { Card } from './Card.jsx';

import styles from './CardWithHeader.module.scss';

export function CardWithHeader({
  children,
  className,
  title,
  icon,
  onSelect,
  ...rest
}) {
  return (
    <Card className={combineClasses(styles.CardWithHeader, className)}
      onSelect={onSelect}
      {...rest}
    >
      <Title className={styles.heading}>
        {
          React.cloneElement(icon, {
            className: combineClasses(icon.props.className, styles.icon)
          })
        }
        <span data-test="cardTitle">{ title }</span>
      </Title>
      <div className={styles.cardBody}>
        {children}
      </div>
    </Card>
  );
}

CardWithHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  onSelect: PropTypes.func,
};
