import * as Yup from 'yup';
import {
  ARTWORK_MEDIUM_VALUES,
} from './collection';

/* eslint-disable no-template-curly-in-string */

const NumberSchema = Yup.number()
  .transform(value => isNaN(value) ? undefined : value)
  .transform(v => v === '' ? null : v);
const StringSchema = Yup.string()
  .transform(value => value === null ? '' : value);
const BooleanSchema = Yup.boolean()
  .transform(value => value == null ? value : !!value);

export const LooseNumberSchema = NumberSchema.nullable();
export const LooseStringSchema = StringSchema.nullable();

export const RequiredNumberSchema = NumberSchema.typeError('is required').required('is required');
export const RequiredStringSchema = StringSchema.typeError('is required').required('is required');

// const RequiredBooleanSchema = BooleanSchema.required('is required');
export const LooseBooleanSchema = BooleanSchema.nullable();

const ArtworkMediumSchema = Yup.mixed().oneOf(ARTWORK_MEDIUM_VALUES);

const OptionalObjectSchema = shape => Yup.object().shape(shape).nullable();

// const optionalObject = (schema, requiredKeys) => Yup.lazy(
//   value => {
//       const validate = !!(value && requiredKeys.find(key => !!value[key]));
//       return validate ? schema : Yup.mixed().transform(() => undefined);
//     }
// );

const NoteSchema = Yup.object().shape({
  description: LooseStringSchema,
});

export const NotesSchema = Yup.object().shape({
  public  : NoteSchema.nullable(),
  private : NoteSchema.nullable(),
});

export const ImageSchema = Yup.object().shape({
  file_name : RequiredStringSchema,
  type      : RequiredStringSchema,
  url       : RequiredStringSchema,
});

export const DimensionsSchema = Yup.object().shape({
  width  : RequiredStringSchema.trim(),
  height : RequiredStringSchema.trim(),
  depth  : LooseStringSchema.trim(),
});

export const PriceSchema = Yup.object().shape({
  wholesale: RequiredNumberSchema.min(1, 'must be at least ${min}'),
  retail   : RequiredNumberSchema.min(1, 'must be at least ${min}'),
  quantity : RequiredNumberSchema.min(1, 'must be at least ${min}'),
});

export const FramingPriceSchema = Yup.object().shape({
  // Allow creating 0 cost frames in order to create frames with notes
  // but no costs.
  wholesale: NumberSchema.min(0, 'must be at least ${min}'),
  retail   : NumberSchema.min(0, 'must be at least ${min}'),
  quantity : RequiredNumberSchema.min(1, 'must be at least ${min}'),
});

export const ContactSchema = Yup.object().shape({
  name  : RequiredStringSchema,
  email : RequiredStringSchema.email('must be a valid email'),
});

export const VendorSchema = Yup.object().shape({
  name: LooseStringSchema,
  // contact: ContactSchema.required('is required'),
  // website: LooseStringSchema,
});

const BaseArtistSchemaDef = {
  first_name   : RequiredStringSchema,
  last_name    : LooseStringSchema,
  // url          : LooseStringSchema,
  phone_number : StringSchema,
};

export const ArtistContactSchema = Yup.object().shape({
  ...BaseArtistSchemaDef,
  email: LooseStringSchema.email('must be a valid email'),
});

// Because the requirements for new artists are stricter than those
// for existing artists, they get their own schema.
export const LegacyArtistSchema = Yup.object().shape({
  ...BaseArtistSchemaDef,
  id    : RequiredNumberSchema,
  email : LooseStringSchema.email('must be a valid email'),
});

export const FloorplanSchema = Yup.object().shape({
  title: LooseStringSchema.required('is required'),
  notes: NotesSchema,
});

const FramingSchema = Yup.object().shape({
  cost: FramingPriceSchema,
  notes: NotesSchema.nullable(),
});

const ArtworkPage1Shape = {
  // type: Yup.mixed().oneOf(Object.values(ARTWORK_TYPES)).required('is required'),
  notes: NotesSchema.nullable(),
};

const ArtworkPage3Shape = {
  framing: FramingSchema,
};

export const ArtworkSchema = Yup.object().shape(ArtworkPage1Shape);
export const ArtworkPage3Schema = Yup.object().shape(ArtworkPage3Shape);

export const ArtworkDetailsSchema = Yup.object().shape({
  title      : RequiredStringSchema.trim(),
  dimensions : DimensionsSchema.required('is required'),
  medium     : ArtworkMediumSchema.required('is required'),
});

export const ArtworkPurchasingSchema = Yup.object().shape({
  price: PriceSchema.required('is required'),
  notes: OptionalObjectSchema({
    supplier: LooseStringSchema,
  }),
  sold: LooseBooleanSchema,
});

export const CostSchema = Yup.object().shape({
  type        : RequiredStringSchema,
  retail      : RequiredNumberSchema.positive('must be more than 0'),
  wholesale   : RequiredNumberSchema.positive('must be more than 0'),
  description : LooseStringSchema,
  note        : LooseStringSchema,
});

export const CollectionSchema = Yup.object().shape({
  title       : RequiredStringSchema,
  description : LooseStringSchema,
  notes       : NotesSchema,
  budget      : RequiredNumberSchema.min(1, 'must be at least ${min}'),
});
/* eslint-enable no-template-curly-in-string */
