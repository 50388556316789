
export const env = {
  // The environment we are currently running in.
  environment: process.env.REACT_APP_ENV,

  // Specific environment flags...
  production: process.env.REACT_APP_ENV === 'production',
  development: process.env.REACT_APP_ENV === 'development',
  test: process.env.REACT_APP_ENV === 'test',

  //// APP CONFIG
  // Whether or not to perform verbose logging.
  get verbose() {
    return process.env.REACT_APP_VERBOSE === 'true' &&
      window.location.search.indexOf('verbose=false') < 0;
  },

  // Whether or not to run against mock APIs.
  mock: process.env.REACT_APP_MOCK_APIS === 'true',
  // The name of the app.
  appName: process.env.REACT_APP_NAME,

  //// API ENDPOINTS
  // The URL of our Elastic Search API.
  searchAPI: process.env.REACT_APP_ELASTICSEARCH_URL,
  // The URL of our graph API.
  graphAPI: process.env.REACT_APP_DOTFOLIO_GRAPH_API,

  //// SEARCH INDEXES
  artworkSearchIndex: process.env.REACT_APP_SEARCH_INDEX_ARTWORK,
  artistSearchIndex: process.env.REACT_APP_SEARCH_INDEX_ARTIST,

  log() {
    console.log('[ENV] environment:', env.environment);
    if (!env.production && env.verbose) {
      for (let key in env) {
        const blacklist = ['log', 'environment', 'graphAPIKey'];
        if (blacklist.indexOf(key) === -1) {
          console.log(`[ENV] ${key}:`, env[key]);
        }
      }
    }
  },
};
