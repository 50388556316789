import React from 'react';

import {
  useIsMounted,
} from '~/util';

/**
 * Check to see if Formik has finished it's initialization.
 * This can be useful to verify that Formik is ready before
 * trying to change it's state (such as setting a field value).
 */
export function useIsFormikInitialized() {
  const initialized = React.useRef(false);

  React.useEffect(() => {
    let timeout = setTimeout(() => {
      initialized.current = true;
      timeout = null;
    });
    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, []);

  return () => initialized.current;
}

/**
 * Once Formik is fully initialized, call the provided callback.
 * If Formik is ready immediately, then the callback is called
 * synchronously. Otherwise, it is delayed until Formik is ready
 * (one tick).
 */
export function useCallWhenFormikReady() {
  const isInitialized = useIsFormikInitialized();
  const isMounted = useIsMounted();

  return (cb) => {
    if (cb) {
      if (!isInitialized()) {
        setTimeout(() => {
          if (isMounted()) {
            cb();
          }
        });
      } else {
        cb();
      }
    }
  }
}

/**
 * Create an AutoSaveForm change handler that will emit
 * an onReady callback after Formik is initialized and
 * the form's initial values have been emitted. The returned
 * change handler will also emit the standard onChange events.
 * You can also use this hook to supress the initial onChange
 * event if you don't care about the initial form state.
 * Both callbacks are optional so you can choose which events
 * you're interested in.
 *
 * @param {function} [onChange] - Callback when values change.
 * @param {function} [onReady] - Callback when Formik is initialized
 *   and the initial form state has been emitted.
 * @param {boolean} [supressInitialChange] - Use this to supress
 *   the initial `onChange` event.
 */
export function useAutoSaveFormInitialized(onChange, onReady, supressInitialChange = false) {
  const whenReady = useCallWhenFormikReady();

  return values => {
    if (!values.dirty) {
      if (!supressInitialChange && onChange) {
        onChange(values);
      }

      // Call this after the first onChange emission
      // because those initial values may be used to
      // set parent component state before they can
      // be considered fully "ready".
      if (onReady) whenReady(() => onReady(values));
    } else {
      if (onChange) onChange(values);
    }
  };
}

