import React from 'react';

import styles from './PageLayout.module.scss';

/**
 * This wrapper component ensures that new pages fill the screen height
 * and have any additional setup that is global to all pages.
 */
export function PageLayout({
  children,
  ...rest
}) {
  return (
    <div className={styles.PageLayout} {...rest}>
      {children}
    </div>
  );
}
