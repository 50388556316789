import React from 'react';

import {
  useCollectionGraphAPI,
} from '../graph-api';

export function useMoveArtwork() {
  const graph = useCollectionGraphAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(
    graph.updateArtworkCoordinates.bind(graph),
    [graph]
  );
}
