import React from 'react';

import {
  useCollectionGraphAPI,
} from '../graph-api';
import {
  useImageUpload,
} from './useImageUpload';

export function doUpdateArtwork(
  graph,
  uploadImages,
  collectionId,
  oldArtwork,
  newArtwork,
  setUploadCount,
) {
  return new Promise((resolve, reject) => {
    // TODO We should receive the list of new images rather than trying
    // to determine it.
    const newImages = newArtwork.images.filter(image => !image.uploaded);

    // After uploading all images, save the artwork to the Dotfolio backend.
    const saveArtwork = (succeeded) => {
      // Ensure the newArtwork has the same id as the old Artwork.
      newArtwork.id = oldArtwork.id;

      // Save the artwork details to the server.
      graph.updateArtwork(
        collectionId,
        oldArtwork,
        {
          ...newArtwork,
          // Filter the images to only those with upload data.
          // TODO Once we break the save into smaller pieces,
          // we should not pass back the images on this object
          // at all and should instead pass addedImages and
          // removedImages params.
          images: newArtwork.images
            .filter(i => i.uploaded)
            .map(image => image.upload)
        },
        succeeded
      )
        .then(resolve)
        .catch(reject);
    };

    if (newImages && newImages.length) {
      uploadImages(newImages, 'artwork', setUploadCount)
        .then(succeeded => saveArtwork(succeeded))
        .catch(reject);
    } else {
      // Proceed without any images.
      saveArtwork([]);
    }
  });
}

/**
 * Returns a callback that can be used to update an artwork
 * including removing images, uploading new images and updating
 * the artwork metadata.
 *
 * @callback setUploadCount - A callback that can be used to
 *   show how many images have been uploaded so far.
 * @param {number} count
 *
 * @callback updateArtwork - A callback that can be used to
 *   update the data about an artwork.
 * @param {number} collectionId
 * @param {ArtworkPropType} oldArtwork - The pre-update state of the artwork.
 * @param {ArtworkPropType} newArtowkr - The updated artwork data.
 * @param {setUploadCount} setUploadCount
 */
export function useUpdateArtwork() {
  const uploadImages = useImageUpload();
  const graph = useCollectionGraphAPI();

  return React.useCallback(
    (...args) => doUpdateArtwork(graph, uploadImages, ...args),
    [graph, uploadImages]
  );
}
