import {
  fromCollectionGraph,
} from '../graph-api';

export const fromSearch = {
  artwork(artwork, id, score) {
    const a = fromCollectionGraph.artwork({
      ...artwork,
      // ES might store artists as a list.
      artist: Array.isArray(artwork.artist) ? artwork.artist[0] : artwork.artist,
    });
    // Artwork ids are returned from ES as strings
    // For artworks, the document number is the artwork id.
    a.id = Number(id);
    // Append the images directly because the `fromCollectionGraph.artwork`
    // expects images to be nested under an `uploads` key.
    a.images = artwork.images.slice();
    // Costs are stored in the same format as the legacy database.
    a.price = {
      retail: artwork.retail_price_cents,
      retailCurrency: artwork.retail_currency,
      wholesale: artwork.wholesale_price_cents,
      wholesaleCurrency: artwork.wholesale_currency,
      quantity: 1,
      note: null,
      description: null,
    };
    // For debugging, we'll include the score.
    a.score = score;
    return a;
  },

  artist(artist, id, score) {
    return {
      ...fromCollectionGraph.artist(artist),
      id: Number(artist.id),
      score,
    };
  },

  artistSearch(response, limit, nextOffset, term) {
    return {
      results: response.hits.hits.map(
        item => fromSearch.artist(item._source, item._id, item._score)
      ),
      limit: limit,
      offset: nextOffset,
      total: response.hits.total.value,
      searchTerm: term,
    }
  },

  artworkSearch(response, limit, nextOffset, term) {
    return {
      results: response.hits.hits.map(
        item => fromSearch.artwork(item._source, item._id, item._score)
      ),
      limit: limit,
      offset: nextOffset,
      total: response.hits.total.value,
      searchTerm: term,
    }
  }
}
