import React from 'react';
import PropTypes from 'prop-types';
import {
  ToggleLayer,
} from 'react-laag';
import ResizeObserver from "resize-observer-polyfill";
import useKey from 'react-use/lib/useKey';

import {
  combineClasses,
} from '~/util';

import styles from './Popover.module.scss';

let count = 0;


/**
 * A generic component for creating pop over content like
 * dropdowns and tooltips. It takes a trigger element as
 * it's children and a menu prop which specifies what to
 * render when it is open.
 *
 * For accessibility purposes, it will attach unique id values
 * to the trigger and menu. You can override these if necessary
 * by specifying your own but it's important that each one
 * has a unique id and that you account for accisibility rules
 * for the aria design pattern you are implementing.
 */
export function Popover({
  isOpen,
  setIsOpen = () => {},
  menu,
  children,
  placement,
  // animationProps,
  disableAnimations,
  role = 'menu',
  onClick,
  className,
  ...rest
}) {
  const [id] = React.useState(() => `collectionbuilder-dropdown-${++count}`);
  const triggerId = `${id}-trigger`;
  const menuId = `${id}-menu`;

  // Allow either controlled or uncontrolled state.
  const [localIsOpen, setLocalIsOpen] = React.useState(false);
  const open = isOpen === undefined ? localIsOpen : isOpen;
  const setOpen = isOpen === undefined ? setLocalIsOpen : setIsOpen;

  const closeMenu = () => {
    if (open) setOpen(false);
  };

  useKey('Escape', closeMenu, {}, [open]);

  return (
    <div className={combineClasses(styles.Popover, className)} {...rest}>
      <ToggleLayer
        isOpen={open}
        ResizeObserver={ResizeObserver}
        onOutsideClick={closeMenu}
        placement={{
          autoAdjust: true,
          anchor: "BOTTOM_CENTER",
          triggerOffset: 10,
          ...placement
        }}
        renderLayer={({layerProps, ...rest}) => menu({
          disableAnimations,
          layerProps: {
            role,
            id: menuId,
            'aria-labelledby': triggerId,
            ...layerProps,
          },
          ...rest,
        })}
      >
        {({isOpen, ...layerProps}) => {
          const toggle = () => setOpen(o => !o);
          const open = () => setOpen(true);
          const close = () => setOpen(false);

          const triggerProps = {
            id: triggerId,
            'aria-controls': menuId,
            'aria-expanded': isOpen ? 'true' : 'false',
            role: 'button',
            onClick: e => {
              toggle();
              if (onClick) onClick(e);
            },
            tabIndex: 0,
          };

          return children({
            ...layerProps,
            isOpen,
            triggerProps,
            toggle,
            open,
            close
          });
        }}
      </ToggleLayer>
    </div>
  );
}

Popover.propTypes = {
  /**
   * Whether the popover is currently open.
   * If you don't pass this, then the Popover will control
   * it's own state. If you do pass it, then you should also
   * pass the `setIsOpen` prop if you want the Popover to
   * close on Escape or click outside events.
   */
  isOpen: PropTypes.bool,
  /**
   * A function to set the open state of the popover if
   * you need to control that state yourself.
   * This will be called when the escape key is pressed
   * or when a click event occurs outside of the Popover.
   * If you are controlling the state and don't pass this
   * callback, then the Popover will never attempt to close
   * itself.
   */
  setIsOpen: PropTypes.func,
  /**
   * The aria role of the trigger element.
   */
  role: PropTypes.string,
  /**
   * A function that should return the menu content to render
   * when the popover is open. It receives the following props:
   * @param {object} props
   * @param {boolean} props.isOpen - Whether the menu is open or closed
   * @param {function} props.open - A function to open the menu
   * @param {function} props.close - A function to close the menu
   * @param {function} props.toggle - A function to toggle the menu open/closed state
   * @param {object} props.layerProps - Props that should be attached to the menu container element
   *   in order for it to position itself correctly.
   * @param {object} props.triggerRect - The position and size of the trigger button element
   *   should you need to use it to in the menu styling.
   * @param {object} props.arrowStyle - The style property to apply to the arrow element
   *   if you want to render an arrow. `react-laag` exposes a useful `<Arrow>` component
   *   if you don't want to generate your own.
   * @param {string} props.layerSide - The side on which the arrow will be placed if you
   *   are using an arrow.
   * @param {boolean} props.disableAnimations - Indicates whether animations should
   *   be disabled.
   */
  menu: PropTypes.func.isRequired,
  /**
   * A function that will return the menu trigger element. It receives
   * the following properties:
   * @param {object} options
   * @param {object} options.triggerRef - A ref you should attach to the trigger element returned.
   * @param {function} options.toggle - A function you can call to toggle the menu open/closed.
   * @param {function} options.open - A function to open the menu.
   * @param {function} options.close - A function to close the menu.
   * @param {boolean} options.isOpen - Whether the menu is currently open.
   * @param {object} options.triggerProps - Props that need to be attached to the trigger element
   *   for the menut to function as expected.
   * @param {string} options.triggerProps.id - The unique id of the menu element.
   * @param {string} options.triggerProps.aria-controls - Indicates the trigger is an HTML control element.
   * @param {string} options.triggerProps.aria-expanded - The expanded state of the menu.
   * @param {string} options.triggerProps.role - Indicates the trigger is a button.
   * @param {string} options.triggerProps.className - Adds the dropdown arrow styling by setting a background-image
   *   in an after content of your trigger.
   * @param {function} options.triggerProps.onFocus - A focus event handler.
   * @param {function} options.triggerProps.onBlur - A blur event handler.
   * @param {function} options.triggerProps.onClick - A click event handler.
   * @param {number} options.triggerProps.tabIndex - The default tab index value for the trigger.
   */
  children: PropTypes.func.isRequired,
  /**
   * Where to place the menu as described by
   * [react-laag](https://www.react-laag.com/docs/togglelayer/)
   */
  placement: PropTypes.object,
  /**
   * Pass this prop to turn off animations.
   */
  disableAnimations: PropTypes.bool,
  /**
   * Any props that can be passed to react-spring Transition.
   * https://www.react-spring.io/docs/props/transition
   */
  animationProps: PropTypes.object,
};


