import React from 'react';
import PropTypes from 'prop-types';

import {
  TinyTitle,
} from '../titles';
import {
  combineClasses,
} from '~/util';

import './TitleValuePair.scss';

/*
 * Represents a title and value that are
 * arranged in a column. This is used
 * in places like Cards to display
 * budget values or due dates.
 *
 * @prop {string} title The title to display above the value.
 * @prop {string} value The value to display.
 * @prop {string} className Additional classes to add to the
 *   root element.
 */
export default function TitleValuePair({
  title,
  value,
  children,
  className,
  ...rest
}) {
  return (
    <div {...rest}
      className={combineClasses('title-value-pair', className)}
    >
      <TinyTitle className="title" data-test="title">{ title }</TinyTitle>
      <div className="value" data-test="value">{ value ? value : children }</div>
    </div>
  );
}

TitleValuePair.propTypes = {
  /**
   * The display label describing the data value.
   */
  title: PropTypes.string.isRequired,
  /**
   * The data you need to display.
   */
  value: PropTypes.node,
  /**
   * You can pass the value as children if you prefer.
   */
  children: PropTypes.node,
};

