
import {get as wordDistance} from 'fast-levenshtein';

const normalize = v => !v ? '' : v.toLowerCase().trim();
const match = (a, b) => normalize(a) === normalize(b);

/**
 * Generate a similarity score for the similarity between two artists.
 *
 * If `comparee` does not have an email adress
 * @param {ArtistPropType} comparee - The artist to compare to an existing artist.
 * @param {ArtistPropType} archetype - The artist to use as the archetypical artist that you
 *   want to check for similarity to.
 * @return {number} A score between 0 - 3. 3 = Artist definitely exists.
 */
export function artistSimilarity(comparee, archetype) {
  if (
    // Legacy artists may not have an email address so
    // only validate the email if both artists have an email address.
    (comparee.email && archetype.email) &&
    match(comparee.email, archetype.email)
  ) {
    return 3;
  }
  else {
    return ['first_name', 'last_name'].reduce((acc, key) => {
      const a = normalize(comparee[key]);
      const b = normalize(archetype[key]);
      if (match(a, b)) ++acc;
      // Handle typos using levenshtein distance
      else {
        const distance = wordDistance(a, b);
        if (distance === 1) acc += 0.75;
        else if (distance === 2) acc += 0.5;
      }
      return acc;
    }, 0);
  }
}

/**
 * @typedef SimilarArtists
 * @property {ArtistPropType[]} similar - Full list of similar artists.
 * @property {ArtistPropType[]} errors - List of artists that are too similar.
 */
/**
 * Check for similar artists using the search callback provided.
 * @param {ArtistPropType} values - The artist data to check.
 * @param {function} onSearch - The search api callback for searching artists.
 * @return {Promise<SimilarArtists>}
 */
export function checkForSimilarArtists(values, onSearch) {
  return new Promise((resolve, reject) => {
    // Generate a Lucen `query_string` query so we can specify the
    // specific fields we're matching against.
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/query-dsl-query-string-query.html#query-string-syntax
    //
    // TEST Scenarios:
    // first_name:Marc email:markbueno84@gmail.com
    // first_name:M email:markbueno84@gmail.com
    // first_name:Marc last_name:Wen
    // first_name:Marc last_name:Bueno
    // first_name:Marc last_name:Beuno
    // first_name:Mark email:markbueno84@hotmail.com
    // first_name:Marc email:markbueno84@hotmail.com
    const query = ['first_name^2~', 'last_name^3~', 'email^4']
      .map(key => {
        const parts = key.split('^');
        const field = parts[0];
        const boost = parts.length > 0 ? parts[1] : 1;
        const value = values[field];

        return values[field]
          ? `${field}:${value}^${boost}`
          : null;
      })
      .filter(f => !!f)
      .join(' ');

    if (query) {
      onSearch(query, 10).then(artists => {
        let errors = [];

        const similar = artists.map(artist => {
          let similarity = artistSimilarity(values, artist);

          if (similarity >= 3) errors.push(artist);

          return {artist, similarity};
        }).sort((a, b) => b.similarity - a.similarity);

        if (errors.length > 0) {
          reject({
            similar,
            errors,
          });
        } else {
          resolve({similar});
        }
      });
    }
    else {
      resolve({similar: []});
    }
  });
}
