import queryString from 'query-string';
import { matchPath } from "react-router-dom";

/*
 * A Map of route names to `Route` objects.
 *
 * @typedef {Object} Route
 * @property {string} path - The path with replacement variables.
 *
 * @const {Map} Routes
 */
// IMPORTANT: These routes need to be inserted in order of
// specificity (lease specific to most specific);
export const Routes = new Map([
  [ 'HOME'                      , {path: '/'}],
  [ 'LOGIN'                     , {path: '/login'}],
  [ 'COLLECTIONS'               , {path: '/collections'}],
  [ 'ADD_COLLECTION'            , {path: '/collections/new'}],
  [ 'COLLECTION'                , {path: '/collections/:collectionId'}],
  [ 'COLLECTION_PRINT'          , {path: '/collections/:collectionId/print'}],
  // [ 'EDIT_COLLECTION'        , {path: '/collections/:collectionId/edit'}],
  [ 'ADD_ARTWORK'               , {path: '/collections/:collectionId/artworks/new'}],
  [ 'ADD_ARTWORK_PAGE'          , {path: '/collections/:collectionId/artworks/new/:page'}],
  // [ 'ARTWORK'                , {path: '/collections/:collectionId/artworks/:artworkId'}],
  [ 'EDIT_ARTWORK'              , {path: '/collections/:collectionId/artworks/:artworkId/edit'}],
  [ 'ADD_FLOORPLAN'             , {path: '/collections/:collectionId/floorplans/new'}],
  [ 'FLOORPLAN'                 , {path: '/collections/:collectionId/floorplans/:floorplanId'}],
  // TODO Rename
  [ 'COLLECTION_ARTWORK_SEARCH' , {path: '/collections/:collectionId/floorplans/:floorplanId/artworks/search'}],
  [ 'FLOORPLAN_ADD_ARTWORK'     , {path: '/collections/:collectionId/floorplans/:floorplanId/artworks/new'}],
  [ 'FLOORPLAN_ADD_ARTWORK_PAGE', {path: '/collections/:collectionId/floorplans/:floorplanId/artworks/new/:page'}],
]);


/**
 * Find the first route that matches the provided location object.
 * @param {window.Location}
 */
export function findRouteMatching(location) {
  const values = Array.from(Routes.values()).reverse();
  for (let route of values) {
    const match = matchPath(location, {path: route.path, exact: false, strict: false});
    if (match) {
      const params = {};
      for (let key in match.params) {
        params[key] = getRouteParam(key, match);
      }

      return {
        ...match,
        params
      }
    }
  }
  return null;
}

/*
 * Get the URL for a specific route with path parameters
 * replaced.
 *
 * @param {string} name - The name of the route (key) in the Routes map.
 * @param {Object} [urlParams] - An object with the url parameters to replace.
 *   Each key in the parameter should match a variable token in the route
 *   (without the leading ':'). If parameters is not passed, the raw
 *   path is returned.
 *
 *   Ex: {projectId: 123} will replace the project id token in the route
 *   `/project/:projectId/new` with the value 123.
 * @param {Object} [queryParams] - An object of key/value pairs to use as
 *   query parameters on the URL.
 *
 * @return {string} The url that can be used to route to a specific page.
 */
export function getRoute(name, urlParams, queryParams) {
  const route = Routes.get(name);
  if (!route) {
    console.error('Could not find route called', name);
    return '#';
  }

  let r = route.path;
  if (urlParams) {
    Object.keys(urlParams).forEach((key) => r = r.replace(`:${key}`, urlParams[key]));
  }

  if (queryParams) {
    r += `?${queryString.stringify(queryParams, {skipNull: true, skipEmptyString: true})}`;
  }

  return r;
}

/*
 * Get a parameter from the react-router `match`
 * object, doing any necessary casting.
 */
export function getRouteParam(name, match) {
  // Allow passing either the `match` object or
  // the `match.params` object.
  const params = match.params ? match.params : match;

  if (/Id$/.test(name)) {
    return Number(params[name]);
  }

  return params[name];
}

/**
 * Get a query parameter from the current search string
 * (or location.search)
 * @param {string} name - The parameter name.
 * @param {string|object} search - The search string or an object
 *   with a "search" key (ie. window.location).
 * @return {string}
 */
export function getQueryParam(name, search = window.location) {
  search = typeof(search) === 'object' ? search.search : search;
  // TODO Verify this change
  const params = queryString.parse(search, {parseNumbers: true, parseBooleans: true});

  switch(name) {
    default:
      return params[name];
  }
}

