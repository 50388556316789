import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import {
  combineClasses,
} from '~/util';

import {
  ZoomInMenu,
  StaticMenu,
} from '../menu-styles';
import {
  Popover,
} from '../Popover.jsx';

import styles from './Dropdown.module.scss';

/**
 * A wrapper around the Popover trigger that attaches
 * a down arrow and shows a disabled state when the
 * Popover is open.
 */
export function Trigger({
  isOpen,
  children,
}) {
  return (
    <div data-testid="triggerWrapper"
      className={combineClasses(
        styles.triggerWrapper,
        isOpen ? styles.open : null,
      )}
      children={children}
    />
  );
}

/**
 * A popover that can have any content and traps focus while it is open.
 * This is good for dropdowns of arbitrary content that should focus
 * the user's interactions while it is open.
 */
export function Dropdown({
  menu,
  animationProps,
  disableAnimations,
  children,
  ...rest
}) {
  // The menu wrapper to use (animated or non-animated).
  const renderMenu = (content, props) => disableAnimations
    ? <StaticMenu children={content} {...props} disableAnimations />
    : <ZoomInMenu children={content} {...props} animationProps={animationProps} />

  return (
    <Popover
      disableAnimations={disableAnimations}
      menu={props => renderMenu(
        <FocusLock returnFocus autoFocus={false}>{ menu }</FocusLock>,
        props
      )}
      role="menu"
      {...rest}
    >
      {({isOpen, ...rest}) =>
        <Trigger isOpen={isOpen}>
          { children({isOpen, ...rest}) }
        </Trigger>
      }
    </Popover>
  );
}

Dropdown.propTypes = {
  /**
   * Whether the popover is currently open.
   * If you don't pass this, then the Popover will control
   * it's own state. If you do pass it, then you should also
   * pass the `setIsOpen` prop if you want the Popover to
   * close on Escape or click outside events.
   */
  isOpen: PropTypes.bool,
  /**
   * A function to set the open state of the popover if
   * you need to control that state yourself.
   * This will be called when the escape key is pressed
   * or when a click event occurs outside of the Popover.
   * If you are controlling the state and don't pass this
   * callback, then the Popover will never attempt to close
   * itself.
   */
  setIsOpen: PropTypes.func,
  /**
   * The menu content to render in the dropdown when it is open.
   */
  menu: PropTypes.node.isRequired,
  /**
   * A function that will return the menu trigger element. It receives
   * the following properties:
   * @param {object} options
   * @param {object} options.triggerRef - A ref you should attach to the trigger element returned.
   * @param {function} options.toggle - A function you can call to toggle the menu open/closed.
   * @param {function} options.open - A function to open the menu.
   * @param {function} options.close - A function to close the menu.
   * @param {boolean} options.isOpen - Whether the menu is currently open.
   * @param {object} options.triggerProps - Props that need to be attached to the trigger element
   *   for the menut to function as expected.
   * @param {string} options.triggerProps.id - The unique id of the menu element.
   * @param {string} options.triggerProps.aria-controls - Indicates the trigger is an HTML control element.
   * @param {string} options.triggerProps.aria-expanded - The expanded state of the menu.
   * @param {string} options.triggerProps.role - Indicates the trigger is a button.
   * @param {string} options.triggerProps.className - Adds the dropdown arrow styling by setting a background-image
   *   in an after content of your trigger.
   * @param {function} options.triggerProps.onFocus - A focus event handler.
   * @param {function} options.triggerProps.onBlur - A blur event handler.
   * @param {function} options.triggerProps.onClick - A click event handler.
   * @param {number} options.triggerProps.tabIndex - The default tab index value for the trigger.
   */
  children: PropTypes.func.isRequired,
  /**
   * By default the menu placement is determined for you
   * but you can use this to place the menu yourself as described by
   * [react-laag](https://www.react-laag.com/docs/togglelayer/)
   */
  placement: PropTypes.object,
  /**
   * Pass this prop to turn off animations.
   */
  disableAnimations: PropTypes.bool,
  /**
   * Use this to customize the animation. It can include
   * any props that can be passed to react-spring Transition.
   * https://www.react-spring.io/docs/props/transition
   */
  animationProps: PropTypes.object,
}

