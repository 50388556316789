import React from 'react';
import { window } from 'global';
import ColorThief from 'colorthief';
import debounce from 'awesome-debounce-promise';
import { cancelable } from 'cancelable-promise';

import { rgbToHex } from '@thesoulfresh/utils';

export function scrollToTop(element = window, smooth = false) {
  element.scrollTo({top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'});
}

/*
 * Scroll window to the top of the page on component load.
 */
export function useScrollToTop(smooth) {
  React.useEffect(() => {
    scrollToTop(window, smooth);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

// Global counter used to create unique ids.
let idCount = 0;

export function useId(prefix) {
  const [id] = React.useState(() => `${prefix}-${++idCount}`);
  return id;
}

/**
 * Determine if a component is currently mounted.
 * Use this in instances where you need to ensure that async
 * actions only happen while a component is mounted and
 * you are unable to cancel those async actions on unount.
 * It is preferred to use `useDebouncedCallback` if you can
 * because it will cancel callbacks for you on component
 * unmount.
 */
export function useIsMounted() {
  const state = React.useRef(true);

  React.useEffect(() => {
    return () => state.current = false;
  }, []);

  return React.useCallback(() => state.current, []);
}


/**
 * Create a constant cancellable debounced promise returning callback.
 *
 * 1. Only the last invocation of the debounced callback will be resolved.
 * For example, if you debounce an API request, only the last response
 * will be returned, ensuring you don't receive responses out of order.
 *
 * 2. The returned promise has a `cancel` method you can use to cancel the promise.
 * Additionally, the promise will be cancelled for you when your
 * component is unmounted.
 *
 * 3. The callback is constant so you will always get back the same function
 * object.
 *
 * 4. If you pass `null` as the timeout,
 * the callback will be called directly so you can avoid debouncing
 * during testing.
 *
 * Example usage:
 * const debounced = useDebouncedCallback(callback, timeout);
 * const onClick = () => debounced('foo');
 */
export function useDebouncedCallback(callback, timeout = 500) {
  const promised = React.useRef();
  const [debounced] = React.useState(() => debounce(callback, timeout));

  const [out] = React.useState(() => {
    return (...args) => {
      // promised.current = cancelable(debounced(...args));
      promised.current = cancelable(debounced(...args)).then(result => {
        promised.current = null;
        return result;
      });
      return promised.current;
    };
  });

  React.useEffect(() => {
    // Cancel any running promises when the component unmounts.
    return () => {
      if (promised.current && promised.current.cancel) {
        promised.current.cancel();
      }
    }
  }, []);

  // If timeout is null, don't do any debouncing.
  // This is useful for turning off debouncing during tests.
  return timeout != null ? out : callback;
}


/**
 * Returns a wrapper around `setTimeout` that will
 * automatically clear the timeout if the component
 * is destroyed before the timeout.
 */
export function useTimeout() {
  const idRef = React.useRef();

  React.useEffect(() => {
    return () => {
      // When the component is destroyed,
      // if the timeout was created, then clear it.
      if (idRef.current) {
        clearTimeout(idRef.current);
      }
    };
  }, []);

  return (cb, delay, ...rest) => {
    return idRef.current = setTimeout(() => {
      idRef.current = null;
      cb(...rest);
    }, delay);
  };
}


/**
 * @callback SampleCallback
 * @param {string} src - The URL or base64 data for the image to sample.
 * @return {Promise<string>} - The sampled color or fallback color.
 */

/**
 * Get a callback that will sample the color of an image.
 *
 * @param {string} [defaultColor] - The fallback color to use if
 *   sampling fails.
 * @return {SampleCallback}
 */
export function useSampleColor(defaultColor = '#000000', colorThief = undefined) {
  return src => new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.addEventListener('load', () => {
      try {
        const thief = colorThief || new ColorThief();
        const color = thief.getColor(image);
        resolve(rgbToHex(...color));
      } catch(error) {
        console.warn('Failed to sample color:', error.message);
        resolve(defaultColor);
      }
    });

    image.addEventListener('error', err => {
      console.warn('Failed to load image while sampling color of', src);
      resolve(defaultColor);
    });

    image.src = src;
  });
}
