import React from 'react';

import {
  combineClasses,
} from '~/util';

import styles from './Row.module.scss';

export function Row({
  children,
  className,
  ...rest
}) {
  return (
    <div className={combineClasses(styles.Row, className)} {...rest}>
      { children }
    </div>
  );
}
