import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-spring/renderprops';

import {
  Title,
} from '../../titles';
import {
  combineClasses,
  springs,
  useAcceptImages,
} from '~/util';
import {
  ImageTransport,
} from '~/model';

import { ReactComponent as ImagePlaceholder } from '~/assets/icons/image-placeholder-filled.svg';

import 'toasted-notes/src/styles.css';
import './ImageUpload.scss';


function Overlay(props) {
  return (
    <div className="overlay" data-test="uploadOverlay" {...props}>
      <ImagePlaceholder className="icon" />
      <Title>Drop Your Image Here</Title>
    </div>
  );
}

function AnimatedOverlay({
  visible,
  animated,
}) {
  // Because Transition relies on transitionend (which only
  // works if the browser has focus), skip rendering the
  // Transition if animations are turned off.
  if (!animated && visible) {
    return <Overlay />;
  } else {
    return (
      <Transition
        immediate={!animated}
        unique={true}
        items={visible}
        config={springs.moderate}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {visible => visible && (props => <Overlay style={props} />)}
      </Transition>
    );
  }
}

/**
 * @param {object} props
 * @param {ImageTransport[]} [props.images]
 * @param {boolean} [props.allowMultiple]
 * @param {function} [props.onAddImages]
 * @param {function} [props.onError]
 * @param {function} [props.children]
 * @param {string} [props.className]
 * @param {boolean} [props.animated]
 */
export function ImageUpload({
  images,
  allowMultiple = true,
  onAddImages,
  children,
  className,
  animated = true,
  onError,
  ...rest
}) {
  const {isOver, ref: drop, addImages} = useAcceptImages({
    onSuccess: onAddImages,
    onError,
    max: !allowMultiple ? 1 : undefined,
    existing: images,
  });

  const childProps = {
    ...rest,
    images,
    uploadImages: addImages,
  };

  return (
    <div
      data-test="imageUpload"
      className={combineClasses('image-upload', className)}
      {...rest}
      ref={drop}
    >
      <AnimatedOverlay visible={isOver} />
      { children(childProps) }
    </div>
  );
}

ImageUpload.propTypes = {
  images: PropTypes.arrayOf(PropTypes.instanceOf(ImageTransport)),
  allowMultiple: PropTypes.bool,
  onAddImages: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  animated: PropTypes.bool,
};

