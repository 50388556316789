import { Set } from 'global';
import { getMedium } from '~/model';

/**
 * Determine the user's locale.
 */
export function userLocale() {
  return navigator.userLanguage || navigator.language || 'en-US';
}

/*
 * Format a date string for display.
 *
 * @param {string} date The date string to parse and format.
 * @param {string} [locale] This should only be passed during testing
 *   unless you have a really good reason to specify the locale.
 */
export function formatDate(date, locale = userLocale()) {
  if (date) {
    if (!(date instanceof Date)) date = new Date(date);

    return new Intl.DateTimeFormat(locale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      }).format(date);
  } else {
    return '';
  }
}

/*
 * Combine two class strings, removing duplicates and handling
 * empty, null or undefined strings.
 */
export function combineClasses(...classes) {
  const list = classes.reduce(
    (acc, arg) => {
      if (typeof arg === 'string') {
        const c = arg.trim().split(' ');
        if (c.length > 0) acc = acc.concat(c);
      }
      return acc;
    }
    , []
  );

  if (list.length > 0) {
    return [...new Set(list)].join(' ').trim();
  }
}

/*
 * Convert a data url into a Blob.
 * Taken from: https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
 */
// TODO Replace usage with @thesoulfresh/utils
export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], {type: mimeString});
}

/**
 * Format a number to the current local using decimal notation.
 */
export function formatNumber(value, locale = userLocale()) {
  if (value == null) return '';
  return new Intl.NumberFormat(locale, {
      style: 'decimal',
  }).format(value);
}

/*
 * Format a currency value for display rounded to the nearest dollar.
 *
 * @param {number} pennies - The value in pennies to parse into a currency.
 * @param {string} currency - The currency to use (ex. USD, GBP).
 * @param {string} [locale] - This should only be passed during testing
 *   unless you have a really good reason to specify the locale.
 */
export function formatCurrency(pennies, currency='USD', locale = userLocale(), cents=0) {
  if (pennies == null) return '';
  return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: cents,
      minimumFractionDigits: cents,
  }).format(pennies / 100);
}

/**
 * Similar to formatCurrency but without rounding to dollars.
 */
export function formatCurrencyExact(pennies, currency, local) {
  return formatCurrency(pennies, currency, local, 2);
}

/**
 * Get the name of a medium given it's value in the database.
 * Because the list of mediums in v3 and v2 are different,
 * this also converts v2 mediums into v3.
 */
export function formatMedium(name) {
  const medium = getMedium(name);
  return medium ? medium.name : name;
}

/**
 * Convert a `plain/text` string into a list
 * of urls. Multiple urls can be converted if
 * they are separated by new lines.
 *
 * @param {string} data
 * @return {string[]}
 */
export function textToURLs(data) {
  const urls = data
    // Allow passing a URL per line
    .split('\n')
    .map(url => url.trim())
    .filter(url => !!url)
    // Filter any files that start with # as those are intended as comments
    // according to the text/uri-list spec.
    .filter(url => !url.startsWith('#'))
    // Filter any lines that don't start with http
    // .filter(url => url.startsWith('http'));
  ;

  // Use a Set to guarantee uniquness.
  return Array.from(new Set(urls));
}


/**
 * Normalize anything object into an array.
 * If it's already an array, keep it as is.
 * Strings are treated as a single item (not characters).
 * Iterable objects are converted to real arrays.
 * @param {*} input - The thing to normalize into an array.
 * @param {boolean} coerceNulls - Whether or not null/undefined
 *   should be kept as null/undefined (false) or converted to an empty
 *   array (true).
 * @return {*[]|null|undefined} Returns an array unless
 *  `coerceNulls` is true and the input is null or undefined.
 */
export function normalizeToList(input, coerceNulls = false) {
  return input == null && !coerceNulls
    ? input
    // Convert nulls to an empty array
    : input == null && coerceNulls
    ? []
    // Treat strings as a single item (not as characters)
    : typeof input === 'string'
    ? [input]
    // Don't double nest arrays.
    : Array.isArray(input)
    ? input
    // Anything iterable can be converted to an array
    // with Array.from
    : typeof input[Symbol.iterator] === 'function'
    ? Array.from(input)
    // Assume anything else is a single item to be put
    // in an array.
    : [input];
}
