
/**
 * A mock implementation of the Auth client from Amplify.
 * This client implements both the Auth and Hub interfaces
 * for simplicity.
 */
export class AmplifyAuthClientMock {
  /**
   * @param {boolean} [loggedIn] - Whether the user is logged in by default.
   * @param {boolean} [testFailure] - Whether login requests should fail.
   * @param {object} [user] - The fake user data returned on login.
   */
  constructor(
    loggedIn = true,
    testFailure = false,
    // NOTE It is important that this mock be
    // as light as possible so don't pull in
    // Faker or other large libraries.
    user = {
      email: 'katz@gmail.com',
      name: 'Grumpy Cat',
      given_name: 'Grumpy',
      family_name: 'Cat',
      picture: 'https://i.insider.com/5cdecf14021b4c0e0d0514c5?width=700&format=jpeg&auto=webp',
    },
    role = 'admin',
    roles = [role],
    token = 'abcd',
  ) {
    // Initialize to logged in state.
    this.userTemplate = user;
    this.listeners = [];
    this.testFailure = testFailure;

    this.user = loggedIn ? this.makeUser() : null;
    this.role = role;
    this.roles = roles;
    this.token = token;
  }

  makeUser() {
    return {...this.userTemplate};
  }

  currentAuthenticatedUser() {
    if (this.user) {
      return Promise.resolve({
        attributes: this.user,
        signInUserSession: {
          idToken: {
            jwtToken: this.token,
            payload: {
              'https://hasura.io/jwt/claims': JSON.stringify({
                'x-hasura-default-role': this.role,
                'x-hasura-allowed-roles': this.roles,
              })
            }
          }
        }
      });
    } else {
      return Promise.reject('not authenticated');
    }
  }

  federatedSignIn() {
    if (this.testFailure) {
      return Promise.reject('not authenticated');
    } else {
      if (!this.user) this.user = this.makeUser();
      return this.currentAuthenticatedUser()
        .then(user => {
          this.emit('signIn', user);
          return user;
        });
    }
  }

  signOut() {
    this.user = null;
    this.emit('signOut', null);
    return Promise.resolve(null);
  }

  listen(name, listener) {
    this.listeners.push({name, listener});
  }

  emit(event, data) {
    this.listeners.forEach(l => {
      l.listener({ payload: {event, data}})
    });
  }
};
