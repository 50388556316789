import React from 'react';
import ReactDOM from 'react-dom';
import { useCollectionGraphAPI } from '../graph-api';
import { useUploadUserImage } from './useUploadUserImage';

import {
  useTimeout,
} from '~/util';

export function useAuthenticatedUser(
  authResponse,
  doProfileUpload = true,
  profileUploadDelay = 10000,
) {
  const [loading, setLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const api = useCollectionGraphAPI();
  const uploadProfilePicture = useUploadUserImage();
  const wait = useTimeout();

  const maybeTimeout = (callback, delay) => {
    if (delay > 0) wait(callback, delay);
    else callback();
  }

  React.useEffect(() => {
    if (authResponse && authResponse.attributes && authResponse.attributes.email) {
      const {email, given_name, family_name, picture} = authResponse.attributes;

      api.updateAuthenticatedUser(email, given_name, family_name)
        .then(user => {
          ReactDOM.unstable_batchedUpdates(() => {
            setLoading(false);
            setAuthenticated(true);
            setUser(user);
          });

          // If the authResponse has a user profile picture that is different that
          // what's saved (as the image.file_name), give some time for the app to
          // fully load and then upload the new profile picture.
          if (
            doProfileUpload &&
            picture &&
            (!user.image || !user.image.file_name || user.image.file_name !== picture)
          ) {
            maybeTimeout(
              () => uploadProfilePicture(user.id, picture, 'profile', 'User Profile Picture')
                .then(result => console.debug('User Profile Image Uploaded', result))
                // Ignore errors because we'll get to try again
                // the next time the user signs in.
                .catch(e => console.warn('Image Upload Failures:', e.failed)),
              profileUploadDelay
            );
          }
        })
        .catch(e => {
          ReactDOM.unstable_batchedUpdates(() => {
            setLoading(false);
            setAuthenticated(false);
            setError(e);
          });
        });
    } else {
      setAuthenticated(false);
      setError(null);
      setUser(null);
    }
  }, [authResponse, api]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    authenticated,
    error,
    user
  };
}
