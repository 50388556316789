import React from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd';
import composeRefs from '@seznam/compose-react-refs';

import {
  combineClasses,
  screenToPercent,
  useImagePaste,
  filesToImageTransports,
  urlsToImageTransports,
  preloadImages,
  useIsMounted,
} from '~/util';

import {
  ImageTransportError,
} from '~/model';

import { PinPropType } from '~/model';
import { ZoomableMap } from './ZoomableMap.jsx';

import styles from './Floorplan.module.scss';

export function Floorplan({
  onAddImages,
  onDropError,
  className,
  ...rest
}) {
  const isMounted = useIsMounted();
  const imageRef = React.useRef();

  const handleDropError = e => {
    if (isMounted() && onDropError) onDropError(e);
  }

  const doImagePreloading = (images, x, y) => {
    if (onAddImages) {
      preloadImages(images)
        .then(result => {
          if (isMounted() && onAddImages) onAddImages(result, x, y);
        })
        .catch(handleDropError);
    }
  };

  const handleDrop = (images, x, y, type) => {
    if (images.length && onAddImages) {
      const dimensions = imageRef.current.getBoundingClientRect();
      const percent = screenToPercent(x, y, dimensions);

      doImagePreloading(images, percent.x, percent.y);
    } else {
      handleDropError(new ImageTransportError(
        {
          type,
          action: ImageTransportError.DROP,
          reason: ImageTransportError.EMPTY,
        },
        'No images or URLs were detected in the items you dropped.'
      ));
    }
  };

  useImagePaste(
    images => doImagePreloading(images, 0.5, 0.5),
    handleDropError
  );

  const [{droppable, dragging}, dropRef] = useDrop({
    accept: [NativeTypes.FILE, NativeTypes.URL, NativeTypes.TEXT],
    drop: (item, monitor) => {
      const {x, y} = monitor.getClientOffset();
      const type = monitor.getItemType();

      switch (type) {
        case NativeTypes.FILE:
          handleDrop(filesToImageTransports(item.files), x, y, ImageTransportError.FILE);
          break;
        case NativeTypes.URL:
          handleDrop(urlsToImageTransports(item.urls), x, y, ImageTransportError.FILE);
          break;
        case NativeTypes.TEXT:
          handleDrop(urlsToImageTransports(item.text), x, y, ImageTransportError.FILE);
          break;
        default:
          handleDropError(new ImageTransportError(
            {
              failed: item,
              action: ImageTransportError.DROP,
              reason: ImageTransportError.EMPTY,
            },
            'No images or URLs were detected in the items you dropped.'
          ));
      };

      return {type, item, x, y};
    },
    collect: monitor => ({
      droppable: monitor.isOver(),
      dragging: monitor.canDrop(),
    }),
  });

  return (
    <ZoomableMap
      className={combineClasses(
        styles.Floorplan,
        className,
        dragging  ? styles.dragging  : null,
        droppable ? styles.droppable : null,
      )}
      imageRef={composeRefs(imageRef, dropRef)}
      {...rest}
    />
  );
}

Floorplan.propTypes = {
  floorplan: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pins: PropTypes.arrayOf(PinPropType).isRequired,
  onPinMove: PropTypes.func,
  onReady: PropTypes.func,
  onAddImages: PropTypes.func.isRequired,
};

