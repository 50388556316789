import React from 'react';
import PropTypes from 'prop-types';
import { ToggleLayer } from 'react-laag';
import Downshift from 'downshift';
import classSet from 'react-classset';
import matchSorter from 'match-sorter';
import useAsync from 'react-use/lib/useAsync';
import debounce from 'debounce-promise';
import ResizeObserver from "resize-observer-polyfill";
import { Field } from 'formik';
import get from 'lodash.get';

import { Button } from '../../buttons';
import { Loader } from '../../loader';
import LabeledInput from '../LabeledInput.jsx';
import { DropdownMenuWrapper } from '../../dropdown';
import {
  combineClasses,
} from '~/util';

import './Autocomplete.scss';


/*
 * Render a single button item in the menu.
 * This is the default renderer which can be replaced
 * by passing the `renderItem` prop.
 */
function MenuItem({
  itemProps,
  suggestion,
  suggestionToString,
}) {
  return <Button {...itemProps}>{suggestionToString(suggestion)}</Button>;
}

/*
 * Render an empty result as the dropdown menu.
 * This should be the only thing rendered inside the DropdownMenu
 * if there are no results. It will show a loader
 * if we are currently searching for new results.
 */
function EmptyResults({
  message,
  getMenuProps,
  loading,
}) {
  return (
    <div
      data-test="emptyResults"
      className="autocomplete-suggestions empty"
      {...getMenuProps()}
    >
      { loading && <Loader fast /> }
      <span className="message">{ message }</span>
    </div>
  );
}

/*
 * Render the list of results only. This component is only used
 * if there are any results; otherwise the EmptyResults component
 * is rendered. This component is wrapped inside the DropdownMenu
 * component which provides the animated container and it's positioning.
 */
function Results({
  suggestions,
  onSearch,
  inputValue,
  itemToString,
  highlightedIndex,
  renderSuggestion,
  getMenuProps,
  getItemProps,
  loading,
}) {
  const matches = suggestions.map((s, i) => {
    const itemClasses = classSet({
      "transparent suggestion": true,
      "selected": highlightedIndex === i,
    });

    const itemProps = getItemProps({
      className: itemClasses,
      type: "button",
      key: s,
      index: i,
      item: s,
    });

    // Don't add a data-test attribute because if the
    // consumer of this component does something like
    // this, confusion will ensue:
    // <div data-test="foo" {...itemProps}></div>
    if (!renderSuggestion) {
      itemProps['data-test'] = "suggestion";
    }

    const item = renderSuggestion
      ? renderSuggestion({itemProps, suggestionToString: itemToString, suggestion: s})
      : <MenuItem itemProps={itemProps} suggestionToString={itemToString} suggestion={s} />;

    return (
      <li key={i}>
          { item }
        </li>
      )}
    );

  if (matches.length < 1) {
    return (
      <EmptyResults
        message={!inputValue ? 'Type To Search' : 'No Matches'}
        getMenuProps={getMenuProps}
        loading={loading}
      />
    );
  } else {
    return (
      <ul data-test="results" className="autocomplete-suggestions" {...getMenuProps()}>
        { loading && <Loader className="fast" /> }
        { matches }
      </ul>
    );
  }
}

/*
 * Conditionally render either the EmptyResults or Results
 * based on the current list of suggestions, loading state
 * and error state.
 */
function Menu(props) {
  const {
    suggestions,
    inputValue,
    loading,
    loadError,
    getMenuProps,
  } = props;
  if (inputValue && loading && (!suggestions || suggestions.length === 0)) {
    return <EmptyResults message={'Loading...'} getMenuProps={getMenuProps} loading={true} />
  }
  else if (inputValue && loadError) {
    const message = typeof(loadError) === 'string'
      ? loadError
      : loadError.message
      ? loadError.message
      : 'An error occured. Please try again.';
    return <EmptyResults message={message} getMenuProps={getMenuProps} />
  }
  else {
    const toArray = (v) => Array.isArray(v)
      ? v
      : v != null
      ? [v]
      : [];
    const results = toArray(suggestions);
    return <Results {...props} suggestions={results} loading={loading} />;
  }
}


function isMatch(inputValue, selectedItem, matches, itemToString) {
  return matches &&
    matches.length === 1 &&
    itemToString(matches[0]).toLowerCase() === inputValue.toLowerCase() &&
    selectedItem !== matches[0];
}

/*
 * Render the Dropdown in a synchronous manner based
 * on the static list of suggestions. This compoennt
 * will perform filtering of the suggestions based on
 * the input text value.
 */
function SyncSearcher(props) {
  const {suggestions, inputValue, itemToString, selectedItem, selectItem} = props;
  const matches = matchSorter(suggestions, inputValue, {keys: [itemToString]});

  if (isMatch(inputValue, selectedItem, matches, itemToString)) {
    // Do this in the next frame so we don't update state during render.
    setTimeout(() => selectItem(matches[0]));
  }

  return (
    <DropdownMenuWrapper
      className="autocomplete-overlay"
      {...props}
    >
      <Menu {...props}
        suggestions={matches}
        loading={false}
        loadError={false}
      />
    </DropdownMenuWrapper>
  );
}


/*
 * Render the Dropdown while performing an asynchronous
 * search against the input value term. This component
 * will handle loading state, search results and errors
 * that occur during the search.
 */
function AsyncSearcher(props) {
  const {
    onSearch,
    inputValue,
    isOpen,
    selectedItem,
    itemToString,
    selectItem,
    debounceSpeed
  } = props;
  const [suggestions, setSuggestions] = React.useState();

  const [search] = React.useState(() => ({
    doSearch: debounceSpeed > 0
      ? debounce(onSearch, debounceSpeed)
      : onSearch
  }), []);

  const getSuggestions = () => {
    if (!inputValue) {
      return Promise.resolve([]);
    } else {
      if (!isOpen) {
        return Promise.resolve(suggestions);
      } else {
        const result = search.doSearch(inputValue);
        if (!(result instanceof Promise)) {
          console.error('Autocomplete.onSearch must return a Promise. Received:', result);
          return Promise.resolve([]);
        }
        return result;
      }
    }
  }
  const state = useAsync(getSuggestions, [inputValue]);

  // If search returned a new result, re-render?
  // Shouldn't this be unnecessary because useAsync caused the current re-render?
  if (state.value && state.value !== suggestions) {
    setSuggestions(state.value);
  }

  if (isMatch(inputValue, selectedItem, suggestions, itemToString)) {
    // Perform this in the next frame so we don't update state during render.
    setTimeout(() => selectItem(suggestions[0]));
  }

  if (state.error) {
    console.error('[SEARCH ERROR]', state.error);
  }

  return (
    <DropdownMenuWrapper
      className="autocomplete-overlay"
      isOpen={props.isOpen}
      layerProps={props.layerProps}
      layerSide={props.layerSide}
      arrowStyle={props.arrowStyle}
      triggerRect={props.triggerRect}
      animated={props.animated}
      minWidth={props.minWidth}
    >
      <Menu {...props}
        suggestions={suggestions}
        loading={state.loading}
        loadError={state.error}
      />
    </DropdownMenuWrapper>
  );
}


/*
 * Synchronous/Asynchronous Autocomplete component that will
 * place the dropdown menu above or below its input element
 * based on available space.
 */
export default function Autocomplete({
  children,
  suggestions,
  onSearch,
  suggestionToString = s => s,
  suggestionToValue,
  onSelect,
  value = '',
  renderSuggestion,
  animated = true,
  debounceSpeed = 500,
  className,
  ...rest
}) {
  if (!animated) {
    // Remove the debounce if we're not using animations
    // in order to make the component feel more responsive
    // and to allow tests to disable debounce easily.
    debounceSpeed = 0;
  }

  const [selectedItem, setSelectedItem] = React.useState(value);

  // TODO Allow passing no children.
  const input = React.Children.only(children);
  if (
    input.type !== 'input' &&
    (input.props.type != null && input.props.type !== 'text')
  ) {
    console.error('The Autocomplete component requires a single Input child element of type "text".');
    return children;
  }

  if (!suggestions && !onSearch) {
    console.error(
      'The Autocomplete component requires either a "suggestions" prop ' +
      'with a static list of suggestions or it requires an "onSearch" prop ' +
      'that will return suggestions.'
    );
    return children;
  }

  const handleSelect = (item) => {
    if (item !== selectedItem) {
      setSelectedItem(item);
      onSelect(item);
    }
  }

  function handleInputChange(inputValue, {clearSelection}) {
    if (!inputValue) {
      setSelectedItem('');
      if (selectedItem) onSelect(null);
    }
  }

  const itemToString = (s) => {
    if (s) return suggestionToString(s) || '';
    else return '';
  };

  return (
    <Downshift
      itemToString={itemToString}
      onChange={handleSelect}
      defaultHighlightedIndex={0}
      onInputValueChange={handleInputChange}
      selectedItem={selectedItem}
    >
      {( downshiftProps) => {
        const {getInputProps, isOpen, inputValue, openMenu} = downshiftProps;

        return (
          <span
            data-test="autocomplete"
            className={combineClasses('autocomplete-wrapper', className)}
            {...rest}
          >
            <ToggleLayer
              isOpen={isOpen}
              placement={{
                anchor: "BOTTOM_CENTER",
                possibleAnchors: ["TOP_CENTER", "BOTTOM_CENTER"],
                autoAdjust: true,
                triggerOffset: 11
              }}
              closeOnOutsideClick
              ResizeObserver={ResizeObserver}
              renderLayer={(layerProps) => {
                const ddProps = {
                  suggestions,
                  onSearch,
                  animated,
                  debounceSpeed,
                  selectedItem,
                  renderSuggestion,
                  ...layerProps,
                  ...downshiftProps,
                };

                return !!onSearch
                  ? <AsyncSearcher {...ddProps} />
                  : <SyncSearcher {...ddProps} />
              }}
            >
              {({ triggerRef }) => {
                const inputProps = getInputProps({
                  'data-test': 'autocompleteInput',
                  type: "text",
                  value: inputValue || '',
                  autoComplete: 'off',
                  ...input.props,
                  ref: triggerRef,
                  onFocus: () => {
                    openMenu();
                    if (input.props.onFocus) input.props.onFocus();
                  },
                });

                return React.cloneElement(input, inputProps);
              }
            }
            </ToggleLayer>
          </span>
        )}
      }
    </Downshift>
  );
}

Autocomplete.propTypes = {
  /**
   * This component requires an input element be passed
   * as its only child. This allows you to customize the attributes
   * of the input. However, you should not set focus, blur, or change
   * handlers on your component because they will be overwritten
   * by this class.
   *
   * TODO Add the ability to pass onFocus, onBlur, and onChange methods
   * to attach to the input.
   */
  children: PropTypes.any.isRequired,
  /**
   * A render function that will be called to render each suggestion
   * in the dropdown menu. If not passed, this component will generate
   * a button for each suggestion with the text value returned by
   * `suggestionToString`.
   */
  renderSuggestion: PropTypes.func,
  /**
   * A callback to perform asynchronous
   * searches. It will receive the current input text and must return a promise.
   */
  suggestions: PropTypes.array,
  /**
   * @callback onSearch A static list of suggestions to synchronously filter.
   * @param {string} term - The search term to search against.
   * @return {Promise} - Must return a promise that will resolve with the results.
   */
  onSearch: PropTypes.func,
  /**
   * @callback onSelect - A callback that will be called
   *   whenever an item is selected from the autocomplete menu or
   *   when the selected item is cleared.
   * @param {?*} selectedItem - The currently selected item or null
   *   if the selected item (input element) was cleared.
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * The initial value to set. It should be of the same format as the
   * selectedItem passed to the `onSelect` callback.
   */
  value: PropTypes.any,
  /**
   * @callback suggestionToString - A callback to convert a search result into a string.
   * @param {*} suggestion - A suggestion from the suggestions prop or
   *   a result returned by onSearch.
   * @return {string} - The display value/text for this item.
   */
  suggestionToString: PropTypes.func,
  /**
   * Whether or not to use animations. Turning this off will also set
   * the debounceSpeed to 0 in order to improve responsiveness and to
   * speed up testing.
   */
  animated: PropTypes.bool,
  /**
   * The amount of time to debounce search requests.
   */
  debounceSpeed: PropTypes.number,
};


/*
 * An Autocomplete component configured for use in a Formik form.
 * The Autocomplete will also render a LabeledInput component.
 * You must pass the input element as the `children` prop.
 */
export function FormikAutocomplete({
  name,
  value,
  label,
  children,
  suggestionToValue,
  className,
  ...autocompleteProps
}) {
  const testId = autocompleteProps['data-test'];
  delete autocompleteProps['data-test'];

  return (
    <Field name={name} value={value}>
      {({form, field}) => {
        const {onChange, value, ...inputFields} = field;
        const touched = get(form.touched, name);

        let error = get(form.errors, name);
        if (typeof(error) === 'object') {
          error = Object.keys(error).map(key => `${key}: ${error[key]}`).join(', ');
        }

        return (
          <LabeledInput
            data-test={testId}
            className={className}
            label={label}
            error={touched ? error : null}
          >
            <Autocomplete
              {...autocompleteProps}
              onSelect={(value) => {
                form.setFieldTouched(name, true);
                form.setFieldValue(name, value ? suggestionToValue(value) : '');
                if (autocompleteProps.onSelect) autocompleteProps.onSelect(value);
              }}
              value={value}
            >
              { React.cloneElement(children, inputFields) }
            </Autocomplete>
          </LabeledInput>
        );
      }}
    </Field>
  );
}

FormikAutocomplete.propTypes = {
  /**
   * The name of this form field in the validation schema
   * and output values.
   */
  name: PropTypes.string.isRequired,
  /**
   * The current value of this field.
   */
  value: PropTypes.any,
  /**
   * The display label for this form field.
   */
  label: PropTypes.string,
  /**
   * You must pass an `<input>` element as the only
   * child to this autocomplete element.
   * @see `Autocomplete.children` for more details.
   */
  children: PropTypes.any.isRequired,
  /*
   * @callback suggestionToValue - A callback to convert a search result into the value
   *   used by the surrounding form.
   * @param {*} result - The selected item data.
   */
  suggestionToValue: PropTypes.func,
  /**
   * The amount of time to wait after a user types
   * and before performing the search.
   */
  debounceSpeed: PropTypes.number,
};

FormikAutocomplete.defaultProps = {
  suggestionToValue: v => v,
}

