import React from 'react';
import PropTypes from 'prop-types';

import {
  SaveProgress,
} from '../../save-progress';
import {
  combineClasses,
} from '~/util';

import styles from './SaveModal.module.scss';

export default function SaveModal({
  uploadCount,
  uploadTotal,
  artworkSaved,
  onComplete,
  className,
  ...rest
}) {
  const uploading = uploadCount < uploadTotal;

  const state = uploading
    ? 'progress'
    : !artworkSaved
    ? 'finishing'
    : 'done'

  const message = state === 'progress'
    ? `Uploading ${uploadCount + 1} / ${uploadTotal}`
    : state === 'finishing'
    ? 'Saving Artwork'
    : 'Artwork Saved';

  return (
    <SaveProgress
      className={combineClasses(styles.SaveModal, className)}
      message={message}
      state={state}
      onComplete={onComplete}
      {...rest}
    />
  )
}

SaveModal.propTypes = {
  /**
   * The current count of successfully uploaded images.
   */
  uploadCount: PropTypes.number.isRequired,
  /**
   * The total number of images to upload.
   */
  uploadTotal: PropTypes.number.isRequired,
  /**
   * Whether or not the artwork metadata has be saved.
   * It is assumed that this step occurs after images are uploaded.
   */
  artworkSaved: PropTypes.bool.isRequired,
  /**
   * @callback Called once the saved animation is complete.
   */
  onComplete: PropTypes.func.isRequired,
  /**
   * Whether or not to show animations.
   */
  animated: PropTypes.bool,
};

SaveModal.defaultProps = {
  animated: true,
}
