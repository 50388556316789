import {
  getMedium,
  NOTE_CATEGORIES,
} from '~/model';
import {
  clamp,
  pickFirst,
} from '~/util';
import {
  COORDINATE_PRECISION,
} from './util';

export const fromCollectionGraph = {

  /**
   * Convert a coordinate location from an integer value
   * into a decimal between 0 and 1.
   */
  coordinate(value) {
    return clamp(value / COORDINATE_PRECISION);
  },

  note(n) {
    const out = {
      is_public: n.is_public || false,
      description: n.description || '',
      category: n.category || (n.is_public ? NOTE_CATEGORIES.PUBLIC : NOTE_CATEGORIES.PRIVATE),
    };
    if (n.id) out.id = n.id;
    return out;
  },

  /**
   * Convert a notes array into an object
   * keyed by note category.
   */
  notes(notes) {
    if (notes && notes.length > 0) {
      return notes.reduce((acc, note) => {
        let key;
        const category = note.category;

        // If this note has a category, use
        // that as the key to store this note.
        if (category) {
          key = Object.keys(NOTE_CATEGORIES)
            .find(key => NOTE_CATEGORIES[key] === category);
        }

        // Otherwise, use the public flag to
        // determine the key to store this note.
        if (!key) {
          if (note.is_public) {
            key = 'PUBLIC';
          } else {
            key = 'PRIVATE';
          }
        }

        acc[key.toLowerCase()] = fromCollectionGraph.note(note);

        return acc;
      }, {});
    }

    return null;
  },

  /**
   * @param {object} c
   * @param {number} c.id,
   * @param {number} c.quantity,
   * @param {string} c.note,
   * @param {string} c.description,
   * @param {number} c.retail_price_cents,
   * @param {string} c.retail_currency,
   * @param {number} c.wholesale_price_cents,
   * @param {string} c.wholesale_currency,
   */
  cost(c) {
    return {
      id: c.id,
      quantity: c.quantity,
      note: c.note,
      description: c.description,
      retail: c.retail_price_cents,
      retailCurrency: c.retail_currency,
      wholesale: c.wholesale_price_cents,
      wholesaleCurrency: c.wholesale_currency,
    };
  },

  uploadData(u) {
    return JSON.parse(u.upload_tmp);
  },

  upload(u) {
    const upload = {
      ...u,
      ...u.image,
      uploadId: u.id,
    };

    if (u.upload_tmp) upload.upload_tmp = fromCollectionGraph.uploadData(u);

    delete upload.image;
    delete upload.__typename;
    return upload;
  },

  user(u) {
    return {
      id: u.id,
      firstName: u.first_name,
      lastName: u.last_name,
      fullName: [u.first_name, u.last_name].join(' ').trim(),
      email: u.email,
      image: u.uploads && u.uploads.length > 0
        ? fromCollectionGraph.upload(u.uploads[0])
        : null,
    };
  },

  floorplan(f) {
    const floorplan = {
      ...f,
      image: f.uploads && f.uploads.length > 0
        ? fromCollectionGraph.upload(f.uploads[0])
        : null,
      notes: fromCollectionGraph.notes(f.notes),
    }


    // Clean up so we only use upload.
    delete floorplan.uploads;
    delete floorplan.__typename;

    return floorplan;
  },

  artworkFloorplan(af) {
    return {
      floorplanId: af.floor_plan_id,
      x: fromCollectionGraph.coordinate(af.x),
      y: fromCollectionGraph.coordinate(af.y),
      color: af.hex_color,
      notes: fromCollectionGraph.notes(af.notes),
    }
  },

  artist(a) {
    if (!a) return a;

    const out = {
      id: a.id,
      first_name: a.first_name,
      last_name: a.last_name,
      fullName: [a.first_name, a.last_name].filter(a => !!a).join(' '),
      email: a.email,
      phone_number: a.phone_number,
      url: a.url,
    };

    return out;
  },

  framing(f) {
    const out = {
      ...f,
      cost: pickFirst(f.costs, fromCollectionGraph.cost),
      notes: fromCollectionGraph.notes(f.notes),
    }

    delete out.costs;
    delete out.__typename;
    return out;
  },

  dimensions(d) {
    const out = {
      ...d,
    };

    delete out.__typename;
    return out;
  },

  artwork(a) {
    const artwork = {
      id: a.id,
      title: a.title,
      sold: a.sold,
      // Convert the artwork medium from a v2 medium
      // into a v3 medium.
      medium: getMedium(a.medium)?.value,
      artist: fromCollectionGraph.artist(a.artist),
      dimensions : pickFirst(a.dimensions, fromCollectionGraph.dimensions),
      floorplans : a.artwork_floor_plans ? a.artwork_floor_plans.map(fromCollectionGraph.artworkFloorplan) : [],
      images     : a.uploads ? a.uploads.map(fromCollectionGraph.upload) : [],
      notes      : fromCollectionGraph.notes(a.notes),
      // For now, assume the first cost is the artwork price.
      price      : pickFirst(a.costs, fromCollectionGraph.cost),
      // For now, only one frame is allowed.
      framing    : pickFirst(a.framings, fromCollectionGraph.framing),
    };

    return artwork;
  },

  artCollection(ac) {
    return fromCollectionGraph.artwork(ac.artwork);
  },

  collection(c) {
    const collection = {
      ...c,
      floor_plans: c.floor_plans.map(fromCollectionGraph.floorplan),
      artworks: c.art_collections.map(fromCollectionGraph.artCollection),
      notes: fromCollectionGraph.notes(c.notes),
      user: fromCollectionGraph.user(c.user),
    };

    delete collection.art_collections;
    delete collection.__typename;

    return collection;
  },

  job(j) {
    return j;
  },
};
