import React from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';

import {
  FormikLabeledInput,
  // FormikDebug,
  FormikSelect,
  FieldRow,
  useAutoSaveFormInitialized,
} from '../../../forms';
import {
  ArtworkDetailsSchema,
  ARTWORK_MEDIUMS,
} from '~/model';

import { AutoSaveForm } from '../../../forms/AutoSaveForm.jsx';

import styles from './ArtworkDetailsForm.module.scss';


export function ArtworkDetailsForm({
  name,
  values,
  strip,
  onChange,
  onReady,
  ...rest
}) {
  const handleChange = useAutoSaveFormInitialized(onChange, onReady);

  return (
    <AutoSaveForm
      strip={strip}
      name={name}
      initialValues={values}
      schema={ArtworkDetailsSchema}
      onChange={handleChange}
      {...rest}
    >
      <Form className={styles.details} data-test="detailsPage">
        <FormikLabeledInput name="title" label="Title">
          <input required type="text" autoFocus data-test="title" />
        </FormikLabeledInput>

        <FieldRow className={styles.dimensions}>
          <FormikLabeledInput name="dimensions.height" label="Height">
            <input type="text" required data-test="height" />
          </FormikLabeledInput>
          <FormikLabeledInput name="dimensions.width" label="Width">
            <input type="text" required data-test="width" />
          </FormikLabeledInput>
          <FormikLabeledInput name="dimensions.depth" label="Depth">
            <input type="text" data-test="depth" />
          </FormikLabeledInput>
        </FieldRow>

        <FormikSelect name="medium" required data-test="medium" >
          <option value="">Select Medium</option>
          { ARTWORK_MEDIUMS.map((m, i) =>
            <option value={m.value} key={i}>{m.name}</option>
          )}
        </FormikSelect>

      </Form>
    </AutoSaveForm>
  );
}

ArtworkDetailsForm.propTypes = {
  /**
   * A name used to disambiguate change events from this form.
   */
  name: PropTypes.string,
  /**
   * The initial form values.
   */
  values: PropTypes.object,
  /**
   * A callback to handle changes to the state of the form.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Called after the form is initialized and the first onChange
   * event is dispatched.
   */
  onReady: PropTypes.func,
};

