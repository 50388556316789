import React from 'react';

// Use Google login
export {
  GoogleAuthAPI as AuthAPI,
  useGoogleAuth as useAuth,
} from './google';

// Use Dofolio login
// export {
//   useDofolioAuth as useAuth,
//   DotfolioAuthAPI as AuthAPI,
// } from './dotfolio-auth-api';

export const AuthAPIContext = React.createContext();
export const AuthAPIProvider = AuthAPIContext.Provider;

export function useAuthAPI() {
  return React.useContext(AuthAPIContext);
}

