import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';

import {
  FormikLabeledInput,
  // FormikDebug,
  FieldRow,
  useAutoSaveFormInitialized,
} from '../../../forms';
import {
  ArtistContactSchema,
  ArtistPropType,
} from '~/model';
import {
  combineClasses,
} from '~/util';

import { AutoSaveForm } from '../../../forms/AutoSaveForm.jsx';

import styles from './CreateArtistForm.module.scss';

export function CreateArtistForm({
  name,
  values,
  onChange,
  onReady,
  className,
  ...rest
}) {
  const handleChange = useAutoSaveFormInitialized(onChange, onReady);

  return (
    <AutoSaveForm
      name={name}
      initialValues={values}
      schema={ArtistContactSchema}
      onChange={handleChange}
      {...rest}
    >
      <Form className={combineClasses(styles.details, className)} data-test="detailsPage">
        <FieldRow responsive>
          <FormikLabeledInput name="first_name" label="First Name">
            <input required autoFocus data-testid="firstName" />
          </FormikLabeledInput>
          <FormikLabeledInput name="last_name" label="Last Name">
            <input data-testid="lastName" />
          </FormikLabeledInput>
        </FieldRow>
        <FormikLabeledInput name="email" label="Email">
          <input data-testid="email" />
        </FormikLabeledInput>
        <FormikLabeledInput name="phone_number" label="Phone Number">
          <input data-testid="phone" />
        </FormikLabeledInput>
      </Form>
    </AutoSaveForm>
  );
}

CreateArtistForm.propTypes = {
  name: PropTypes.string,
  values: ArtistPropType,
  onChange: PropTypes.func.isRequired,
  onReady: PropTypes.func,
};

