import {
  diffObjects,
  diffObjectsInList,
  findItemsRemovedFromList,
  findNewObjectsInList,
} from '~/util';

function findById(a, b) {
  return a.id === b.id;
}

function setOutputProp(target, type, prop, value) {
  if (!value) return;
  if (!target) target = {};
  if (!target[type]) target[type] = {};
  target[type][prop] = value;
  return target;
}

function addToArrayProp(target, type, prop, value) {
  if (!value) return;
  if (!target) target = {};
  if (!target[type]) target[type] = {};
  if (!target[type][prop]) target[type][prop] = [];

  if (Array.isArray(value))
    target[type][prop] = target[type][prop].concat(value);
  else
    target[type][prop].push(value);

  return target;
}

function updateFromDiff(target, prop, diff) {
  setOutputProp(target, 'added',   prop, diff.added);
  setOutputProp(target, 'removed', prop, diff.removed);
  setOutputProp(target, 'updated', prop, diff.updated);
  return target;
}

export const diff = {
  notes(oldNotes, newNotes) {
    let diff = null;

    const removedNotes = findItemsRemovedFromList(
      oldNotes,
      newNotes,
      findById
    );
    if (removedNotes.length > 0) {
      diff = {removed: removedNotes};
    }

    const addedNotes = findNewObjectsInList(
      oldNotes,
      newNotes,
      findById
    );
    if (addedNotes.length > 0) {
      if (!diff) diff = {};
      diff.added = addedNotes;
    }

    // TODO What about partial updates?
    if (oldNotes) {
      const updatedNotes = diffObjectsInList(
        oldNotes,
        newNotes,
        findById,
      ).map(note => ({
        // Only the description and category can be modified.
        id: note.id,
        description: note.description,
        category: note.category,
      }));
      if (updatedNotes.length > 0) {
        if (!diff) diff = {};
        diff.updated = updatedNotes;
      }
    }

    return diff;
  },

  uploads(oldUploads, newUploads) {
    let diff = null;

    const removedUploads = findItemsRemovedFromList(
      oldUploads,
      newUploads,
      findById
    );
    if (removedUploads.length > 0) {
      diff = {removed: removedUploads};
    }

    const addedUploads = findNewObjectsInList(oldUploads, newUploads, findById);
    if (addedUploads.length > 0) {
      if (!diff) diff = {};
      diff.added = addedUploads;
    }

    return diff;
  },

  framing(oldFraming, newFraming) {
    let out = {};

    if (oldFraming || newFraming) {
      // If there isn't a frame for the new artwork, then we've removed the framing.
      if (!newFraming) {
        setOutputProp(out, 'removed', 'framings', oldFraming);
        addToArrayProp(out, 'removed', 'costs', oldFraming.costs);
        addToArrayProp(out, 'removed', 'notes', oldFraming.notes);
      }
      // Otherwise, if there wasn't previously framing, then we've added it.
      else if (!oldFraming) {
        setOutputProp(out, 'added', 'framings', newFraming);
      }
      // Otherwise, have we modified the framing details?
      else {
        const costDiff = diffObjects(oldFraming.costs[0], newFraming.costs[0]);
        if (costDiff) {
          if (costDiff.added) {
            // Include the id so we know which cost was updated.
            costDiff.added = costDiff.added.map(c => ({...c, framing_id: newFraming.id}));
          }
          addToArrayProp(out, 'updated', 'costs', {...costDiff, id: newFraming.costs[0].id});
        }

        const notesDiff = diff.notes(oldFraming.notes, newFraming.notes);
        if (notesDiff) {
          if (notesDiff.added) {
            // Associate the note with the framing.
            notesDiff.added = notesDiff.added.map(n => ({...n, framing_id: newFraming.id}));
          }
          updateFromDiff(out, 'notes', notesDiff);
        }
      }
    }

    return Object.keys(out).length > 0 ? out : null;
  },

  artworks(oldArtwork, newArtwork) {
    let out = {};

    // UPLOADS
    const uploadsDiff = diff.uploads(oldArtwork.uploads, newArtwork.uploads);
    if (uploadsDiff) updateFromDiff(out, 'uploads', uploadsDiff);

    // ARTWORK METADATA
    let artworks = diffObjects(
      oldArtwork,
      newArtwork,
      undefined,
      (a, b, prop) => typeof(b[prop]) === 'object'
    );

    // ARTIST
    // If the artist changed, make that part of the artwork update.
    if (
      (oldArtwork.artist || newArtwork.artist) &&
      oldArtwork.artist?.id !== newArtwork.artist?.id
    ) {
      if (!artworks) artworks = {};
      // If we are just switching artists, only set the artist id.
      if (newArtwork.artist?.id) artworks.artist_id  = newArtwork.artist.id;
      // Otherwise, we're creating a new artist.
      // We treat new artists as an artwork update, because
      // we need to update the artist_id on the artwork after
      // creating the artist and we want to do that in an
      // artwork upsert.
      else {
        artworks.artist = newArtwork.artist;
      }
    }

    if (artworks) {
      // Ensure the artwork id is included in the upsert.
      artworks.id = newArtwork.id;

      setOutputProp(out, 'updated', 'artworks', artworks);
    }

    // ARTWORK COST
    const artworkCost = diffObjects(oldArtwork.costs[0], newArtwork.costs[0]);
    if (artworkCost) {
      addToArrayProp(
        out,
        'updated',
        'costs',
        newArtwork.costs[0]
      );
    }

    // ARTWORK DIMENSIONS
    // TODO Saving dimensions doesn't work for artworks without them.
    const dimensions = diffObjects(
      oldArtwork.dimensions ? oldArtwork.dimensions[0] : undefined,
      newArtwork.dimensions ? newArtwork.dimensions[0] : undefined,
    );
    if (dimensions) {
      addToArrayProp(
        out,
        'updated',
        'dimensions',
        newArtwork.dimensions[0]
      );
    }

    // ARTWORK NOTES
    const artworkNotesDiff = diff.notes(oldArtwork.notes, newArtwork.notes);
    if (artworkNotesDiff) {
      // Associate any new notes to the artwork.
      if (artworkNotesDiff.added) {
        artworkNotesDiff.added = artworkNotesDiff.added
          .map(n => ({...n, artwork_id: newArtwork.id}));
      }

      updateFromDiff(out, 'notes', artworkNotesDiff);
    }

    // FRAMING
    const framingDiff = diff.framing(
      oldArtwork.framings ? oldArtwork.framings[0] : null,
      newArtwork.framings ? newArtwork.framings[0] : null
    );
    if (framingDiff) {
      if (framingDiff.added) {
        if (framingDiff.added.framings) {
          // Associate framing objects with the artwork.
          framingDiff.added.framings.artwork_id = newArtwork.id;
          addToArrayProp(out, 'added', 'framings', framingDiff.added.framings);
        }
        if (framingDiff.added.costs) {
          addToArrayProp(out, 'added', 'costs', framingDiff.added.costs);
        }
        if (framingDiff.added.notes) {
          addToArrayProp(out, 'added', 'notes', framingDiff.added.notes);
        }
      }

      if (framingDiff.removed) {
        if (framingDiff.removed.framings) {
          addToArrayProp(out, 'removed', 'framings', framingDiff.removed.framings);
        }
        if (framingDiff.removed.costs) {
          addToArrayProp(out, 'removed', 'costs', framingDiff.removed.costs);
        }
        if (framingDiff.removed.notes) {
          addToArrayProp(out, 'removed', 'notes', framingDiff.removed.notes);
        }
      }

      if (framingDiff.updated) {
        if (framingDiff.updated.costs) {
          // Send the full cost object so we don't nullify fields that aren't passed.
          addToArrayProp(out, 'updated', 'costs', newArtwork.framings[0].costs[0]);
        }
        if (framingDiff.updated.notes) {
          addToArrayProp(out, 'updated', 'notes', framingDiff.updated.notes);
        }
      }
    }

    // console.log('DIFF', out);
    return out;
  }
};
