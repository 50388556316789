import React from 'react';

import {
  useCollectionGraphAPI,
} from '../graph-api';
import {
  useImageUpload,
} from './useImageUpload';


export function doCreateArtwork(
  graph,
  uploadImage,
  collectionId,
  artwork,
  setUploadCount,
  artworkFloorplan,
) {
  return new Promise((resolve, reject) => {
    const { images } = artwork;

    // After uploading all images, save the artwork to the Dotfolio backend.
    const saveArtwork = (succeeded) => {
      // Save the artwork details to the server.
      graph.createArtwork(collectionId, artwork, succeeded, artworkFloorplan)
        .then((response) => {
          resolve(response);
        })
        .catch(error => reject(error));
    };

    // First upload all of the images.
    if (images && images.length) {
      uploadImage(images, 'artwork', setUploadCount)
        .then(succeeded => saveArtwork(succeeded))
        .catch(error => reject(error));
    } else {
      // Proceed without any images.
      saveArtwork([]);
    }
  });
}

/**
 * Returns a callback that can be used to upload artwork images,
 * create the associated artwork and optionally link that artwork
 * to a floorplan.
 *
 * @callback setUploadCount
 * @param {number} count
 *
 * @callback artworkUpload
 * @param {number} collectionId
 * @param {Object} artwork
 * @param {setUploadCount} setUploadCount
 * @param {number} [floorplanId] - The floorplan to associate this artwork with.
 * @param {number} [x] - The x location of the artwork on the floorplan.
 * @param {number} [y] - The y location of the artwork on the floorplan.
 *
 * @return {artworkUpload}
 */
export function useCreateArtwork() {
  const uploadImage = useImageUpload();
  const graph = useCollectionGraphAPI();

  return React.useCallback(
    (...args) => doCreateArtwork(graph, uploadImage, ...args),
    [graph, uploadImage]
  );
}

