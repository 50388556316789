import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Title,
  SubTitle,
} from '../../titles';
import {
  TitleValuePair,
} from '../../title-value-pair';
import { Image } from '../../images';
import {
  Card
} from '../Card.jsx';
import {
  combineClasses,
  formatCurrency,
  formatMedium,
} from '~/util';
import {
  UploadedImagePropType,
} from '~/model';

import styles from './ArtworkCard.module.scss';

/**
 * @param {object} props
 * @param {string|number} props.id
 * @param {string} props.title
 * @param {boolean} props.sold
 * @param {[]} props.images
 * @param {string} props.artistName
 * @param {number} props.price
 * @param {string} props.currency
 * @param {string} props.medium
 * @param {string} props.link
 * @param {function} props.onImageLoad
 * @param {string} props.className
 * @param {*} props.children
 */
export function ArtworkCard({
  id,
  title = 'Unknown',
  sold,
  images,
  artistName = 'Unknown',
  price,
  currency = 'USD',
  medium = 'Unknown',
  link,
  onImageLoad,
  className,
  children,
  ...rest
}) {
  const image = images && images.length > 0 ? images[0].url : '';

  const wrap = (component) => {
    return link
      ? <Link data-test="ArtworkCard"
          className={combineClasses(styles.ArtworkCard, className)}
          to={link}
        >
          {component}
        </Link>
      : <div data-test="ArtworkCard"
          className={combineClasses(styles.ArtworkCard, className)}
        >
          {component}
        </div>
  };

  return wrap(
    <Card
      data-testid="Card"
      className={styles.Card}
      clickable={!!link}
      {...rest}
    >
      <Image
        data-test="artworkImage"
        className={styles.image}
        src={image}
        alt={title}
        onLoad={onImageLoad}
      />
      { sold &&
        <span data-testid="sold" className={styles.sold}>Sold</span>
      }
      <Title data-test="artworkTitle" className={styles.title}>{ title }</Title>
      <SubTitle data-test="artistName" className={styles.artistName}>by { artistName }</SubTitle>
      <div className={styles.metaData}>
        <TitleValuePair
          data-testid="medium"
          title="Medium"
          value={formatMedium(medium)}
        />
        <TitleValuePair
          data-testid="price"
          title="Retail Price"
          value={price ? formatCurrency(price, currency) : 'Unknown'}
        />
      </div>
      { children }
    </Card>
  );
}

ArtworkCard.propTypes = {
  /**
   * The id of the artwork.
   */
  id: PropTypes.number.isRequired,
  /**
   * The artwork title
   */
  title: PropTypes.string,
  /**
   * The image object describing the image and it's upload
   */
  images: PropTypes.arrayOf(UploadedImagePropType).isRequired,
  /**
   * The artist object
   */
  artistName: PropTypes.string,
  /**
   * A callback that will be called once the image has loaded.
   */
  onImageLoad: PropTypes.func,
  /**
   * A link that the card should redirect to if clicked.
   */
  link: PropTypes.string,
  /**
   * Whether or not the artwork has been sold.
   */
  sold: PropTypes.bool,
  /**
   * The artwork price.
   */
  price: PropTypes.number,
  /**
   * The artwork price currency.
   */
  currency: PropTypes.string,
  /**
   * The artwork medium.
   */
  medium: PropTypes.string,
  /**
   * Any other props will be passed to the out `Card` component.
   */
  '...other props': PropTypes.any,
};
