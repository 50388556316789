import React from 'react';

import {
  useCollectionGraphAPI,
} from '../graph-api';
import {
  useImageUpload,
} from './useImageUpload';

export function doFloorplanUpload(
  graph,
  uploadImage,
  collectionId,
  floorplan,
  image,
  setUploadCount
) {
  return new Promise((resolve, reject) => {
    const saveFloorplan = (upload) => {
      graph.createFloorplan(collectionId, floorplan, upload)
        .then(result =>
          resolve({
            upload,
            floorplan
          })
        );
    };

    uploadImage([image], 'floorplan', setUploadCount)
      .then(succeeded => saveFloorplan(succeeded[0]))
      .catch(error => reject(error));
  });
}

export function useFloorplanUpload() {
  const uploadImage = useImageUpload();
  const graph = useCollectionGraphAPI();

  return React.useCallback(
    (collectionId, floorplan, image, setUploadCount) =>
      doFloorplanUpload(graph, uploadImage, collectionId, floorplan, image, setUploadCount),
    [uploadImage, graph]
  );
}

