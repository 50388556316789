import React from 'react';
import PropTypes from 'prop-types';
import PanZ from '@thesoulfresh/pan-z';

import {
  combineClasses,
  useIsMounted,
} from '~/util';
import { PinPropType } from '~/model';

import { Map } from './Map.jsx';

import styles from './ZoomableMap.module.scss';

export function ZoomableMap({
  className,
  floorplan,
  name,
  pins,
  imageRef,
  onReady,
  onPinMove,
  animated,
  ...rest
}) {
  const boundsRef = React.useRef();
  const mapRef = React.useRef();
  const isMounted = useIsMounted();
  const [scale, setScale] = React.useState(1);
  const [loaded, setLoaded] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const [pz] = React.useState(() => new PanZ());

  // TODO Pinch zoom nolonger works in Safari.
  React.useEffect(() => {
    if (loaded && !pz.initialized) {
      const options = {
        minZoom: pz.getContainSize(mapRef.current, boundsRef.current).scale * 0.8,
        bounds: 0.5,
        initialFit: 'contain',
        boundingElement: boundsRef.current,
      };

      pz.on('update', ({scale: newScale}) => {
        if (newScale !== scale) setScale(newScale);
      });

      pz.init(mapRef.current, options);

      const {z} = pz.position;
      if (z !== scale) setScale(z);

      setInitialized(true);

      return () => {
        pz.destroy();
      }
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  // Emit the ready event after pan/zoom is initialized.
  React.useEffect(() => {
    if (initialized && onReady && isMounted()) onReady();
  }, [initialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMapLoad = React.useCallback(() => {
    if (isMounted()) setLoaded(true);
  }, [isMounted]);

  const onDisablePanning = () => {
    if (pz.initialized && !pz.moving) pz.disablePan();
  }
  const onEnablePanning = () => {
    if (pz.initialized) pz.enablePan();
  }

  return (
    <div
      data-testid="ZoomableMap"
      className={combineClasses(
        styles.ZoomableMap,
        className,
        scale !== 1 ? styles.draggable : null,
      )}
      {...rest}
      ref={boundsRef}
    >
      <Map
        className={styles.Map}
        ref={mapRef}
        imageRef={imageRef}
        imageURL={floorplan}
        name={name}
        pins={pins}
        scale={scale}
        onImageLoad={handleMapLoad}
        onPinHover={onDisablePanning}
        onPinLeave={onEnablePanning}
        onPinMove={onPinMove}
        animated={animated}
      />
    </div>
  );
};

ZoomableMap.propTypes = {
  /**
   * The URL to the floorplan image.
   */
  floorplan: PropTypes.string.isRequired,
  /**
   * The name of the floorplan (used as the alt attribute of the image).
   */
  name: PropTypes.string.isRequired,
  /**
   * The list of pins to display.
   */
  pins: PropTypes.arrayOf(PinPropType).isRequired,
  /**
   * Called when a pin is moved.
   */
  onPinMove: PropTypes.func,
  /**
   * Called once the map image has loaded.
   */
  onReady: PropTypes.func,
};
