import React from 'react';

import {
  dataURIToBlob,
} from '@thesoulfresh/utils';

import {
  useAWSStorageAPI,
} from '../file-storage';

export class FileUploadError extends Error {
  constructor(message, files) {
    super(message);
    this.failed = files;
  }
}

/**
 * Load the given Blob into an image element and return the
 * image's width and height.
 * @param {string|Blob} blob - The URL or Blob object to download and measure.
 */
export function getImageDimensions(blob) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const url = typeof(blob) === 'object' ? URL.createObjectURL(blob) : blob;

    image.addEventListener('load', () => {
      const width = image.naturalWidth;
      const height = image.naturalHeight;

      // Release the file from memory.
      URL.revokeObjectURL(url);

      resolve({width, height});
    });
    image.addEventListener('error', e => reject(new FileUploadError(
      'Could not determine the dimensions of an image being uploaded.',
      [blob]
    )));

    image.src = url;
  });
}

/**
 * @typedef FileImagesToUpload
 * @property {File} file
 * @property {string} file_name
 * @property {string} type - image mime type
 * @property {string} url - the base64 encoded image data uri
 */
/**
 * Uploads a list of image objects, returning the image and AWS url
 * for each image.
 *
 * @param {AWSStorageAPI} api
 * @param {FileImagesToUpload[]} images
 * @param {string} category - the type of image being uploaded (ex. 'artwork', 'floorplan').
 *   This will be used as part of the file location in AWS.
 * @param {function} setUploadCount
 *
 * @return {Promise<AWSUploadPropType[]>} - A list of images that
 *   succeeded and their associated url in AWS.
 */
export function doAWSUpload(api, images, category, setUploadCount, dimensions) {
  return new Promise((resolve, reject) => {
    let count = 0;

    images = Array.isArray(images) ? images : [images];

    // TODO The images list can have images that previously uploaded
    // if the item save passed the upload step,
    // then a server error occured, then the user fixed the errors and re-submitted
    // the artwork. At this point we will have lost the upload metadata and won't
    // be able to save the upload. If we want to handle this more gracefully,
    // we'll need to update the Artwork Upload form to retain and pass the
    // upload metadata back through. Unfortunately we can't just handle it here
    // because we lose the File object reference which has data we want to save.
    // UPDATE: We should be able to handle this now that we're passing
    // ImageTransport objects around.

    const uploads = images.map((image, i) => {
      // This allows us to bypass downloading the image during testing.
      const maybeGetDimensions = (file, dimensions) => {
        if (!dimensions) return getImageDimensions(file);
        else return Promise.resolve(dimensions);
      }

      // Ensure we have the image data before we proceed.
      return image.preload(true).then(image => {
        return maybeGetDimensions(image.src, dimensions)
          // If we fail to determine the dimensions, we'll continue
          // without them because it's not very important.
          .catch(e => ({}))
          .then(dimensions =>
            api.uploadImage(dataURIToBlob(image.src), image.mimeType, category)
              .then(location => {
                ++count;
                if (setUploadCount) setUploadCount(count);
                return {
                  location,
                  category,
                  dimensions,
                  image,
                };
              })
          )
          .catch(error => ({image, error}));
      });
    });

    Promise.all(uploads).then(result => {
      const succeeded = [];
      const failed = [];
      result.forEach((item) => item.error ? failed.push(item) : succeeded.push(item));

      // If there were any issues uploading, don't continue.
      if (failed.length > 0)
        reject(new FileUploadError('Some image files failed to upload.', failed));
      else {
        resolve(succeeded);
      }
    });
  });
}

export function useImageUpload() {
  const api = useAWSStorageAPI();
  return React.useCallback(
    (images, type, setUploadCount) =>
      doAWSUpload(api, images, type, setUploadCount),
    [api]
  );
}

